import { Dialog, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useLoading } from 'contexts/loading';
import { widthScreen } from 'const/common';
import { StyledParams } from 'types/common';
import { InputSelect } from 'app/components/elements/InputSelect';
import { optionLeadType, OptionVINStatus } from 'const/option';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewLeadSchema } from 'utils/validate';
import { InputText } from 'app/components/elements/InputText';
import { TypeLead } from 'types/user';
import { RadioGroupCustom } from 'app/components/elements/RadioGroupCustom';
import theme from 'styles/theme';
import { AddIcon } from 'assets/icon';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useInjection } from './hook';
import { Autocomplete } from '@react-google-maps/api';

export type NewLeadForm = {
  type: string;
  address?: string;
  city?: string;
  province?: string;
  phoneNumber?: string;
  otherPhoneNumber?: string;
  information?: string;
  note?: string;
  firstName?: string;
  lastName?: string;
  colour?: string;
  plate?: string;
  vinStatus?: string;
  noteAdmin?: string;
  namePOE?: string;
  nameReference?: string;
  towYards?: { value: string }[];
  latitude?: number;
  longitude?: number;
};

type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: NewLeadForm) => void;
  textTitle: string;
  isAdmin?: boolean;
};
export const ModalNewLead = ({
  isOpen,
  onClose,
  onSubmit,
  textTitle,
  isAdmin
}: DialogConfirmType) => {
  const { loading } = useLoading();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<NewLeadForm>({
    resolver: yupResolver(NewLeadSchema)
  });

  const { isLoaded, onPlaceChanged, onLoadAutoComplete } = useInjection(setValue);

  const inputRef = useRef(null);

  const {
    fields: fieldTowYards,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'towYards'
  });
  const typeLead = watch('type');
  const other = useMemo(() => {
    return (
      typeLead === TypeLead.OTHER_OPEN_NOTE ||
      typeLead === TypeLead.OTHER_ADDRESS ||
      typeLead === TypeLead.OTHER_PHONE_NUMBER
    );
  }, [typeLead]);
  const hasGap = useMemo(() => {
    return (
      typeLead === TypeLead.MVS || typeLead === TypeLead.VIN || typeLead === TypeLead.TOW_YARDS
    );
  }, [typeLead]);

  const isRenderAddressBox = useMemo(() => {
    return (
      typeLead === TypeLead.POR || typeLead === TypeLead.POE || typeLead === TypeLead.OTHER_ADDRESS
    );
  }, [typeLead]);
  const handleCancel = () => {
    reset({ type: undefined, towYards: [] });
    onClose();
  };

  const optionsType = useMemo(() => {
    if (!isAdmin) {
      return optionLeadType.filter((item) => !item.isAdmin);
    }
    return optionLeadType;
  }, [isAdmin]);
  return (
    <DialogStyled open={isOpen} onClose={handleCancel}>
      <WrapperDialog width={widthScreen < 500 ? '100%' : '420px'}>
        <Stack
          width={'100%'}
          pt={1.25}
          flexDirection={'row'}
          px={1.375}
          justifyContent={'space-between'}>
          <Stack width={'33px'} />
          <IconButton onClick={handleCancel} sx={{ width: '40px', height: '40px' }}>
            <img src={'/icons/icon_cancel.svg'} alt={'icon-cancel'} />
          </IconButton>
        </Stack>
        <Stack width={'100%'} px={2.5} pb={4} justifyContent={'space-between'} flex={1}>
          <Stack gap={2} pb={2.5}>
            <TitleStyled>{textTitle}</TitleStyled>
            <WrapperContent>
              <Controller
                control={control}
                render={({ field: { onBlur, value, onChange } }) => (
                  <InputSelect
                    inputError={errors.type?.message}
                    style={{ height: 50 }}
                    title={'Lead Type'}
                    placeholder={'Select an option'}
                    options={optionsType}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event) => {
                      onChange(event);
                      clearErrors();
                      reset({ type: event.target.value as string });
                    }}
                  />
                )}
                name={'type'}
              />
              <Stack gap={hasGap ? 0 : 2.5} flexDirection={other ? 'column-reverse' : 'column'}>
                {typeLead === TypeLead.POE && (
                  <InputText
                    inputError={errors.namePOE?.message}
                    placeholder={'Name POE'}
                    title={'Name POE'}
                    inputProps={{
                      ...register('namePOE')
                    }}
                  />
                )}
                {typeLead === TypeLead.REFERENCE && (
                  <InputText
                    inputError={errors.nameReference?.message}
                    placeholder={'Name Reference'}
                    title={'Name Reference'}
                    inputProps={{
                      ...register('nameReference')
                    }}
                  />
                )}
                <Stack gap={2.5}>
                  {(typeLead === TypeLead.PHONE_NUMBER ||
                    typeLead === TypeLead.REFERENCE ||
                    typeLead === TypeLead.POE ||
                    typeLead === TypeLead.OTHER_PHONE_NUMBER) && (
                    <InputText
                      inputError={errors.phoneNumber?.message}
                      placeholder={'+1'}
                      title={'Phone Number'}
                      inputProps={{
                        ...register('phoneNumber')
                      }}
                    />
                  )}
                  {typeLead === TypeLead.PHONE_NUMBER && (
                    <InputText
                      inputError={errors.otherPhoneNumber?.message}
                      placeholder={'+1'}
                      title={'CB Phone Number'}
                      inputProps={{
                        ...register('otherPhoneNumber')
                      }}
                    />
                  )}
                  {isRenderAddressBox && (
                    <>
                      {isLoaded && (
                        <>
                          <input hidden type="text" {...register('latitude')} />
                          <input hidden type="text" {...register('longitude')} />
                          <Autocomplete onLoad={onLoadAutoComplete} onPlaceChanged={onPlaceChanged}>
                            <InputText
                              inputError={errors.address?.message}
                              placeholder={'Address'}
                              title={'Address'}
                              inputRef={inputRef}
                              inputProps={{
                                ...register('address')
                              }}
                            />
                          </Autocomplete>
                        </>
                      )}
                      <Stack style={{ flexDirection: 'row', gap: '20px' }}>
                        <InputText
                          inputError={errors.city?.message}
                          placeholder={'City'}
                          title={'City'}
                          inputProps={{
                            ...register('city')
                          }}
                        />
                        <InputText
                          inputError={errors.province?.message}
                          placeholder={'Province'}
                          title={'Province'}
                          inputProps={{
                            ...register('province')
                          }}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
                <Stack gap={2.5}>
                  {typeLead === TypeLead.MVS && (
                    <>
                      <InputText
                        inputError={errors.firstName?.message}
                        placeholder={'First name'}
                        title={'First Name'}
                        inputProps={{
                          ...register('firstName')
                        }}
                      />
                      <InputText
                        inputError={errors.lastName?.message}
                        placeholder={'Last name'}
                        title={'Last Name'}
                        inputProps={{
                          ...register('lastName')
                        }}
                      />
                      {isLoaded && (
                        <Autocomplete>
                          <InputText
                            inputError={errors.address?.message}
                            placeholder={'R.O. Address'}
                            title={'R.O. Address'}
                            inputRef={inputRef}
                            inputProps={{
                              ...register('address')
                            }}
                          />
                        </Autocomplete>
                      )}

                      <InputText
                        inputError={errors.colour?.message}
                        placeholder={'Colour'}
                        title={'Colour'}
                        inputProps={{
                          ...register('colour')
                        }}
                      />
                      <InputText
                        inputError={errors.plate?.message}
                        placeholder={'Plate'}
                        title={'Plate'}
                        inputProps={{
                          ...register('plate')
                        }}
                      />
                    </>
                  )}
                  {typeLead === TypeLead.VIN && (
                    <Controller
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <RadioGroupCustom
                          onChange={onChange}
                          value={value || ''}
                          label={'Status'}
                          options={OptionVINStatus}
                        />
                      )}
                      name={'vinStatus'}
                    />
                  )}
                  {typeLead === TypeLead.TOW_YARDS && (
                    <Stack gap={2}>
                      <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                        <Typography fontSize={'13px'} fontWeight={500} color={theme.colors.dark1}>
                          Add Field
                        </Typography>
                        <IconButton
                          onClick={() => append({ value: '' })}
                          sx={{ width: '34px', height: '34px' }}>
                          <AddIcon color={'#22374A'} />
                        </IconButton>
                      </Stack>
                      <Stack gap={1}>
                        {fieldTowYards.map((field, index) => {
                          return (
                            <Stack
                              key={field.id}
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              mb={2}>
                              <Controller
                                name={`towYards.${index}.value`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <InputText
                                      {...field}
                                      inputError={errors.towYards?.[index]?.value?.message || ''}
                                      placeholder={'Enter Note'}
                                      title={`Tow Yards ${index + 1}`}
                                      inputProps={{
                                        ...field
                                      }}
                                    />
                                  );
                                }}
                              />
                              <IconButton onClick={() => remove(index)}>
                                <RemoveCircleOutlineIcon color={'error'} />
                              </IconButton>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                  {other && (
                    <InputText
                      inputError={errors.information?.message}
                      placeholder={'Enter Note'}
                      title={'Lead Information'}
                      inputProps={{
                        ...register('information')
                      }}
                    />
                  )}
                  {typeLead === TypeLead.OTHER_OPEN_NOTE && (
                    <InputText
                      inputError={errors.note?.message}
                      placeholder={'Open Note'}
                      title={'Open Note'}
                      inputProps={{
                        ...register('note')
                      }}
                    />
                  )}
                  {isAdmin && (
                    <Stack gap={1}>
                      <Typography
                        fontSize={'13px'}
                        fontWeight={500}
                        mb={1}
                        color={theme.colors.dark1}>
                        Admin Note
                      </Typography>
                      <InputAdd rows={6} {...register('noteAdmin')} />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </WrapperContent>
          </Stack>
          <Stack gap={2}>
            <ButtonCustom
              loading={loading}
              sx={{ width: '100%' }}
              label={'Save Lead'}
              onClick={handleSubmit((value) => {
                onSubmit(value);
                reset({ type: undefined, towYards: [], noteAdmin: '' });
              })}
              fullWidth
            />
            <ButtonCustom
              loading={loading}
              sx={{ width: '100%' }}
              isCancel
              label={'Cancel'}
              onClick={handleCancel}
              fullWidth
            />
          </Stack>
        </Stack>
      </WrapperDialog>
    </DialogStyled>
  );
};
const InputAdd = styled('textarea')(() => ({
  border: '1px solid #ccc',
  minHeight: '30px',
  fontSize: '14px',
  borderRadius: '10px',
  padding: '6px 10px',
  width: '100%',
  color: '#565656'
}));
const WrapperDialog = styled(Stack)(() => ({
  borderRadius: '20px',
  flex: 1
}));
const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '20px',
  fontWeight: 700,
  color: theme.colors.dark1
}));
const WrapperContent = styled(Stack)(() => ({
  flexDirection: 'column',
  gap: '20px'
}));
const DialogStyled = styled(Dialog)(({ theme }: StyledParams) => ({
  [theme.breakpoints.up('sm')]: {
    '.MuiDialog-paper': {
      maxWidth: '420px'
    }
  },
  borderRadius: '20px',
  '.MuiDialog-paper': {
    margin: '20px',
    width: '100%',
    minHeight: '450px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  }
}));
