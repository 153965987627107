import { ReactElement, useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Stack, styled } from '@mui/material';
import { StyledParams } from 'types/common';
import {
  CallMenuIcon,
  HomeMenuIcon,
  LeadsMenuIcon,
  MapMenuIcon,
  SettingMenuIcon
} from 'assets/icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLE } from 'const/common';
import { StorageServices } from 'services/storage';
const { innerWidth: width } = window;

const MENU = [
  {
    label: 'Home',
    value: 0,
    icon: <HomeMenuIcon />,
    link: '/'
  },
  {
    label: 'Leads',
    value: 1,
    icon: <LeadsMenuIcon />,
    link: '/leads'
  },
  {
    label: 'Map',
    value: 2,
    icon: <MapMenuIcon />,
    link: '/map'
  },
  {
    label: 'Calls',
    value: 3,
    icon: <CallMenuIcon />,
    link: '/calls'
  },
  {
    label: 'Settings',
    value: 4,
    icon: <SettingMenuIcon />,
    link: '/setting'
  }
];
const storage = new StorageServices();

/**
 *
 * RequireAuth Component check auth
 *
 * */
export function LayoutButtonNavigation({ children }: { children: ReactElement }) {
  const role = storage.getRole();
  const navigate = useNavigate();
  const [value, setValue] = useState<number | null>(null);
  const location = useLocation();
  useEffect(() => {
    MENU.map((item) => {
      if (location.pathname.includes(item.link)) {
        setValue(item.value);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    if (role !== ROLE.USER) {
      navigate('/forbidden');
    }
  }, [role]);

  return (
    <Wrapper>
      <Content id={'scrollableDiv'}>{children}</Content>
      <BottomMenu style={{ width: width < 500 ? width : '450px' }}>
        <BottomNavigationStyled
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue);
          }}
          showLabels>
          {MENU?.map((item, index) => (
            <BottomNavigationActionStyled
              onClick={() => navigate(item.link)}
              key={index}
              label={item.label}
              icon={item.icon}
            />
          ))}
        </BottomNavigationStyled>
      </BottomMenu>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)(() => ({
  height: '100dvh',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden'
}));

const Content = styled(Box)(() => ({
  flexGrow: 1,
  overflowY: 'auto',
  paddingBottom: '16px'
}));

const BottomMenu = styled(Box)(({ theme }: StyledParams) => ({
  background: theme.colors.dark1
}));
const BottomNavigationStyled = styled(BottomNavigation)(({ theme }: StyledParams) => ({
  background: theme.colors.dark1,
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  height: 80
}));
const BottomNavigationActionStyled = styled(BottomNavigationAction)(({ theme }: StyledParams) => ({
  paddingTop: 0,
  height: '100%',
  minWidth: 60,
  gap: 4,
  color: theme.colors.light2,
  '&.Mui-selected': {
    color: theme.colors.white
  },
  '& .MuiBottomNavigationAction-label': {
    fontWeight: 400,
    '&.Mui-selected': {
      fontWeight: 700
    }
  }
}));
