import axios from 'axios';

export type AuctionDelivery = {
  value?: string;
  idOption?: string;
  isDelete?: boolean;
};

type typeUpdateAuctionDelivery = {
  options: { value?: string; id?: number; isDelete?: boolean }[];
};

export const getAuctionDeliveryLocations = async () => {
  const { data } = await axios.get('/auction-delivery-locations/');
  return data;
};

export const updateAuctionDeliveryLocations = async (payload: typeUpdateAuctionDelivery) => {
  const { data } = await axios.put('/auction-delivery-locations/', payload);
  return data;
};
