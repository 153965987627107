/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSearchParams } from 'react-router-dom';

export const useUpdateSearchParamUrl = (): [
  URLSearchParams,
  (params: Record<string, unknown>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateParams = (params: Record<string, unknown>) => {
    const newPrams: any = {};
    for (const key in params) {
      if (Array.isArray(params[key]) && (params[key] as Array<string[]>).length === 0) {
        continue;
      }
      if (params[key]) newPrams[key] = params[key];
    }
    setSearchParams(newPrams);
  };
  return [searchParams, updateParams];
};

export const formatPayload = (payload: any) => {
  if (typeof payload !== 'object') return payload;
  const newPrams: any = {};
  for (const key in payload) {
    if (Array.isArray(payload[key]) && (payload[key] as Array<string[]>).length === 0) {
      continue;
    }
    if (payload[key]) newPrams[key] = payload[key];
  }
  return newPrams;
};

export function createGoogleMapsDirectionsLink(coordinates: Array<{ lat: number; lng: number }>) {
  if (!Array.isArray(coordinates) || coordinates.length < 2) {
    throw new Error('coordinates must have at least 2 item');
  }

  const baseUrl = 'https://www.google.com/maps/dir/?api=1';

  const origin = `${coordinates[0].lat},${coordinates[0].lng}`;

  const destination = `${coordinates[coordinates.length - 1].lat},${coordinates[coordinates.length - 1].lng}`;

  let waypoints = '';
  if (coordinates.length > 2) {
    waypoints = coordinates
      .slice(1, -1)
      .map((coord) => `${coord.lat},${coord.lng}`)
      .join('|');
  }

  let url = `${baseUrl}&origin=${origin}&destination=${destination}`;

  if (waypoints) {
    url += `&waypoints=${waypoints}`;
  }

  url += '&travelmode=driving';

  return url;
}
