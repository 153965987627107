import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { useDebouncedCallback } from 'use-debounce';
import { Lead, getLeads } from 'services/leads';
import { FilterLeadsType } from 'types/common';
import { useUpdateSearchParamUrl } from 'utils';
import { useNavigate } from 'react-router-dom';
import { CATEGORY } from 'const/common';

export const useLeads = ({ category }: { category: string }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useUpdateSearchParamUrl();

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<Lead[]>([]);
  const [search, setSearch] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>(searchParams.get('searchText') ?? '');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const formValue = useMemo(() => {
    return {
      searchText: searchParams.get('searchText') || searchInput,
      earlyMorning: searchParams.get('earlyMorning')
        ? searchParams.get('earlyMorning') === 'true'
        : undefined,
      doorKnock: searchParams.get('doorKnock')
        ? searchParams.get('doorKnock') === 'true'
        : undefined,
      status: searchParams.getAll('status'),
      types: searchParams.getAll('types'),
      sortBy: searchParams.get('sortBy'),
      order: searchParams.get('order'),
      page: 1,
      category: category ? category : undefined,
      size: SIZE_PAGE_CONTROL_PANEL
    };
  }, [searchParams, searchInput, category]);

  const fetchLeadsData = useCallback(async () => {
    const data = await getLeads(formValue);
    setRows(data.leads);
    setPage(2);
    if (data.total > SIZE_PAGE_CONTROL_PANEL) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [formValue]);

  const fetchMoreData = useCallback(async () => {
    if (page === 1) return;
    const res = await getLeads({ ...formValue, page: page });
    const newData = res.leads;
    setRows((prevState) => [...prevState, ...newData]);
    setPage((prevState) => prevState + 1);
    if (res.total > SIZE_PAGE_CONTROL_PANEL * page) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [page, formValue]);

  const { asyncCallback: asyncFetchLeads } = useAsyncCallback(fetchLeadsData, []);

  useEffect(() => {
    asyncFetchLeads();
  }, [asyncFetchLeads]);

  const handleInputSearch = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    const params = {
      page: page || 1,
      searchText: inputValue || null
    };
    setSearchParams({ ...formValue, ...params });
  }, 500);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancel = () => {
    setSearch(false);
    if (!searchInput) return;
    setSearchInput('');
    setSearchParams({
      ...formValue,
      searchText: ''
    });
  };

  const onFilter = useCallback(
    (valueForm: FilterLeadsType) => {
      const params = {
        page: 1,
        category: searchParams.get('category'),
        status: valueForm.status || null,
        types: valueForm.types || null,
        earlyMorning: valueForm.earlyMorning,
        doorKnock: valueForm.doorKnock
      };
      setSearchParams({ ...formValue, ...params });
    },
    [formValue, searchParams, setSearchParams]
  );

  const handleResetFilter = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const handleClickRow = useCallback(
    (indexRow: number) => {
      const path =
        category === CATEGORY.PHONE ? 'calls' : category === CATEGORY.ADDRESS ? 'map' : 'leads';
      const row = rows.find((_, index) => index === indexRow);
      if (row?.id) {
        navigate(`/${path}/file/${row.fileId}?leadId=${row.id}`);
      }
    },
    [category, navigate, rows]
  );

  const onSortHeader = useCallback(
    (sortField: string) => {
      let order = 'asc';
      const sortFieldOld = searchParams.get('sortBy');
      const orderOld = searchParams.get('order');
      if (sortFieldOld === sortField) {
        if (orderOld === 'asc') {
          order = 'desc';
        } else {
          order = 'asc';
        }
      }
      setSearchParams({ ...formValue, sortBy: sortField, order: order });
    },
    [formValue, searchParams, setSearchParams]
  );

  return {
    open,
    search,
    searchInput,
    rows,
    setSearch,
    handleOpen,
    handleClose,
    handleCancel,
    handleInputSearch,
    onFilter,
    handleResetFilter,
    formValue,
    handleClickRow,
    fetchMoreData,
    hasMore,
    onSortHeader
  };
};
