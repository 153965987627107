import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { SubmitButton, SubmitButtonProps } from 'app/components/elements/SubmitButton';
import { CircularProgress } from '@mui/material';

type CustomButtonProps = {
  label?: string;
  loading?: boolean;
  isCancel?: boolean;
  isDelete?: boolean;
} & ButtonProps &
  SubmitButtonProps;

/**
 * @returns Element PrimaryButton
 */
export const ButtonCustom: FC<CustomButtonProps> = ({
  label,
  isDelete,
  loading,
  isCancel,
  ...props
}) => {
  return (
    <PrimaryButtonStyled
      isdelete={isDelete ? 'delete' : ''}
      iscancel={isCancel ? 'cancel' : ''}
      {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {label && (
            <Typography fontSize={16} fontWeight={600}>
              {label}
            </Typography>
          )}
          {props.children}
        </>
      )}
    </PrimaryButtonStyled>
  );
};

const PrimaryButtonStyled = styled(SubmitButton)(
  ({ iscancel, isdelete }: { iscancel?: string; isdelete?: string }) => ({
    borderRadius: 10,
    textTransform: 'none',
    width: '100%',
    height: 56,
    background: isdelete ? 'red' : iscancel ? 'white' : '#22374A',
    color: isdelete ? 'white' : iscancel ? '#22374A' : 'white',
    border: isdelete ? '1px solid #22374A' : iscancel ? '1px solid #22374A' : 'none',
    '&:hover': {
      backgroundColor: isdelete ? 'red' : iscancel ? 'white' : '#22374A',
      opacity: 0.8
    }
  })
);
