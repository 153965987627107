import { Stack } from '@mui/material';

import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { Link } from 'react-router-dom';

export const ResetPasswordSuccess = () => {
  return (
    <Stack gap={2.5}>
      <Stack width={'100%'} alignItems={'center'} mt={'17px'} mb={27}>
        You have successfully reset your password.
      </Stack>
      <Link to="/login">
        <ButtonCustom label={'Reset to Sign In'} />
      </Link>
    </Stack>
  );
};
