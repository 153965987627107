import { Grid, IconButton, Link, Stack, styled, Typography } from '@mui/material';
import theme from 'styles/theme';
import React, { useRef, useState } from 'react';
import { StyledParams } from 'types/common';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { widthScreen } from 'const/common';
import { compact } from 'lodash';

type FormCarfaxProps = {
  initDocument?: {
    id: string;
    storageId: string;
    url: string;
    name: string;
  }[];
  handleUploadFileCarfax: (files: File[], oldDocIds: number[]) => void;
  handleDeleteDocument: (oldDocIds: number[]) => void;
};
export const FormCarfax = ({
  initDocument,
  handleUploadFileCarfax,
  handleDeleteDocument
}: FormCarfaxProps) => {
  const [documents, setDocuments] = useState<File[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const fileInputPhotoRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    setDocuments((prevFiles) => [...prevFiles, ...newFiles]);
    setShowModal(true);
  };

  const handleDeleteDocs = (order: number) => {
    setDocuments((prevState) => prevState.filter((_, index) => index !== order));
  };

  return (
    <Stack mb={1}>
      <Stack flexDirection={'row'} justifyContent={'space-between'} gap={1} alignItems={'center'}>
        <Typography fontSize={'13px'} fontWeight={500} color={theme.colors.dark1}>
          Add document
        </Typography>
        <IconButton
          onClick={() => {
            if (fileInputPhotoRef.current) {
              fileInputPhotoRef.current.click();
            }
          }}
          sx={{ width: '46px', height: '46px' }}>
          <img src={'/icons/icon_upload.svg'} alt={'icon'} />
        </IconButton>
        <input
          accept="image/pdf"
          ref={fileInputPhotoRef}
          hidden
          onChange={handleFileChange}
          type="file"
          multiple
        />
      </Stack>
      <Stack style={{ marginRight: '10px' }}>
        <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
          {initDocument?.map((doc, index) => (
            <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack
                  width={'calc(100% - 20px)'}
                  flexDirection={'row'}
                  gap={1.5}
                  alignItems={'center'}>
                  <img src={'/icons/icon_document.svg'} alt={'icon'} />
                  <NameDocument href={doc.url} target={'_blank'}>
                    {doc.name}
                  </NameDocument>
                </Stack>
                <img
                  onClick={() => {
                    const oldDocIds =
                      compact(
                        initDocument?.map((item) => {
                          if (item.id === doc.id) return;
                          return Number(item.storageId);
                        })
                      ) || [];
                    handleDeleteDocument(oldDocIds);
                  }}
                  style={{ cursor: 'pointer' }}
                  src={'/icons/icon_delete_file.svg'}
                  alt={'icon'}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <DialogConfirm
        width={widthScreen < 500 ? '100%' : '420px'}
        isOpen={showModal}
        textButton={'Upload'}
        onClose={() => {
          setShowModal(false);
          setDocuments([]);
        }}
        textTitle={'Upload document carfax'}
        onSubmit={() => {
          const oldDocIds = initDocument?.map((item) => Number(item.storageId)) || [];
          setDocuments([]);
          setShowModal(false);
          handleUploadFileCarfax(documents, oldDocIds);
        }}>
        <Stack>
          <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
            {documents?.map((doc, index) => (
              <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Stack
                    width={'calc(100% - 20px)'}
                    flexDirection={'row'}
                    gap={1.5}
                    alignItems={'center'}>
                    <img src={'/icons/icon_document.svg'} alt={'icon'} />
                    <NameDocument href={URL.createObjectURL(doc)} target={'_blank'}>
                      {doc.name}
                    </NameDocument>
                  </Stack>
                  <img
                    onClick={() => handleDeleteDocs(index)}
                    style={{ cursor: 'pointer' }}
                    src={'/icons/icon_delete_file.svg'}
                    alt={'icon'}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogConfirm>
    </Stack>
  );
};
const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 24px)'
}));
