import axios from 'axios';
import * as qs from 'qs';

export type GetPerformanceParams = {
  startDate?: string;
  endDate?: string;
};

export const getCurrentWorkload = async () => {
  const { data } = await axios.get(`/dashboard/top-current-workload`);
  return data;
};

export const getOverallPerformance = async (params: GetPerformanceParams) => {
  const query = qs.stringify({
    ...params,
    startDate: params.startDate,
    endDate: params.endDate
  });
  const { data } = await axios.get(`/dashboard/top-overall-performance?${query}`);
  return data;
};

export const getBailiffPerformance = async (params: GetPerformanceParams) => {
  const query = qs.stringify({
    ...params,
    startDate: params.startDate,
    endDate: params.endDate
  });
  const { data } = await axios.get(`/dashboard/bailiff-performance?${query}`);
  return data;
};

export const getClientPerformance = async (params: GetPerformanceParams) => {
  const query = qs.stringify({
    ...params,
    startDate: params.startDate,
    endDate: params.endDate
  });
  const { data } = await axios.get(`/dashboard/client-performance?${query}`);
  return data;
};
