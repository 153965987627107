import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Button, Grid, IconButton, Stack, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { ClientOption, TClient, useClientOption } from './hooks';

export type ClientOptionTabProps = {
  optionClientInit: TClient[];
  onSubmitForm: (data: ClientOption) => void;
};
export const ClientOptionTab = ({ optionClientInit, onSubmitForm }: ClientOptionTabProps) => {
  const {
    control,
    errors,
    handleSubmit,
    fieldClientOption,
    showChildren,
    toggleChildrenVisibility,
    update,
    handleResetForm,
    handleShowAll,
    handleHiddenAll,
    handleAddNew,
    isShowAll
  } = useClientOption({ optionClientInit });
  return (
    <Wrapper minWidth={'900px'} width={'50%'} mt={2}>
      <Stack width={'100%'} mb={2} alignItems={'flex-start'}>
        <Stack width={'100%'} alignItems={'flex-end'}>
          <ButtonCustom
            style={{ width: '150px' }}
            isCancel
            label={!isShowAll ? 'Show All Child' : 'Hidden All Child'}
            onClick={() => {
              if (isShowAll) {
                handleHiddenAll();
                return;
              }
              handleShowAll();
            }}
          />
        </Stack>
        <Stack width={'100%'}>
          <Grid container gap={4} justifyContent="flex-start" alignItems="flex-start">
            {fieldClientOption.map((field, parentIndex) => {
              return (
                <Grid
                  display={field.isDelete ? 'none' : 'grid'}
                  item
                  xs={12}
                  key={field.id}
                  style={{ position: 'relative' }}>
                  <Stack>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Controller
                        name={`options.${parentIndex}.value`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <InputText
                              title={'Client'}
                              inputError={errors.options?.[parentIndex]?.value?.message || ''}
                              placeholder="Client Option"
                              {...field}
                              inputProps={{
                                ...field
                              }}
                            />
                          );
                        }}
                      />
                      <IconButton
                        sx={{ mt: 3 }}
                        onClick={() => {
                          toggleChildrenVisibility(parentIndex);
                        }}>
                        <ExpandMoreIcon
                          style={{
                            transform: showChildren[parentIndex] ? 'rotate(180deg)' : 'unset'
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{ mt: 3 }}
                        onClick={() => {
                          update(parentIndex, {
                            ...fieldClientOption[parentIndex],
                            isDelete: true
                          });
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                    {showChildren[parentIndex] && (
                      <Box key={parentIndex} display="flex" alignItems="center" mb={2} mt={2.5}>
                        <Controller
                          name={`options.${parentIndex}.children`}
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Stack width={'100%'}>
                                <Stack gap={2} pb={1.5} pl={2} width={'100%'}>
                                  {value.map((child, childIndex) => {
                                    return (
                                      <Stack
                                        display={child?.isDelete ? 'none' : 'flex'}
                                        key={child.id}
                                        flexDirection={'row'}
                                        alignItems={'center'}>
                                        <InputText
                                          inputError={
                                            errors.options?.[parentIndex]?.children?.[childIndex]
                                              ?.value?.message || ''
                                          }
                                          placeholder={'Child'}
                                          title={`Child`}
                                          onChange={(e) => {
                                            const updatedValue = e.target.value;
                                            const updatedChildren = value.map((childItem, idx) =>
                                              idx === childIndex
                                                ? { ...childItem, value: updatedValue }
                                                : childItem
                                            );
                                            onChange(updatedChildren);
                                          }}
                                          inputProps={{
                                            value: child.value
                                          }}
                                        />
                                        <IconButton
                                          sx={{ mt: 3 }}
                                          onClick={() => {
                                            const updatedChildren = value.map((child, idx) =>
                                              idx === childIndex
                                                ? { ...child, isDelete: true }
                                                : child
                                            );
                                            onChange(updatedChildren);
                                          }}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Stack>
                                    );
                                  })}
                                  <Button
                                    sx={{ width: '120px' }}
                                    onClick={() => onChange([...(value || []), { value: '' }])}
                                    variant="contained"
                                    color="primary">
                                    Add children
                                  </Button>
                                </Stack>
                              </Stack>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <Button onClick={handleAddNew} variant="contained" color="primary" sx={{ mt: 2, p: 2 }}>
          Add New Client
        </Button>
      </Stack>
      <Stack
        width={'100%'}
        justifyContent={'flex-end'}
        flexDirection={'row'}
        gap={2}
        alignItems={'flex-end'}>
        <Box width={'156px'} sx={{ float: 'inline-end' }}>
          <ButtonCustom label="Save" onClick={handleSubmit(onSubmitForm)} type={'submit'} />
        </Box>
        <ButtonCustom isCancel sx={{ width: '156px' }} label={'Reset'} onClick={handleResetForm} />
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  padding: '24px',
  border: '1px solid #ccc',
  borderRadius: '20px'
}));
