import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { NewLeadForm } from '.';

export const useInjection = (setValue: UseFormSetValue<NewLeadForm>) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!
  });

  return {
    isLoaded,
    onPlaceChanged: useCallback(() => {
      if (autocomplete !== null) {
        const place = autocomplete?.getPlace();
        const city = place?.address_components?.find(
          (item) =>
            item.types.includes('administrative_area_level_2') || item.types.includes('locality')
        );
        const province = place?.address_components?.find((item) =>
          item.types.includes('administrative_area_level_1')
        );
        if (city) setValue('city', city.long_name);
        if (province) setValue('province', province.long_name);
        if (place.geometry?.location?.lat()) setValue('latitude', place.geometry?.location?.lat());
        if (place.geometry?.location?.lng()) setValue('longitude', place.geometry?.location?.lng());
      } else {
        console.log('Autocomplete is not loaded yet!');
      }
    }, [autocomplete, setValue]),
    onLoadAutoComplete: useCallback((autocompleteInstance: google.maps.places.Autocomplete) => {
      setAutocomplete(autocompleteInstance);
    }, [])
  };
};
