import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFile, TypeLead } from 'types/user';
import { getFilesById, getOptionFile } from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useNavigate, useParams } from 'react-router-dom';
import { payloadLoadCreateReport, updateSeizure } from 'services/leads';
import { compact, uniqBy } from 'lodash';
import axios from 'axios';
import { TypeOption } from 'app/pages/Admin/NewFileSetUpPage/hook';

export interface FormDataSeizure {
  kilometers: number;
  hasKey: boolean;
  run: boolean;
  signedVSA: boolean;
  possibleRedemption: boolean;
  conditionReport: string;
  towingCompany: string;
  transportRequired: boolean;
  billsIncurred: boolean;
  otherAddress?: string;
  otherCustomerPOR?: string;
  addressLeadId: string;
  customersLeadId: string;
  removedToId: number;
  removedToOther?: string;
  otherBills?: string;
  otherTransport?: string;
}

export const useSeizure = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [photos, setPhotos] = useState<File[]>([]);
  const [file, setFile] = useState<TFile>();
  const [vsaDocuments, setVsaDocuments] = useState<File[]>([]);
  const fileInputPhotoRef = useRef<HTMLInputElement | null>(null);
  const fileInputDocumentRef = useRef<HTMLInputElement | null>(null);
  const [optionAuctionDeliveryLocation, setOptionAuctionDeliveryLocation] = useState<TypeOption[]>(
    []
  );
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'photo' | 'document'
  ) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);

    if (type === 'photo') {
      setPhotos((prevFiles) => uniqBy([...prevFiles, ...newFiles], 'name'));
      return;
    }
    setVsaDocuments((prevFiles) => uniqBy([...prevFiles, ...newFiles], 'name'));
  };

  const handleDeleteDocument = (indexToDelete: number) => {
    // @ts-ignore
    fileInputDocumentRef.current.value = '';
    setVsaDocuments((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  const getFiles = useCallback(async () => {
    const [dataFile, dataOption] = await Promise.all([getFilesById(Number(id)), getOptionFile()]);
    const { resOptionAuctionDeliveryLocation } = dataOption;
    setOptionAuctionDeliveryLocation([
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...resOptionAuctionDeliveryLocation?.auctionHouses?.map(
        (item: { id: number; value: string }) => ({
          value: item.id,
          label: item.value
        })
      ),
      { value: 0, label: 'Other location' }
    ]);
    const fileRes = dataFile.file as TFile;
    setFile(fileRes);
  }, [id]);

  const { asyncCallback: asyncGetFiles } = useAsyncCallback(getFiles, []);

  useEffect(() => {
    asyncGetFiles();
  }, [id]);

  const optionsAddress = useMemo(() => {
    const option = file?.leads.map((item) => {
      if (
        item.type === TypeLead.POE ||
        item.type === TypeLead.POR ||
        item.type === TypeLead.OTHER_ADDRESS
      ) {
        return {
          value: String(item.id),
          label: `${item.address}, ${item.city}, ${item.province}`
        };
      }
      return;
    });
    return [...compact(option), { value: 'other', label: 'Other Address' }];
  }, [file]);

  const onSubmitSeizure = useCallback(
    async (data: FormDataSeizure) => {
      if (data.signedVSA && !vsaDocuments?.length) return;
      const documentFormData = new FormData();
      const photoFormData = new FormData();
      let filePhotosIds: number[] = [];
      let fileDocumentsIds: number[] = [];
      if (photos?.length) {
        for (const photo of photos) {
          photoFormData.append('files', photo, photo.name);
        }
        const { data: resListIdFile } = await axios.post(
          '/storages/seizure-report-photo',
          photoFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
        filePhotosIds = resListIdFile.files;
      }

      if (vsaDocuments?.length) {
        for (const document of vsaDocuments) {
          documentFormData.append('files', document, document.name);
        }
        const { data: resListIdFile } = await axios.post(
          '/storages/vsa-document',
          documentFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
        fileDocumentsIds = resListIdFile.files;
      }

      const otherAddress = data.addressLeadId === 'other';
      const otherCustomer = data.customersLeadId === 'other';

      const payload: payloadLoadCreateReport = {
        fileId: Number(id),
        kilometers: data.kilometers,
        key: data.hasKey,
        addressLeadId: !otherAddress ? Number(data.addressLeadId) : undefined,
        otherAddress: otherAddress ? data.otherAddress : undefined,
        customersLeadId: !otherCustomer ? Number(data.customersLeadId) : undefined,
        otherCustomerPOR: otherCustomer ? data.otherCustomerPOR : undefined,
        run: data.run,
        signedVsa: data.signedVSA,
        possibleRedemption: data.possibleRedemption,
        transportRequired: data.transportRequired,
        billsIncurred: data.billsIncurred,
        conditionReport: data.conditionReport,
        towingCompany: data.towingCompany,
        vsaDocumentIds: fileDocumentsIds,
        seizureReportPhotoIds: filePhotosIds,
        removedToId: data.removedToId || undefined,
        removedToOther: data.removedToId === 0 ? data.removedToOther : undefined,
        otherBills: data.otherBills,
        otherTransport: data.otherTransport
      };
      await updateSeizure(payload);
      navigate('/');
    },
    [id, navigate, photos, vsaDocuments]
  );

  const { asyncCallback: asyncUpdateSeizure } = useAsyncCallback(onSubmitSeizure, []);

  const handleBack = () => navigate(`/file/${id}`);

  return {
    file,
    photos,
    vsaDocuments,
    fileInputPhotoRef,
    fileInputDocumentRef,
    handleFileChange,
    handleDeleteDocument,
    handleSubmit: asyncUpdateSeizure,
    optionsAddress,
    handleBack,
    optionAuctionDeliveryLocation
  };
};
