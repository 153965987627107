import axios from 'axios';

type OptionType = {
  option: string;
};

export type payloadClientOption = {
  option: string;
  children?: OptionType[];
};

export type UpdateClientOption = {
  options: {
    id?: number;
    value?: string;
    isDelete?: boolean;
    parentId?: number;
    children?: { id?: number; value?: string; isDelete?: boolean; parentId?: number }[];
  }[];
};

export const getClientOption = async () => {
  const { data } = await axios.get('/client-options/');
  return data;
};

export const postClientOption = async (payload: Partial<payloadClientOption>) => {
  const { data } = await axios.post('/client-options/', payload);
  return data;
};

export const updateClientOption = async (payload: UpdateClientOption) => {
  const { data } = await axios.put(`/client-options/`, payload);
  return data.clientOptions;
};
