import React, { ChangeEvent, memo } from 'react';
import { RadioGroupCustom } from '../../../components/elements/RadioGroupCustom';
import { OptionVINStatus } from 'const/option';
import { Stack } from '@mui/material';
type FormVinCheckProps = {
  value: string;
  onChange: (value: string) => void;
};
const FormVinCheck = ({ value, onChange }: FormVinCheckProps) => {
  return (
    <Stack>
      <RadioGroupCustom
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        value={value || ''}
        label={'Status'}
        options={OptionVINStatus}
      />
    </Stack>
  );
};

export const FormVinCheckMemo = memo(FormVinCheck);
