import React, { ReactElement } from 'react';
import { Dialog, IconButton, Stack, styled, Typography } from '@mui/material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useLoading } from 'contexts/loading';
import { widthScreen } from 'const/common';
import { StyledParams } from 'types/common';

type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  textTitle: string;
  textContent?: string;
  textButton?: string;
  isDelete?: boolean;
  isCancel?: boolean;
  width?: string;
  children?: ReactElement;
  contentStyle?: React.CSSProperties;
};

export const DialogConfirm = ({
  isOpen,
  onClose,
  onSubmit,
  textContent,
  textTitle,
  textButton,
  isDelete,
  isCancel,
  width,
  children,
  contentStyle
}: DialogConfirmType) => {
  const { loading } = useLoading();
  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ borderRadius: '20px' }}>
      <WrapperDialog width={width} maxWidth={width || widthScreen < 500 ? '100%' : '420px'}>
        <Stack width={'100%'} flexDirection={'row'} p={1.375} justifyContent={'space-between'}>
          <Stack width={'33px'} />
          <IconButton onClick={onClose} sx={{ width: '40px', height: '40px' }}>
            <img src={'/icons/icon_cancel.svg'} alt={'icon-cancel'} />
          </IconButton>
        </Stack>
        <Stack width={'100%'} px={2.5} pb={4} alignItems={'center'} gap={2}>
          <TitleStyled>{textTitle}</TitleStyled>
          {textContent && <ContentStyled style={contentStyle}>{textContent}</ContentStyled>}
          {children && children}
          {textButton && (
            <ButtonCustom
              loading={loading}
              sx={{ width: 200 }}
              isCancel={isCancel}
              isDelete={isDelete}
              label={textButton}
              onClick={onSubmit}
              fullWidth
            />
          )}
        </Stack>
      </WrapperDialog>
    </Dialog>
  );
};

const WrapperDialog = styled(Stack)(() => ({
  borderRadius: '20px'
}));
const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '20px',
  fontWeight: 700,
  color: theme.colors.dark1,
  textAlign: 'center'
}));
const ContentStyled = styled(Typography)(() => ({
  fontSize: '20px',
  color: '#000',
  fontWeight: 400,
  whiteSpace: 'pre-line',
  marginBottom: '12px'
}));
