import React, { useCallback } from 'react';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { TabsCustom } from 'app/components/elements/TabsCutom';
import { headerTab, TAB, useInjection } from './hook';
import { HeaderFileMemo } from './components/HeaderFile';
import { TabFileMemo } from './components/TableFiles';
import { GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { StatusFiles, StatusRedemptions } from 'const/option';
import { Stack, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'const/common';
import { DispatchFile } from 'types/user';

export const HEADER_FILE: GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 80 },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: (params: GridRenderCellParams) => {
      const statusFile = params.row.fileStatusTransition.status;
      const statusOpen = params.value;
      let findStatus;
      if (statusFile === 'OPENED') {
        findStatus = StatusFiles.find((item) => item.value === statusOpen);
      } else {
        findStatus = StatusRedemptions.find((item) => item.value === statusFile);
      }
      return (
        <Stack
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
            gap: 10
          }}>
          <Stack
            style={{
              background: findStatus?.color || '#9747FF',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 10px',
              height: '20px',
              borderRadius: '5px'
            }}>
            <Typography
              style={{
                color: '#fff',
                fontSize: '10px',
                fontWeight: 600
              }}>
              {capitalizeFirstLetter(findStatus?.value.replace('_', ' ') || 'New')}
            </Typography>
          </Stack>
          {params.row.isFileReopen && (
            <Stack
              style={{
                background: params.row.isFileReopen ? '#FF4773' : 'blue',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 10px',
                height: '20px',
                borderRadius: '5px'
              }}>
              <Typography
                style={{
                  color: '#fff',
                  fontSize: '10px',
                  fontWeight: 600
                }}>
                Reopened
              </Typography>
            </Stack>
          )}
        </Stack>
      );
    }
  },

  {
    field: 'dispatchFiles',
    headerName: 'Agent Assigned to File',
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      const listUser: DispatchFile[] = params.value;
      return (
        <Stack
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%'
          }}
          gap={1}>
          <Typography
            style={{
              color: '#22374A',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}>
            {listUser.map((item, index) => {
              return `${item?.user?.firstName} ${item?.user?.lastName} ${index === listUser.length - 1 ? '' : ', '}`;
            })}
          </Typography>
        </Stack>
      );
    }
  },
  { field: 'firstName', headerName: 'First Name', width: 160 },
  { field: 'lastName', headerName: 'Last Name', width: 160 },
  { field: 'year', headerName: 'Year', width: 100 },
  { field: 'make', headerName: 'Make', width: 100 },
  { field: 'model', headerName: 'Model', width: 100 },
  { field: 'vin', headerName: 'VIN', width: 100 },
  {
    field: 'clientOption',
    headerName: 'Client',
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value.value;
      return (
        <Stack
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%'
          }}
          gap={1}>
          <Typography
            style={{
              color: '#22374A',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}>
            {value}
          </Typography>
        </Stack>
      );
    }
  },
  { field: 'plate', headerName: 'Plate', width: 100 },
  { field: 'fileNumber', headerName: 'File Number', width: 160 }
];

function FilePage() {
  const {
    tab,
    handleChangeTab,
    optionHeader,
    searchParams,
    fileStates,
    pages,
    handleRowClick,
    loading,
    handlePaginationModelChange
  } = useInjection();
  const renderTab = useCallback(() => {
    const tab = searchParams.get('tab') || TAB.OPEN_FILES;
    const { files, total } = fileStates[tab];
    if (tab === TAB.ON_HOLD) {
      return (
        <TabFileMemo
          header={HEADER_FILE}
          page={pages[tab]}
          totalRows={total}
          handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
            handlePaginationModelChange(newPaginationModel, tab)
          }
          handleRowClick={handleRowClick}
          rows={files}
          loading={loading}
        />
      );
    }
    if (tab === TAB.REDEMPTION) {
      return (
        <TabFileMemo
          header={HEADER_FILE}
          page={pages[tab]}
          totalRows={total}
          handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
            handlePaginationModelChange(newPaginationModel, tab)
          }
          handleRowClick={handleRowClick}
          rows={files}
          loading={loading}
        />
      );
    }
    return (
      <TabFileMemo
        header={HEADER_FILE}
        page={pages[tab]}
        totalRows={total}
        handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
          handlePaginationModelChange(newPaginationModel, tab || TAB.REDEMPTION)
        }
        handleRowClick={handleRowClick}
        rows={files}
        loading={loading}
      />
    );
  }, [searchParams, fileStates, pages, handleRowClick, loading, handlePaginationModelChange]);

  return (
    <LayoutAdminTable title={'File Overview'}>
      <Helmet>
        <title>Surrey Bailiffs - File Overview</title>
      </Helmet>
      <HeaderFileMemo optionHeader={optionHeader} />
      <TabsCustom
        tab={tab}
        contentTab={renderTab()}
        headerTab={headerTab}
        onChangeTab={handleChangeTab}
      />
    </LayoutAdminTable>
  );
}

export default FilePage;
