export const optionLeadType = [
  {
    value: 'POR',
    label: 'POR (Place of Residence)'
  },
  {
    value: 'POE',
    label: 'POE (Place of Employment)'
  },
  {
    value: 'MVS',
    label: 'MVS',
    isAdmin: true
  },
  {
    value: 'PHONE_NUMBER',
    label: 'Phone Number'
  },
  {
    value: 'TOW_YARDS',
    label: 'Tow Yards'
  },
  {
    value: 'REFERENCE',
    label: 'Reference'
  },
  {
    value: 'CARFAX',
    label: 'Carfax',
    isAdmin: true
  },
  {
    value: 'VIN',
    label: 'VIN Check',
    isAdmin: true
  },
  {
    value: 'OTHER_ADDRESS',
    label: 'Other Address'
  },
  {
    value: 'OTHER_PHONE_NUMBER',
    label: 'Other Phone Number'
  },
  {
    value: 'OTHER_OPEN_NOTE',
    label: 'Other Open Note'
  }
];

export const optionLeadInformation = [
  {
    value: 'ADDRESS',
    label: 'Address'
  },
  {
    value: 'PHONE_NUMBER',
    label: 'Phone Number'
  },
  {
    value: 'OPEN_NOTE',
    label: 'Open Note'
  }
];

export const OptionVerify = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'pending'
  }
];

export const StatusFiles = [
  {
    label: 'New - created past 48 hours',
    value: 'NEW',
    color: '#9747FF'
  },
  {
    label: 'Attention - no update past 48 hours',
    value: 'ATTENTION',
    color: '#FFAA47'
  },
  {
    label: 'Aged - opened for 2 weeks',
    value: 'AGED',
    color: '#FF4773'
  },
  {
    label: 'In progress',
    value: 'IN_PROGRESS',
    color: 'blue'
  }
];

export const STATUS_FILE_SEIZURE = {
  COMPLETED: 'COMPLETED',
  POST_SEIZURE: 'POST_SEIZURE',
  PRE_SEIZURE: 'PRE_SEIZURE',
  OPENED: 'OPENED',
  FAILED: 'FAILED'
};

export const OptionVINStatus = [
  {
    label: 'Normal',
    value: 'NORMAL'
  },
  {
    label: 'Salvage',
    value: 'SALVAGE'
  }
];

export const StatusRedemptions = [
  {
    label: 'Post Seizure',
    value: 'POST_SEIZURE',
    color: '#FF4773'
  },
  {
    label: 'Pre-Seizure',
    value: 'PRE_SEIZURE',
    color: '#9747FF'
  },
  {
    label: 'Opened',
    value: 'OPENED',
    color: 'blue'
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
    color: '#04AA6D'
  },
  {
    label: 'Failed',
    value: 'FAILED',
    color: '#000'
  }
];

export const optionsRedemptionsSeizure = [
  { value: 'AWAITING_POI_PAD', label: 'Awaiting POI PAD' },
  { value: 'AWAITING_AMOUNTS', label: 'Awaiting Amounts' },
  { value: 'STATEMENT_SENT', label: 'Statement Sent' },
  { value: 'FOLLOW_UP_SENT', label: 'Follow-up Sent' },
  { value: 'FINAL_DEMAND_SENT', label: 'Final Demand Sent' },
  { value: 'APPOINTMENT_BOOKED', label: 'Appointment Booked' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'DID_NOT_COMPLETED', label: 'Did Not Complete' }
];

export const optionsRedemptionsPreSeizure = [
  { value: 'AWAITING_POI_PAD', label: 'Awaiting POI PAD' },
  { value: 'AWAITING_AMOUNTS', label: 'Awaiting Amounts' },
  { value: 'STATEMENT_SENT', label: 'Statement Sent' },
  { value: 'FOLLOW_UP_SENT', label: 'Follow-up Sent' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' }
];

export const optionArrearsOther = [
  {
    label: 'Lease and agreement',
    value: 'LEASE_AND_AGREEMENT'
  },
  {
    label: 'Voluntary surrender',
    value: 'VOLUNTARY_SURRENDER'
  }
];

export const StatusArchive = [
  {
    label: 'Closed not found',
    value: 'CLOSE_NOT_FOUND',
    color: '#686E7D'
  },
  {
    label: 'Closed client request',
    value: 'CLOSE_CLIENT_REQUEST',
    color: '#686E7D'
  },
  {
    label: 'Closed redemption',
    value: 'CLOSE_REDEMPTION',
    color: '#686E7D'
  }
];

export const stepRedemptionsSeizure = [
  { value: 'AWAITING_POI_PAD', label: 'Awaiting POI PAD' },
  { value: 'AWAITING_AMOUNTS', label: 'Awaiting Amounts' },
  { value: 'STATEMENT_SENT', label: 'Statement Sent' },
  { value: 'FOLLOW_UP_SENT', label: 'Follow-up Sent' },
  { value: 'FINAL_DEMAND_SENT', label: 'Final Demand Sent' },
  { value: 'APPOINTMENT_BOOKED', label: 'Appointment Booked' },
  { value: 'DID_NOT_COMPLETED', label: 'Did Not Complete' }
];
