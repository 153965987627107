import { Stack, styled } from '@mui/material';
import { ReactNode } from 'react';
import theme from 'styles/theme';
import { widthScreen } from 'const/common';
type LayoutProps = {
  children: ReactNode;
};

export const LayoutApp = ({ children }: LayoutProps) => {
  return (
    <Wrapper>
      <Stack
        width={widthScreen}
        flex={1}
        maxWidth={widthScreen < 500 ? '100%' : '450px'}
        sx={{ background: theme.colors.white }}
        alignItems={'center'}>
        {children}
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  background: '#e8f0fe',
  minHeight: '100dvh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1
}));
