import React, { memo } from 'react';
import { Grid, Link, Stack, styled, Typography } from '@mui/material';
import { StyledParams } from 'types/common';
import { TFilePhoto } from 'types/user';

type BoxFileDocumentProps = {
  documents?: TFilePhoto[];
};
const BoxFileDocument = ({ documents }: BoxFileDocumentProps) => {
  return (
    <WrapperBorder>
      <WrapperHeaderBox>
        <TitleLead>Documents</TitleLead>
      </WrapperHeaderBox>
      {documents && (
        <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
          {documents.map((doc, index) => (
            <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack
                  width={'calc(100% - 20px)'}
                  flexDirection={'row'}
                  gap={1.5}
                  alignItems={'center'}>
                  <img src={'/icons/icon_document.svg'} alt={'icon'} />
                  <NameDocument href={doc.url} target={'_blank'}>
                    {doc.name}
                  </NameDocument>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
    </WrapperBorder>
  );
};
export const BoxFileDocumentMemo = memo(BoxFileDocument);

const WrapperBorder = styled(Stack)(({ theme }: StyledParams) => ({
  padding: '20px 0',
  margin: '0 16px',
  borderBottom: `1px solid ${theme.colors.light3}`
}));
const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '100%'
}));
const WrapperHeaderBox = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '10px'
}));
const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 24px)'
}));
