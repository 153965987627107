import { IconBack } from 'assets/icon';
import theme from 'styles/theme';
import React from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type HeaderPageProps = {
  title: string;
  isBack?: boolean;
};
export const HeaderPage = ({ title, isBack }: HeaderPageProps) => {
  const navigate = useNavigate();
  return (
    <WrapperHeader>
      <TitlePage>{title}</TitlePage>
      {isBack && (
        <WrapperIconBack onClick={() => navigate(-1)}>
          <IconBack color={theme.colors.dark1} />
          <TextBack>Back</TextBack>
        </WrapperIconBack>
      )}
    </WrapperHeader>
  );
};

const TitlePage = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '25px'
}));

const TextBack = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px'
}));
const WrapperHeader = styled(Stack)(() => ({
  padding: '30px 16px 0 16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row'
}));

const WrapperIconBack = styled(Button)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  flexDirection: 'row',
  padding: 0
}));
