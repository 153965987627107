import { useFlash } from 'contexts/flash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { FormSignUpType } from 'app/pages/SignUpPage/components/FormSignUp';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { StorageServices } from 'services/storage';
import { signUp } from 'services/auth';
const storage = new StorageServices();
export const useSignUp = () => {
  const { setFlash } = useFlash();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const postSignUp = useCallback(
    async (data: FormSignUpType) => {
      if (!searchParams.get('code')) return;
      const payload = {
        password: data.password,
        code: searchParams.get('code') as string
      };
      const res = await signUp(payload);
      storage.setAccessToken(res.accessToken);
      storage.setRefreshToken(res.refreshToken);
      storage.setRole(res.role);
      setFlash({ type: 'success', message: 'You have successfully signed up' });
      navigate('/');
    },
    [navigate, searchParams, setFlash]
  );
  const { asyncCallback: handleSubmitForm } = useAsyncCallback(postSignUp, []);
  return {
    handleSubmitForm
  };
};
