import { useCallback, useEffect, useState } from 'react';
import { TFile } from 'types/user';
import { useNavigate, useParams } from 'react-router-dom';
import { getFilesById } from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { TAB_CONTROL_PANEL } from 'const/admin';

export const useArchiveDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [file, setFile] = useState<TFile>();

  const getInitFile = useCallback(async () => {
    const { file } = await getFilesById(Number(id));
    setFile(file);
  }, [id]);

  const { asyncCallback: asyncGetInitFiles } = useAsyncCallback(getInitFile, []);

  useEffect(() => {
    asyncGetInitFiles();
  }, [id]);

  const handleOnBack = () => navigate(`/admin/control-panel?tab=${TAB_CONTROL_PANEL.ARCHIVE}`);

  return {
    dataDetail: file,
    handleOnBack
  };
};
