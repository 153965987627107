import { useCallback, useEffect, useState } from 'react';
import { fileResCommunication, TAB } from '../../type';
import { UpdateTabProps } from './index';
import { SIZE_PAGE_COMMUNICATION } from 'const/paging';
import { getUpdatesCommunication } from 'services/communication';
import { useSearchParams } from 'react-router-dom';

export const useInjection = ({ initData, dateSearch }: Partial<UpdateTabProps>) => {
  const [dataRow, setDataRow] = useState<fileResCommunication[]>([]);
  const [page, setPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [dataClientSelected, setDataClientSelected] = useState<fileResCommunication | undefined>(
    undefined
  );
  useEffect(() => {
    if (!initData) {
      setDataRow([]);
      return;
    }
    setDataRow(initData.updates);
    if (initData.total > SIZE_PAGE_COMMUNICATION * page) {
      setHasMore(true);
      setPage(2);
      return;
    }
  }, [initData, page]);

  const fetchMoreData = useCallback(async () => {
    if (page === 1) return;
    const res = await getUpdatesCommunication({
      tab: searchParams.get('tab') || TAB.UPDATES,
      page,
      size: SIZE_PAGE_COMMUNICATION,
      endDate: dateSearch?.endDate,
      startDate: dateSearch?.startDate
    });
    const newData = res.updates;
    setDataRow((prevState) => [...prevState, ...newData]);
    setPage((prevState) => prevState + 1);
    if (res.total > SIZE_PAGE_COMMUNICATION * page) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [searchParams, page, dateSearch]);

  useEffect(() => {
    setDataClientSelected(undefined);
  }, [searchParams.get('tab')]);

  return {
    dataRow,
    hasMore,
    fetchMoreData,
    dataClientSelected,
    setDataClientSelected
  };
};
