import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationPostSeizureSchema } from 'utils/validate';
import { useEffect, useState } from 'react';
import { FormPostSeizure, FormPostSeizureProps } from './FormPostSeizure';
import { optionArrearsOther } from 'const/option';
import { TFilePhoto } from 'types/user';

export const useInjection = ({ initDataForm }: Partial<FormPostSeizureProps>) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields },
    reset,
    control,
    clearErrors,
    setValue
  } = useForm<FormPostSeizure>({
    resolver: yupResolver(validationPostSeizureSchema),
    defaultValues: { arrearsOther: optionArrearsOther[0].value }
  });

  const fileDocument = watch('postSeizuresDocuments') ?? [];

  useEffect(() => {
    reset(initDataForm);
  }, [initDataForm]);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const [contentModal, watchArrears] = watch(['status', 'arrears']);

  const handleDeleteDocument = (doc: Partial<TFilePhoto>) => {
    setValue(
      'postSeizuresDocuments',
      fileDocument?.filter((file) => file.id !== doc.id)
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    const fileConvert = newFiles.map((item, index) => ({
      file: item,
      id: fileDocument.length + index,
      url: URL.createObjectURL(new Blob([item], { type: item?.type }))
    }));

    setValue('postSeizuresDocuments', [...fileDocument, ...fileConvert] as TFilePhoto[]);
  };

  return {
    errors,
    handleSubmit,
    register,
    watch,
    control,
    clearErrors,
    setValue,
    setIsShowModal,
    isShowModal,
    contentModal,
    dirtyFields,
    watchArrears,
    fileDocument,
    handleFileChange,
    handleDeleteDocument
  };
};
