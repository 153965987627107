import { InputAdornment, Stack, Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { forgotPasswordSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormForgotPasswordType = {
  email: string;
};

type FormForgotPasswordProps = {
  onSubmit: (data: FormForgotPasswordType) => void;
};
export const FormForgotPassword = ({ onSubmit }: FormForgotPasswordProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormForgotPasswordType>({ resolver: yupResolver(forgotPasswordSchema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack width={'100%'} alignItems={'center'}>
          <Box sx={{ width: '370px', textAlign: ' center', marginTop: '17px' }}>
            Please enter your email address below and we'll send you a link to reset your password.
          </Box>
        </Stack>
        <Stack gap={1}>
          <InputText
            inputError={errors.email?.message}
            placeholder={'Email'}
            inputProps={{
              ...register('email')
            }}
            endAdornment={
              <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
                <img alt={'user'} src={'/icons/icon_email.svg'} />
              </InputAdornment>
            }
          />
        </Stack>
        <ButtonCustom type={'submit'} label={'Continue'} />
      </Stack>
    </form>
  );
};
