import { useCallback } from 'react';
import { createSeizure } from 'services/files';
import { useFlash } from 'contexts/flash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { TAB_CONTROL_PANEL } from 'const/admin';
import { FormPostSeizure } from '../components/modules/FormPostSeizure/FormPostSeizure';
import axios from 'axios';

export const useCreatePostSeizure = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onSubmitPostSeizure = useCallback(async (formData: FormPostSeizure) => {
    const formData2 = new FormData();
    let filePhotosIds: number[] = [];
    const filePhotos = formData.postSeizuresDocuments?.filter((item) => item.file);
    if (filePhotos?.length) {
      for (let x = 0; x < filePhotos.length; x++) {
        formData2.append('files', filePhotos[x]!.file!, filePhotos[x]!.file!.name);
      }
      const { data: resListIdFile } = await axios.post(
        '/storages/post_seizures_document',
        formData2,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      filePhotosIds = resListIdFile.files;
      formData2.delete('files');
    }
    await createSeizure({ ...formData, ...{ documentIds: filePhotosIds } });
    setFlash({ type: 'success', message: 'Create post seizure successful' });
    navigate(`/admin/redemptions`);
  }, []);

  const { asyncCallback: asyncCreateSeizure } = useAsyncCallback(onSubmitPostSeizure, []);

  const handleOnBack = () => navigate(`/admin/control-panel?tab=${TAB_CONTROL_PANEL.ARCHIVE}`);

  return {
    onSubmitPostSeizure: asyncCreateSeizure,
    handleOnBack,
    searchParams
  };
};
