import React, { Fragment, memo, useCallback } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomDatePicker from 'app/components/elements/InputDate';
import dayjs from 'dayjs';
import { dateCommunication, fileResCommunication } from '../type';
import { CellDataType, PrimaryTableHeader } from 'app/components/elements/TableInfiniteScroll';
import { compact } from 'lodash';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Lead, TNote } from 'types/user';
import InfiniteScroll from 'react-infinite-scroll-component';
import { heightScreen, ROLE } from 'const/common';
import { renderTitleLead } from 'utils/renderTitleLead';
import DeleteIcon from '@mui/icons-material/Delete';

const renderContentTableUpdates = (
  rows: TNote[],
  dataClientSelected: fileResCommunication | undefined,
  lead: Partial<Lead>,
  handleDeleteNote: (value: { idNote: number; lead?: Partial<Lead> }) => void
) => {
  const handleButtonClickCopy = async (id: number) => {
    if (!lead?.id) return;
    const itemNote = rows.find((item) => Number(item.id) === id);
    if (!itemNote) {
      alert('Cannot copy note');
      return;
    }
    const titleLead = renderTitleLead(lead);
    const textCopyArray = `${titleLead} - ${dayjs(itemNote.updatedAt).format('M/D/YYYY, h:mm A')} - ${itemNote.content}`;
    try {
      await navigator.clipboard.writeText(textCopyArray);
    } catch (error) {
      console.error('Failed to copy text to clipboard: ', error);
    }
  };
  return rows.map((item: TNote) => {
    if (item?.deletedAt) return;
    const lead = dataClientSelected?.leads.find(
      (itemLead) => Number(itemLead.id) === Number(item.leadId)
    );
    return {
      updatedAt: {
        value: (
          <Typography fontSize={'13px'}>
            {item.user.role === ROLE.ADMIN
              ? 'Admin'
              : `${item?.user?.firstName} ${item?.user?.firstName}`}
          </Typography>
        )
      },
      date: {
        value: (
          <Typography fontSize={'13px'}>
            {dayjs(item?.updatedAt).format('h:mma | MM/DD/YYYY')}
          </Typography>
        )
      },
      lead: {
        value: <Typography fontSize={'13px'}>{lead ? renderTitleLead(lead) : ''}</Typography>
      },
      details: {
        value: (
          <Typography fontSize={'13px'} color={item.deletedAt || !item?.content ? 'red' : 'unset'}>
            {item.deletedAt || !item?.content ? `Remove note` : item?.content}
          </Typography>
        )
      },
      action: {
        value: (
          <Stack flexDirection={'row'} gap={2}>
            <IconButton
              sx={{ padding: '0 8px 0 0' }}
              onClick={() => handleButtonClickCopy(Number(item.id))}>
              <ContentCopyOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{ padding: '0 8px 0 0' }}
              onClick={() => {
                handleDeleteNote({ idNote: Number(item.id), lead: lead });
              }}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        )
      }
    };
  });
};

type ActiveTabProps = {
  handleChangeDate: (startDate?: string | null, endDate?: string | null) => void;
  headers: PrimaryTableHeader[];
  rows: fileResCommunication[];
  label: string;
  date: dateCommunication | null;
  hasMore: boolean;
  fetchMoreData: () => void;
  dataClientSelected: fileResCommunication | undefined;
  setDataClientSelected: (value: fileResCommunication | undefined) => void;
  handleDeleteNote: (value: { idNote: number; lead?: Partial<Lead> }) => void;
};

const TableCommunication = ({
  rows,
  label,
  headers,
  handleChangeDate,
  date,
  hasMore,
  fetchMoreData,
  dataClientSelected,
  setDataClientSelected,
  handleDeleteNote
}: ActiveTabProps) => {
  const handleChooseClient = useCallback(
    (id: number) => {
      const dataSelected = rows.find((item) => Number(item.id) === Number(id));
      setDataClientSelected(dataSelected);
    },
    [rows, setDataClientSelected]
  );

  return (
    <WrapperBox>
      <Stack p={2} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography fontSize={18} fontWeight={700} marginBottom={2.5}>
          {label}
        </Typography>
        <Stack direction="row" alignItems={'center'} gap={1.5}>
          <TextDateStyled>From</TextDateStyled>
          <CustomDatePicker
            title={''}
            onChange={(value) => {
              const dateStartConvert = value ? String(value) : '';
              handleChangeDate(dateStartConvert, date?.endDate);
            }}
            value={date?.startDate ? dayjs(date?.startDate) : null}
            maxDate={date?.endDate ? dayjs(date?.endDate) : undefined}
          />
          <TextDateStyled>To</TextDateStyled>
          <CustomDatePicker
            title={''}
            onChange={(value) => {
              const dateStartConvert = value ? String(value) : '';
              handleChangeDate(date?.startDate, dateStartConvert);
            }}
            value={date?.endDate ? dayjs(date?.endDate) : null}
            minDate={date?.startDate ? dayjs(date?.startDate) : undefined}
          />
        </Stack>
      </Stack>

      <WrapperContainer>
        <ListClientWrapper height={heightScreen - 450} overflow={'scroll'} id={'divScroll'}>
          <InfiniteScroll
            dataLength={rows.length}
            next={fetchMoreData}
            hasMore={hasMore}
            scrollableTarget={'divScroll'}
            loader={
              <Box p={1}>
                <CircularProgress />
              </Box>
            }>
            {rows.map((item, index) => {
              const userAssign = compact(
                item.dispatchFiles?.map((item) => `${item.user.firstName} ${item.user.lastName}`)
              );
              return (
                <WrapperItem
                  style={{ background: item.id === dataClientSelected?.id ? '#F1F1E8' : '#FFFFFF' }}
                  key={index}
                  onClick={() => handleChooseClient && handleChooseClient(item.id)}>
                  <Stack gap={0.8}>
                    <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                      <Stack
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '4px',
                          background: '#22374A'
                        }}
                      />
                      <Typography fontSize={'16px'} fontWeight={500}>
                        {item.clientOption.value}
                      </Typography>
                    </Stack>
                    <Typography fontSize={'13px'} fontWeight={500}>
                      {`File Number: ${item.fileNumber}`}
                    </Typography>
                  </Stack>
                  <Stack style={{ alignItems: 'flex-start', width: '40%' }}>
                    <Typography fontSize={'12px'} color={'#7F7F7F'} fontWeight={400}>
                      {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Typography fontSize={'12px'} color={'#7F7F7F'} fontWeight={400}>
                      {`Seizure Report added by ${userAssign.join()}`}
                    </Typography>
                  </Stack>
                </WrapperItem>
              );
            })}
          </InfiniteScroll>
        </ListClientWrapper>
        <ListClientWrapper
          sx={{ borderRightWidth: 0 }}
          p={2}
          height={heightScreen - 450}
          overflow={'scroll'}
          id={'divScroll'}>
          <Stack gap={1.25}>
            <Stack direction="row" justifyContent={'space-between'}>
              <Stack gap={1}>
                <Typography fontSize={16} fontWeight={600}>
                  {dataClientSelected?.clientOption.value}
                </Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {dataClientSelected?.fileNumber}
                </Typography>
              </Stack>
            </Stack>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: PrimaryTableHeader, index: number) => {
                      return (
                        <Fragment key={index}>
                          <TableCellStyled
                            onClick={() => {
                              header.onClick &&
                                dataClientSelected?.id &&
                                header.onClick(dataClientSelected.id);
                            }}
                            width={header.width}>
                            {header.label}
                          </TableCellStyled>
                        </Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataClientSelected?.leads?.map((item) => {
                    if (item.notes?.length) {
                      return renderContentTableUpdates(
                        item.notes,
                        dataClientSelected,
                        item,
                        handleDeleteNote
                      ).map((itemNote, index) => {
                        if (!itemNote) return;
                        return (
                          <TableRowStyled
                            sx={{
                              backgroundColor: '#fff'
                            }}
                            key={index}
                            onClick={() => {}}>
                            {Object.values(itemNote)?.map(
                              (record: CellDataType, colIndex: number) => {
                                return (
                                  <Fragment key={colIndex}>
                                    <TableCellStyled>{record.value}</TableCellStyled>
                                  </Fragment>
                                );
                              }
                            )}
                          </TableRowStyled>
                        );
                      });
                    }
                    return <> </>;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </ListClientWrapper>
      </WrapperContainer>
    </WrapperBox>
  );
};

export const TableCommunicationMemo = memo(TableCommunication);

const ListClientWrapper = styled(Stack)(() => ({
  width: '50%',
  border: '1px solid #686E7D',
  borderLeftWidth: 0
}));
const WrapperContainer = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row'
}));
const WrapperItem = styled(Stack)(() => ({
  borderBottom: '1px solid #686E7D',
  width: '100%',
  padding: '20px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  cursor: 'pointer',
  ' &:hover': {
    opacity: 0.8,
    background: '#F1F1E8'
  }
}));

const WrapperBox = styled(Stack)(() => ({
  border: '1px solid #686E7D',
  padding: '14px 0 20px 0',
  borderRadius: '16px',
  flexDirection: 'column',
  marginTop: '20px'
}));

const TextDateStyled = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400,
  color: '#0A0A0A'
}));
const TableRowStyled = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  }
}));
const TableCellStyled = styled(TableCell)(() => ({
  padding: '14px 8px'
}));
