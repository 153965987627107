import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { StyledParams } from 'types/common';
import { Lead, TypeLead } from 'types/user';
import React, { memo, useState } from 'react';
import { ModalNewPhotos } from './ModalNewPhoto';
import { NoteCustomMemo } from 'app/components/elements/NoteCustom';
import { CheckBoxCustom } from 'app/components/elements/CheckBoxCustom';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { ROLE } from 'const/common';
import { BoxPhotoMemo } from './BoxPhoto';
import { compact } from 'lodash';
import { TowYardFormMemo } from './FormTowYard';
import { FormVinCheckMemo } from './FormVinCheck';
import { FormCarfax } from './FormCarfax';
import { renderTitleLead } from 'utils/renderTitleLead';

type AddressProps = {
  onClickAttendance: () => void;
  onClickDoor: () => void;
};
const Address = ({ onClickAttendance, onClickDoor }: AddressProps) => {
  return (
    <WrapperAddress>
      <Stack gap={'20px'} flexDirection={'row'}>
        <WrapperButtonAddress onClick={onClickAttendance}>
          <img src={'/icons/icon_calender.svg'} alt={'icon'} />
          <TextTitle>Attendance</TextTitle>
        </WrapperButtonAddress>
        <WrapperButtonAddress onClick={onClickDoor}>
          <img src={'/icons/icon_door.svg'} alt={'icon'} />
          <TextTitle>Door Knock</TextTitle>
        </WrapperButtonAddress>
      </Stack>
    </WrapperAddress>
  );
};

const NumberPhoneForm = ({ phoneNumber }: { phoneNumber?: string }) => {
  return (
    <WrapperAddress>
      <Stack gap={'20px'} flexDirection={'row'}>
        <a href={`tel:${phoneNumber}`}>
          <WrapperButtonAddress>
            <img src={'/icons/icon_phone.svg'} alt={'icon'} />
            <TextTitle>Call</TextTitle>
          </WrapperButtonAddress>
        </a>
        <a href={`sms:${phoneNumber}`}>
          <WrapperButtonAddress>
            <img src={'/icons/icon_message.svg'} alt={'icon'} />
            <TextTitle>Text</TextTitle>
          </WrapperButtonAddress>
        </a>
      </Stack>
    </WrapperAddress>
  );
};

const WrapperAddress = styled(Stack)(() => ({
  width: '100%'
}));
const WrapperButtonAddress = styled(Button)(() => ({
  height: '50px',
  padding: '0 12px',
  alignItems: 'center',
  gap: '10px',
  flexDirection: 'row',
  borderRadius: '8px',
  border: `1px solid #000}`
}));

export type AddNote = {
  isNewLead?: boolean;
  idLead: number;
  textNote: string;
  isAdmin?: boolean;
};

type LeadBoxProps = {
  isAdmin?: boolean;
  item: Partial<Lead>;
  handleDeleteNoteLead?: (idNote: number, idLead: number) => void;
  handleAddNewNote?: ({ isNewLead, idLead, textNote, isAdmin }: AddNote) => void;
  handleEditNote?: (idLead: number, idNote: number, content: string) => void;
  handleDeleteLead?: () => void;
  handleAddNewPhotos?: (files: File[], textNote: string, isEarly?: boolean) => void;
  handleChooseRuleOut?: (idLead: number, ruleOut: boolean) => void;
  handleChooseDoorKnock?: (idLead: number, doorKnock: boolean) => void;
  handleChooseEarlyMorning?: (idLead: number, earlyMorning: boolean) => void;
  handleAddTowYards?: ({
    idLead,
    towYards
  }: {
    idLead: number;
    towYards: { value: string; checked: boolean; user?: { role: string } }[];
  }) => void;
  handleDeleteTowYards?: ({ idTowYards, idLead }: { idLead: number; idTowYards: number }) => void;
  handleChangeVinStatus?: ({ vinStatus, idLead }: { idLead: number; vinStatus: string }) => void;
  handleUploadFileCarfax?: ({
    idLead,
    files,
    oldDocIds
  }: {
    idLead: number;
    files: File[];
    oldDocIds: number[];
  }) => void;
  handleDeleteDocumentLead?: ({
    idLead,
    oldDocIds
  }: {
    idLead: number;
    oldDocIds: number[];
  }) => void;
  isCompleted?: boolean;
  isOpen: boolean;
  onOpenLead: () => void;
};
const LeadBox = ({
  item,
  handleDeleteNoteLead,
  handleAddNewNote,
  isAdmin,
  handleEditNote,
  handleDeleteLead,
  handleAddNewPhotos,
  handleChooseRuleOut,
  handleChooseDoorKnock,
  handleChooseEarlyMorning,
  handleAddTowYards,
  handleDeleteTowYards,
  handleChangeVinStatus,
  handleUploadFileCarfax,
  handleDeleteDocumentLead,
  isCompleted,
  isOpen,
  onOpenLead
}: LeadBoxProps) => {
  const [showModalNewPhoto, setShowModalNewPhoto] = useState<boolean>(false);
  const [showModalDoorKnock, setShowModalDoorKnock] = useState<boolean>(false);

  return (
    <AccordionStyled
      onChange={onOpenLead}
      expanded={isOpen}
      style={{ background: item.ruleOut ? '#D2D2CF' : 'unset' }}
      slotProps={{ transition: { unmountOnExit: true } }}
      id={`lead-detail-${item.id}`}>
      <AccordionSummary
        expandIcon={
          <WrapperIcon>
            <img alt={'icon'} src={'/icons/chevron_down_mobile.svg'} />
          </WrapperIcon>
        }
        aria-controls="panel1-content"
        id="panel1-header">
        <TextTitle inactive={item.ruleOut ? 'inactive' : ''}>{renderTitleLead(item)}</TextTitle>
      </AccordionSummary>
      <AccordionDetails
        style={{ position: 'relative', pointerEvents: isCompleted ? 'none' : 'auto' }}>
        {(isCompleted || item.ruleOut) && <Overlay />}
        {(item?.type === TypeLead.POR ||
          item?.type === TypeLead.POE ||
          item?.type === TypeLead.OTHER_ADDRESS ||
          item.type === TypeLead.MVS) && (
          <Stack gap={2.5}>
            {item?.type === TypeLead.POE && (
              <Stack gap={2}>
                <TextInfo>{`Name POE: ${item?.namePOE}`}</TextInfo>
                <TextInfo>{`Number Phone POE: ${item?.phoneNumber}`}</TextInfo>
              </Stack>
            )}
            {!isAdmin && (
              <>
                <Address
                  onClickAttendance={() => setShowModalNewPhoto(true)}
                  onClickDoor={() => setShowModalDoorKnock(true)}
                />
                <Stack flexDirection={'row'} gap={2.5}>
                  <CheckBoxCustom
                    onChange={(event) => {
                      if (!item.id || !handleChooseEarlyMorning) return;
                      handleChooseEarlyMorning(item.id, event.target.checked);
                    }}
                    checked={item?.earlyMorning}
                    label={'Early Morning'}
                  />
                  <CheckBoxCustom
                    onChange={(event) => {
                      if (!item.id || !handleChooseDoorKnock) return;
                      handleChooseDoorKnock(item.id, event.target.checked);
                    }}
                    checked={item?.doorKnock}
                    label={'Door Knock'}
                  />
                </Stack>
              </>
            )}
            <BoxPhotoMemo photos={item.notes?.flatMap((item) => compact(item.photos))} />
          </Stack>
        )}
        {!isAdmin &&
          (item?.type === TypeLead.PHONE_NUMBER || item?.type === TypeLead.REFERENCE) && (
            <Stack gap={2.5} pb={2}>
              <NumberPhoneForm phoneNumber={item?.phoneNumber} />
            </Stack>
          )}
        {/*{item?.type === TypeLead.MVS && (*/}
        {/*  <Stack>*/}
        {/*    <WrapperInfo>*/}
        {/*      <TextInfo>{`First Name: ${item?.firstName}`}</TextInfo>*/}
        {/*      <TextInfo>{`Last Name: ${item?.lastName}`}</TextInfo>*/}
        {/*      <TextInfo>{`R.O. Address: ${item?.address}`}</TextInfo>*/}
        {/*      <TextInfo>{`Colour: ${item?.colour}`}</TextInfo>*/}
        {/*      <TextInfo>{`Plate: ${item?.plate}`}</TextInfo>*/}
        {/*    </WrapperInfo>*/}
        {/*  </Stack>*/}
        {/*)}*/}
        {item?.type === TypeLead.VIN && (
          <FormVinCheckMemo
            value={item.vinStatus || ''}
            onChange={(value: string) => {
              if (!item.id) return;
              handleChangeVinStatus && handleChangeVinStatus({ idLead: item.id, vinStatus: value });
            }}
          />
        )}
        {item.type === TypeLead.TOW_YARDS && (
          <TowYardFormMemo
            isAdminPage={isAdmin}
            initTowYards={item.leadTowYards?.map((item) => ({
              ...item,
              id: Number(item.id),
              value: item.content
            }))}
            handleAddTowYards={(
              towYards: { value: string; checked: boolean; user?: { role: string } }[]
            ) => {
              if (!item.id) return;
              handleAddTowYards && handleAddTowYards({ idLead: item.id, towYards });
            }}
            handleDeleteTowYards={(idTowYard: number) => {
              if (!item.id) return;
              handleDeleteTowYards &&
                handleDeleteTowYards({ idTowYards: idTowYard, idLead: item.id });
            }}
          />
        )}

        {item.type === TypeLead.CARFAX && (
          <FormCarfax
            initDocument={item?.leadDocuments}
            handleUploadFileCarfax={(files, oldDocIds) => {
              if (!item.id) return;
              handleUploadFileCarfax &&
                handleUploadFileCarfax({ idLead: item.id, files, oldDocIds });
            }}
            handleDeleteDocument={(oldDocIds) => {
              if (!item.id) return;
              handleDeleteDocumentLead && handleDeleteDocumentLead({ idLead: item.id, oldDocIds });
            }}
          />
        )}

        <NoteCustomMemo
          isAdmin={isAdmin}
          handleEditNote={({ idNote, textNote }: { idNote: number; textNote: string }) => {
            if (item?.id) {
              handleEditNote && handleEditNote(item.id, idNote, textNote);
            }
          }}
          notes={item.notes?.filter((item) => item.user?.role === ROLE.USER)}
          notesAdmin={item.notes?.filter(
            (item) => item.user?.role === ROLE.ADMIN || item.user?.role === ROLE.ROOT_ADMIN
          )}
          handleDelete={(idNote: number) => {
            if (item?.id) {
              handleDeleteNoteLead && handleDeleteNoteLead(idNote, item.id);
            }
          }}
          adminEditNote={(idNote: number, content: string) => {
            if (item?.id) {
              handleEditNote && handleEditNote(item.id, idNote, content);
            }
          }}
          handleAddNewNote={(textNote: string, isAdmin?: boolean) => {
            if (item?.id) {
              handleAddNewNote && handleAddNewNote({ idLead: item.id, textNote, isAdmin });
            }
          }}
        />
        {!isAdmin && (
          <Stack position={'relative'} style={{ zIndex: 150, width: 'max-content' }}>
            <CheckBoxCustom
              checked={item.ruleOut}
              onChange={(event) => {
                if (!item.id || !handleChooseRuleOut) return;
                handleChooseRuleOut(item.id, event.target.checked);
              }}
              label={'Rule Out'}
            />
          </Stack>
        )}
        {isAdmin && (
          <Stack alignItems={'flex-end'}>
            <ButtonCustom
              label={'Delete Lead'}
              onClick={handleDeleteLead}
              isDelete
              style={{ width: 200 }}
            />
          </Stack>
        )}
      </AccordionDetails>
      {handleAddNewPhotos && (
        <>
          <ModalNewPhotos
            isOpen={showModalNewPhoto}
            textTitle={'Attendance'}
            defaultValue="Attended"
            onClose={() => setShowModalNewPhoto(false)}
            onSubmit={(files: File[], textNote: string) => {
              handleAddNewPhotos(files, textNote, true);
              setShowModalNewPhoto(false);
            }}
          />
          <ModalNewPhotos
            isOpen={showModalDoorKnock}
            textTitle={'Door Knock'}
            defaultValue="Door Knocked"
            onClose={() => setShowModalDoorKnock(false)}
            onSubmit={(files: File[], textNote: string) => {
              handleAddNewPhotos(files, textNote);
              setShowModalDoorKnock(false);
            }}
          />
        </>
      )}
    </AccordionStyled>
  );
};

export const LeadBoxMemo = memo(LeadBox);
const Overlay = styled(Stack)(() => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: '#D2D2CF',
  opacity: 0.8,
  zIndex: 130
}));
const AccordionStyled = styled(Accordion)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.light3}`,
  boxShadow: 'none',
  borderRadius: '10px',
  '&:first-of-type': {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
  },
  '&:before': {
    background: 'none'
  },
  '&.Mui-expanded': {
    margin: 0
  },
  '.MuiAccordionSummary-root': {
    width: '100%',
    height: '77px',
    borderRadius: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 7px 0 15px'
  }
}));
const TextTitle = styled(Typography)<{ inactive?: string }>(
  ({ theme, inactive }: StyledParams & { inactive?: string }) => ({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: inactive ? theme.colors.grayscale3 : theme.colors.dark1,
    textDecoration: inactive ? 'line-through' : 'unset'
  })
);
const WrapperIcon = styled(Stack)(() => ({
  height: 30,
  width: 30,
  borderRadius: 15,
  border: '0.2px solid #5C5C5C',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 0,
  padding: 0
}));
// const WrapperInfo = styled(Stack)(({ theme }: StyledParams) => ({
//   flexDirection: 'column',
//   alignItems: 'flex-start',
//   gap: 8,
//   paddingBottom: '20px',
//   borderBottom: `1px solid ${theme.colors.light3}`
// }));
const TextInfo = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: '14px',
  fontWeight: 500
}));
