import React, { useCallback } from 'react';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { TabsCustom } from 'app/components/elements/TabsCutom';
import { Helmet } from 'react-helmet-async';
import { useInjection } from './hook';
import { headerTab, TAB } from './type';
import { UpdateTab } from './components/UpdateTab';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';

function CommunicationPage() {
  const {
    tab,
    handleChangeTab,
    dates,
    searchParams,
    handleCopyAllUpdates,
    communicationUpdate,
    communicationSeizure,
    communicationOutOfLead,
    handleChangeDate,
    handleDeleteNote,
    onDeleteLead,
    isDeleteNote,
    setIdDeleteNote
  } = useInjection();
  const renderTab = useCallback(() => {
    const tab = searchParams.get('tab') || TAB.UPDATES;
    if (tab === TAB.UPDATES) {
      return (
        <UpdateTab
          handleDeleteNote={handleDeleteNote}
          dateSearch={dates.updates}
          handleChangeDate={handleChangeDate}
          initData={communicationUpdate}
          handleCopyAllUpdates={(id) => handleCopyAllUpdates(id, communicationUpdate.updates)}
        />
      );
    }
    if (tab === TAB.SEIZURE_REPORTS) {
      return (
        <UpdateTab
          handleDeleteNote={handleDeleteNote}
          dateSearch={dates.seizureReports}
          handleChangeDate={handleChangeDate}
          initData={communicationSeizure}
          handleCopyAllUpdates={(id) => handleCopyAllUpdates(id, communicationSeizure.updates)}
        />
      );
    }
    if (tab === TAB.OUT_OF_LEADS) {
      return (
        <UpdateTab
          handleDeleteNote={handleDeleteNote}
          dateSearch={dates.outOfLeads}
          handleChangeDate={handleChangeDate}
          initData={communicationOutOfLead}
          handleCopyAllUpdates={(id) => handleCopyAllUpdates(id, communicationOutOfLead.updates)}
        />
      );
    }
    return <></>;
  }, [
    searchParams,
    handleDeleteNote,
    dates.updates,
    dates.seizureReports,
    dates.outOfLeads,
    handleChangeDate,
    communicationUpdate,
    handleCopyAllUpdates,
    communicationSeizure,
    communicationOutOfLead
  ]);

  return (
    <LayoutAdminTable title={'Communication'}>
      <Helmet>
        <title>Surrey Bailiffs - Communication</title>
      </Helmet>
      <TabsCustom
        tab={tab}
        contentTab={renderTab()}
        headerTab={headerTab}
        onChangeTab={handleChangeTab}
      />
      <DialogConfirm
        width={'450px'}
        isOpen={!!isDeleteNote}
        onClose={() => setIdDeleteNote(null)}
        onSubmit={onDeleteLead}
        textTitle={'Delete Note'}
        textButton={'Delete'}
        textContent={'Are you sure delete note.'}
      />
    </LayoutAdminTable>
  );
}

export default CommunicationPage;
