import { Stack, Typography } from '@mui/material';

const ForbiddenPage = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100dvh"
      spacing={3}
      sx={{ backgroundColor: '#f5f5f5', padding: 2 }}>
      <Typography variant="h1" color="error">
        403
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Forbidden - You do not have permission to access this page.
      </Typography>
    </Stack>
  );
};

export default ForbiddenPage;
