import React, { ChangeEvent, memo } from 'react';
import { IconButton, Stack, styled, Typography } from '@mui/material';
import { StyledParams } from 'types/common';
import dayjs from 'dayjs';
import { TFile } from 'types/user';
import { BoxStatus } from '../../../components/elements/BoxStatus';
import { EditIcon } from '../../../../assets/icon';
import { InputText } from '../../../components/elements/InputText';
import { ButtonCustom } from '../../../components/elements/ButtonCustom';
type HeaderFileDetailProps = {
  file?: Partial<TFile>;
  showQuickNote?: boolean;
  setShowQuickNote?: (value: boolean) => void;
  textQuickNote?: string;
  setTextQuickNote?: (value: string) => void;
  handleAddQuickNote?: () => void;
};
const HeaderFileDetail = ({
  file,
  showQuickNote,
  setShowQuickNote,
  setTextQuickNote,
  textQuickNote,
  handleAddQuickNote
}: HeaderFileDetailProps) => {
  const numberWithCommas = (money?: number) => {
    if (!money) return '';
    const parts = money.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  return (
    <WrapperHeader>
      <WrapperTimeAndButton>
        <StackTime>
          <img
            src={'/icons/icon_date_time.svg'}
            alt={'icon-date'}
            style={{ width: 20, height: 20 }}
          />
          <TextTimeStyled>{dayjs().format('DD/MM/YYYY | hh:mm A')}</TextTimeStyled>
        </StackTime>
        <BoxStatus status={file?.status || ''} />
      </WrapperTimeAndButton>
      <WrapperInfo>
        {file?.quickNote && (
          <Stack
            width={'100%'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}>
            {showQuickNote ? (
              <InputText
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setTextQuickNote && setTextQuickNote(event.target.value);
                }}
                inputProps={{
                  value: textQuickNote
                }}
                placeholder={'Edit Quick Note'}
              />
            ) : (
              <TextTitle>{` ${file?.quickNote}`}</TextTitle>
            )}
            {showQuickNote !== null && (
              <Stack flexDirection={'row'} alignItems={'center'}>
                {showQuickNote && (
                  <ButtonCustom
                    sx={{ minWidth: 50, height: 30, borderRadius: `4px !important` }}
                    label={'Add'}
                    onClick={handleAddQuickNote}
                  />
                )}
                <IconButton
                  onClick={() => {
                    setShowQuickNote && setShowQuickNote(!showQuickNote);
                  }}
                  sx={{ width: '46px', height: '46px' }}>
                  <EditIcon
                    width={'40px'}
                    fill={showQuickNote ? '#22374A' : '#fff'}
                    color={showQuickNote ? '#fff' : '#22374A'}
                  />
                </IconButton>
              </Stack>
            )}
          </Stack>
        )}
        <TextInfo>{`Client File Number: ${file?.fileNumber}`}</TextInfo>
        <TextInfo>{`Client: ${file?.clientOption?.value}`}</TextInfo>
        <TextInfo>{`Last Name: ${file?.lastName}`}</TextInfo>
        <TextInfo>{`First Name: ${file?.firstName}`}</TextInfo>
        <TextInfo>{`Auction Delivery Location: ${file?.auctionDeliveryLocation?.value}`}</TextInfo>
        <TextInfo>{`Year, Make, Model: ${file?.year} ${file?.make} ${file?.model}`}</TextInfo>
        <TextInfo>{`VIN: ${file?.vin}`}</TextInfo>
        <TextInfo>{`Arrears:  $${numberWithCommas(file?.arrears)}`}</TextInfo>
        <TextInfo>{`Contract Balance: $${numberWithCommas(file?.contractBalance)}`}</TextInfo>
        <TextInfo>{`Contact Date: ${dayjs(file?.contactDate).format('YYYY/MM/DD')}`}</TextInfo>
        <TextInfo>{`PPSA Base Registration Number: ${file?.ppsaBaseRegistrationNumber}`}</TextInfo>
        <TextInfo>{`PPSA Registration Date: ${dayjs(file?.ppsaRegistrationDate).format('YYYY/MM/DD')}`}</TextInfo>
      </WrapperInfo>
    </WrapperHeader>
  );
};

export const HeaderFile = memo(HeaderFileDetail);
const WrapperHeader = styled(Stack)(() => ({}));
const WrapperTimeAndButton = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '20px'
}));
const StackTime = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px'
}));
const TextTimeStyled = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark2,
  fontWeight: 500,
  lineHeight: '16.8px'
}));
const WrapperInfo = styled(Stack)(({ theme }: StyledParams) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 8,
  paddingBottom: '20px',
  borderBottom: `1px solid ${theme.colors.light3}`
}));
const TextInfo = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: '13px',
  fontWeight: 500
}));
const TextTitle = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: '18px',
  fontWeight: 900
}));
