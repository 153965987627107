import axios from 'axios';

import { GetFilesParams } from 'types/user';
import * as qs from 'qs';

export const getFiles = async (params: Partial<GetFilesParams>) => {
  const query = qs.stringify({
    ...params,
    earlyMorning: params.earlyMorning || undefined,
    doorKnock: params.doorKnock || undefined,
    status: params.status,
    types: params.types
  });
  const { data } = await axios.get(`/files?${query}`);
  return data;
};
export type BailiffUpdateFile = {
  quickNote: string;
};
export const bailiffUpdateFile = async (id: number, payload: BailiffUpdateFile) => {
  const { data } = await axios.put(`/files/${id}`, payload);
  return data;
};

export const getFilesNotDispatch = async (params: { size: number; page: number }) => {
  const query = qs.stringify({
    ...params
  });
  const { data } = await axios.get(`/admin/file-not-dispatch?${query}`);
  return data;
};

export type CreateFilePayload = {
  fileNumber: string;
  firstName: string;
  lastName: string;
  vin: string;
  model: string;
  make: string;
  year: string;
  filePhotosIds: number[];
  fileDocumentsIds: number[];
  arrears: number;
  contractBalance: number;
  ppsaBaseRegistrationNumber: string;
  ppsaRegistrationDate: Date;
  contactDate: Date;
  auctionDeliveryLocationId: number;
  clientOptionsId: number;
};

export type CreateSeizurePayload = {
  fileId: number;
  arrears?: number;
  bailiffFees?: number;
  redemptionFees?: number;
  lenderFees?: number;
  storageFees?: number;
  towBill?: number;
  interimStatementSent?: Date;
  followUpSent?: Date;
  appointmentBooked?: Date;
  finalStatementSent?: Date;
  status: string;
  documentIds: number[];
};

export type UpdateFilePayload = Partial<CreateFilePayload> & {
  redemption?: boolean;
};

// admin
export const createFiles = async (payload: CreateFilePayload) => {
  const { data: res } = await axios.post('/admin/files', payload);
  return res;
};
export const updateFiles = async (id: number, payload: UpdateFilePayload) => {
  const { data: res } = await axios.put(`/admin/files/${id}`, payload);
  return res;
};

export const deleteFile = async (id: number) => {
  const { data: res } = await axios.delete(`/admin/files/${id}`);
  return res;
};

export const updateStatusTransition = async (id: number, payload: string) => {
  const { data: res } = await axios.put(`/files/status-transition/${id}`, { status: payload });
  return res;
};

export const getCountFileByStatus = async () => {
  const { data } = await axios.get('/admin/file-status');
  return data;
};

export const createSeizure = async (payload: CreateSeizurePayload) => {
  const { data: res } = await axios.post('/file-post-seizures', payload);
  return res;
};

export const getDetailSeizure = async (id: number) => {
  const { data: res } = await axios.get(`/seizure-reports/${id}`);
  return res;
};

export const getDetailPostSeizure = async (id: number) => {
  const { data } = await axios.get(`/file-post-seizures/${id}`);
  return data;
};

export const updateDetailPostSeizure = async (
  id: number,
  payload: Partial<CreateSeizurePayload>
) => {
  const { data } = await axios.put(`/file-post-seizures/${id}`, payload);
  return data;
};

export const createPreSeizure = async (payload: Partial<CreateSeizurePayload>) => {
  const { data: res } = await axios.post('/file-pre-seizures', payload);
  return res;
};

export const getDetailPreSeizure = async (id: number) => {
  const { data } = await axios.get(`/file-pre-seizures/${id}`);
  return data;
};

export const updateDetailPreSeizure = async (
  id: number,
  payload: Partial<CreateSeizurePayload>
) => {
  const { data } = await axios.put(`/file-pre-seizures/${id}`, payload);
  return data;
};
// bailiff

export const getFilesById = async (id: number) => {
  const { data: res } = await axios.get(`/files/${id}`);
  return res;
};
export const updateFilesBailiff = async (id: number, payload: string) => {
  const { data: res } = await axios.put(`/files/status-transition/${id}`, { status: payload });
  return res;
};

export const getOptionFile = async () => {
  const [{ data: resOptionClient }, { data: resOptionAuctionDeliveryLocation }] = await Promise.all(
    [axios.get(`/client-options`), axios.get(`/auction-delivery-locations`)]
  );
  return { resOptionClient, resOptionAuctionDeliveryLocation };
};

export type payloadDispatchFile = {
  fileId: number;
  userIds: number[];
};
export const dispatchFiles = async (payload: payloadDispatchFile) => {
  const { data: res } = await axios.put('/dispatch-files', payload);
  return res.dispatchFiles;
};
