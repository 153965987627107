import { Stack } from '@mui/material';
import { ForgotPasswordCode } from 'app/pages/ForgotPasswordPage/ForgotPasswordCodePage/ForgotPasswordCode';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { Helmet } from 'react-helmet-async';
import React from 'react';
export const ForgotPasswordCodePage = () => {
  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Surrey Bailiffs - Forgot Password</title>
      </Helmet>
      <LayoutContent
        title={'Forgot Password'}
        textFooter={''}
        textNavigation={'/login'}
        textLink={'Back to Login'}
        textUnderlineNone
        children={<ForgotPasswordCode />}
      />
    </Stack>
  );
};
