import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { Helmet } from 'react-helmet-async';
import { PreSeizureForm } from '../components/modules/FormPreSeizure/FormPreSeizure';
import React from 'react';
import { useInjection } from './hook';

export const EditPreSeizure = () => {
  const { onSubmitUpdatePreSeizure, handleOnBack, dataDetail, handleMoveToArchive } =
    useInjection();
  return (
    <LayoutAdminTable title={'Pre Seizure'} textButton={'Back'} onClickButton={handleOnBack}>
      <Helmet>
        <title>Surrey Bailiffs - Pre Seizure</title>
      </Helmet>
      <PreSeizureForm
        isEdit
        initDataForm={dataDetail}
        onSubmitForm={onSubmitUpdatePreSeizure}
        handleMoveToArchive={handleMoveToArchive}
      />
    </LayoutAdminTable>
  );
};
