import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { createFiles, dispatchFiles, getOptionFile } from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { ClientOption, CreateFiles } from '../FilesPage/components/FileForm/hook';
import { useFlash } from 'contexts/flash';
import { useNavigate } from 'react-router-dom';
import { compact } from 'lodash';

export type TypeOption = {
  value: number | string;
  label: string;
};
export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();

  const [optionClient, setOptionClient] = useState<ClientOption[]>([]);
  const [optionClientChild, setOptionClientChild] = useState<ClientOption[]>([]);
  const [optionAuctionDeliveryLocation, setOptionAuctionDeliveryLocation] = useState<TypeOption[]>(
    []
  );

  const handleAddFile = useCallback(
    async ({
      value,
      fileDocument,
      filePhotos
    }: {
      value: CreateFiles;
      filePhotos?: { file: File; url: string }[];
      fileDocument?: { file: File; url: string }[];
    }) => {
      const formData = new FormData();
      let filePhotosIds: number[] = [];
      let fileDocumentsIds: number[] = [];
      if (filePhotos?.length) {
        for (let x = 0; x < filePhotos.length; x++) {
          formData.append('files', filePhotos[x].file, filePhotos[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/file-photo', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        filePhotosIds = resListIdFile.files;
        formData.delete('files');
      }
      if (fileDocument?.length) {
        for (let x = 0; x < fileDocument.length; x++) {
          formData.append('files', fileDocument[x].file, fileDocument[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/file-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        fileDocumentsIds = resListIdFile.files;
      }
      const payload = {
        ...value,
        clientOptionsId: value.clientOptionsChildId || value.clientOptionsId,
        filePhotosIds,
        fileDocumentsIds
      };
      const data = await createFiles(payload);
      if (payload.userAssign?.length) {
        const userIds = compact(payload.userAssign?.map((item) => Number(item.value)));
        await dispatchFiles({ fileId: data.file.id, userIds });
      }
      navigate(`/admin/files/${data.file.id}`);
      setFlash({ type: 'success', message: 'Create files successful' });
    },
    [navigate, setFlash]
  );

  const { asyncCallback: asyncCreateFile } = useAsyncCallback(handleAddFile, []);

  const getInitOption = useCallback(async () => {
    const { resOptionClient, resOptionAuctionDeliveryLocation } = await getOptionFile();
    setOptionClient(resOptionClient.clientOptions);
    setOptionAuctionDeliveryLocation(
      resOptionAuctionDeliveryLocation.auctionHouses?.map(
        (item: { id: number; value: string }) => ({
          value: item.id,
          label: item.value
        })
      )
    );
  }, []);

  useEffect(() => {
    getInitOption();
  }, [getInitOption]);

  const changeOptionClient = useCallback(
    (value: number) => {
      const listChild = optionClient.find((item) => item.id === value)?.children;
      if (listChild?.length) {
        setOptionClientChild(listChild);
        return;
      }
      setOptionClientChild([]);
    },
    [optionClient]
  );

  return {
    asyncCreateFile,
    optionClient,
    optionClientChild,
    optionAuctionDeliveryLocation,
    changeOptionClient
  };
};
