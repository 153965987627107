import React, { useState } from 'react';
import { Stack, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputText, InputTextProps } from 'app/components/elements/InputText';

type InputPasswordProps = InputTextProps;

export const InputPassword: React.FC<InputPasswordProps> = ({ inputProps, ...inputTextProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Stack gap={1}>
      <InputText
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleToggleShowPassword}>
              {showPassword ? (
                <Visibility sx={{ color: '#22374A' }} />
              ) : (
                <VisibilityOff sx={{ color: '#22374A' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        {...inputTextProps}
        inputProps={{
          ...inputProps,
          type: showPassword ? 'text' : 'password'
        }}
      />
    </Stack>
  );
};
