import { Box, Button, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useSeizure } from 'app/pages/SeizureReportsPage/hooks';
import { FormSeizure } from 'app/pages/SeizureReportsPage/components/FormSeizure';
import { IconBack } from 'assets/icon';
import theme from 'styles/theme';
import { Helmet } from 'react-helmet-async';
import { HeaderFile } from '../FileDetail/components/HeaderFileDetail';

export const SeizureReportsPage = () => {
  const {
    file,
    vsaDocuments,
    fileInputPhotoRef,
    fileInputDocumentRef,
    photos,
    handleFileChange,
    handleSubmit,
    handleDeleteDocument,
    optionsAddress,
    handleBack,
    optionAuctionDeliveryLocation
  } = useSeizure();

  return (
    <Box mx={2} mt={4}>
      <Helmet>
        <title>Surrey Bailiffs - Seizure</title>
      </Helmet>
      <WrapperClientAndButton sx={{ marginBottom: '20px' }}>
        <TitlePage>SEIZURE REPORT</TitlePage>
        <WrapperIconBack onClick={handleBack}>
          <IconBack color={theme.colors.dark1} />
          <TextBack>Back</TextBack>
        </WrapperIconBack>
      </WrapperClientAndButton>
      <HeaderFile file={file} />
      <FormSeizure
        optionAuctionDeliveryLocation={optionAuctionDeliveryLocation}
        optionsAddress={optionsAddress}
        photos={photos}
        documents={vsaDocuments}
        fileInputPhotoRef={fileInputPhotoRef}
        fileInputDocumentRef={fileInputDocumentRef}
        handleFileChange={handleFileChange}
        onSubmit={handleSubmit}
        handleDeleteDocument={handleDeleteDocument}
      />
    </Box>
  );
};

const WrapperClientAndButton = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const TitlePage = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '25px'
}));

const WrapperIconBack = styled(Button)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  flexDirection: 'row',
  padding: 0
}));

const TextBack = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px'
}));
