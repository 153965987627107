import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LeadMap } from './type';
import { Files, Lead } from 'services/leads';
import { useLeads } from 'hooks/useLeads';
import { CATEGORY } from 'const/common';
import { createGoogleMapsDirectionsLink } from 'utils';
import { renderTitleLead } from 'utils/renderTitleLead';

export const useInjection = () => {
  const {
    searchInput,
    rows,
    handleInputSearch,
    onFilter,
    formValue,
    fetchMoreData,
    hasMore,
    handleClickRow,
    onSortHeader
  } = useLeads({
    category: CATEGORY.ADDRESS
  });
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!
  });

  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | undefined>();
  const [currentLocation, setCurrentLocation] = useState<undefined | { lat: number; lng: number }>(
    undefined
  );
  const [open, setOpen] = useState<boolean>(false);
  const [leadsMap, setLeadsMap] = useState<LeadMap[]>([]);
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [isOpenModalDetailLead, setIsOpenModalDetailLead] = useState<undefined | Lead>(undefined);

  useEffect(() => {
    if (rows.length > 0) {
      const leadsMap: LeadMap[] = [];
      rows.forEach((item) => {
        leadsMap.push({
          latitude: item.latitude,
          longitude: item.longitude,
          status: [
            item?.file?.status?.charAt(0)?.toUpperCase() ?? '',
            item?.file?.status?.slice(1)?.toLocaleLowerCase() ?? ''
          ].join('')
        });
      });

      if (leadsMap.length > 0) {
        setLeadsMap(leadsMap);
        setCenter({ lat: leadsMap[0].latitude ?? 0, lng: leadsMap[0].longitude ?? 0 });
      }
    }
  }, [rows]);

  useEffect(() => {
    const getCurrentLocation = () => {
      navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };

        setCurrentLocation(pos);
      }, getCurrentLocation);
    };
    getCurrentLocation();
  }, []);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      if (center) map.setCenter(center);
      setMap(map);
    },
    [center]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onGetDirection = useCallback(
    (newActiveIndex?: number[]) => {
      const _activeIndex = Array.isArray(newActiveIndex) ? newActiveIndex : activeIndex;
      if (_activeIndex.length) {
        const directionsService = new google.maps.DirectionsService();
        if (!currentLocation) return;
        const origin = currentLocation;

        const waypoints = _activeIndex.map((item) => ({
          location: { lat: leadsMap[item].latitude, lng: leadsMap[item].longitude },
          stopover: true
        }));
        const coordinates = [currentLocation, ...waypoints.map((item) => item.location)];
        const mapsLink = createGoogleMapsDirectionsLink(coordinates);
        window.open(mapsLink, 'blank');
        directionsService.route(
          {
            origin: origin,
            destination: waypoints[waypoints.length - 1]?.location,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
              setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      } else {
        setDirections(undefined);
      }
    },
    [currentLocation, activeIndex]
  );

  return {
    map,
    isOpenModalDetailLead,
    activeIndex,
    isLoaded,
    directions,
    rows: useMemo(
      () =>
        rows.map((item) => ({
          ...item.file,
          leadId: item.id,
          leadTitle: renderTitleLead(item, false),
          address: item.address
        })),
      [rows]
    ) as Files[],
    open,
    currentLocation,
    searchInput,
    leadsMap,
    center,
    onGetDirection,
    onLoad,
    onUnmount,
    onFilter,
    onClose: useCallback(() => {
      setOpen(false);
    }, []),
    onOpenModalFilter: useCallback(() => {
      setOpen(true);
    }, []),
    handleInputSearch,
    formValue,
    onUpdateActiveIndex: useCallback(
      (index: number) => {
        const hasId = activeIndex.find((item) => item === index);
        if (hasId) {
          setActiveIndex((prevState) => prevState.filter((item) => item !== index));
          return;
        }
        setActiveIndex((prevState) => [...prevState, index]);
      },
      [activeIndex]
    ),
    onHold: useCallback(
      (index: number) => {
        setIsOpenModalDetailLead(rows[index]);
      },
      [rows]
    ),
    onCloseModalDetailLead: useCallback(() => {
      setIsOpenModalDetailLead(undefined);
    }, []),
    fetchMoreData,
    hasMore,
    handleClickRow,
    onSortHeader
  };
};
