import { useCallback, useEffect, useState } from 'react';
import {
  getBailiffPerformance,
  getClientPerformance,
  getCurrentWorkload,
  getOverallPerformance
} from 'services/performance';
import { TableDataType } from 'app/components/elements/TableInfiniteScroll';
import dayjs, { Dayjs } from 'dayjs';
import { saveVideoFile } from 'utils/downloadFile';
import * as qs from 'qs';
const API_URL = process.env.REACT_APP_API_URL;

interface ICurrentWorkload {
  openFile: number;
  attention: number;
  aged: number;
}

interface IOverallPerformance {
  averageDaysOpen: number;
  closeClient: number;
  closeNotFound: number;
  openFile: number;
  redemption: number;
  seized: number;
}

interface IBailiffPerformance {
  currentWorkload: ICurrentWorkload;
  overallPerformance: IOverallPerformance;
  firstName?: string;
  lastName?: string;
}

interface IClientPerformance {
  currentWorkload: ICurrentWorkload;
  overallPerformance: IOverallPerformance;
  value?: string;
}

export const usePerformance = () => {
  const [current, setCurrent] = useState<ICurrentWorkload>({
    openFile: 0,
    attention: 0,
    aged: 0
  });
  const [overall, setOverall] = useState<IOverallPerformance>({
    averageDaysOpen: 0,
    closeClient: 0,
    closeNotFound: 0,
    openFile: 0,
    redemption: 0,
    seized: 0
  });
  const [bailiff, setBailiff] = useState<IBailiffPerformance[]>([]);
  const [client, setClient] = useState<IClientPerformance[]>([]);

  const [overallFromDate, setOverallFromDate] = useState<Dayjs | null>(null);
  const [overallToDate, setOverallToDate] = useState<Dayjs | null>(null);

  const [bailiffFromDate, setBailiffFromDate] = useState<Dayjs | null>(null);
  const [bailiffToDate, setBailiffToDate] = useState<Dayjs | null>(null);

  const [clientFromDate, setClientFromDate] = useState<Dayjs | null>(null);
  const [clientToDate, setClientToDate] = useState<Dayjs | null>(null);

  const [isLoadingOverall, setIsLoadingOverall] = useState<boolean>(false);
  const [isLoadingBailiff, setIsLoadingBailiff] = useState<boolean>(false);
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);

  const fetchMetaData = useCallback(async () => {
    const resCurrent = await getCurrentWorkload();
    setCurrent(resCurrent.currentWorkload);
  }, []);

  const fetchOverallPerformance = useCallback(async () => {
    setIsLoadingOverall(true);
    try {
      const resOverall = await getOverallPerformance({
        startDate: overallFromDate?.toISOString(),
        endDate: overallToDate?.toISOString()
      });
      setOverall(resOverall.overallPerformance);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingOverall(false);
    }
  }, [overallFromDate, overallToDate]);

  const fetchBailiffPerformance = useCallback(async () => {
    setIsLoadingBailiff(true);
    try {
      const resBailiff = await getBailiffPerformance({
        startDate: bailiffFromDate?.toISOString(),
        endDate: bailiffToDate?.toISOString()
      });
      setBailiff(resBailiff.bailiffPerformance);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingBailiff(false);
    }
  }, [bailiffFromDate, bailiffToDate]);

  const fetchClientPerformance = useCallback(async () => {
    setIsLoadingClient(true);
    try {
      const resClient = await getClientPerformance({
        startDate: clientFromDate?.toISOString(),
        endDate: clientToDate?.toISOString()
      });
      setClient(resClient.clientPerformance);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingClient(false);
    }
  }, [clientFromDate, clientToDate]);

  useEffect(() => {
    fetchMetaData();
  }, [fetchMetaData]);

  useEffect(() => {
    fetchOverallPerformance();
  }, [overallFromDate, overallToDate]);

  useEffect(() => {
    fetchBailiffPerformance();
  }, [bailiffFromDate, bailiffToDate]);

  useEffect(() => {
    fetchClientPerformance();
  }, [clientFromDate, clientToDate]);

  const handleOverallFromDateChange = (date: Dayjs | null) => setOverallFromDate(date);
  const handleOverallToDateChange = (date: Dayjs | null) => setOverallToDate(date);

  const handleBailiffFromDateChange = (date: Dayjs | null) => setBailiffFromDate(date);
  const handleBailiffToDateChange = (date: Dayjs | null) => setBailiffToDate(date);

  const handleClientFromDateChange = (date: Dayjs | null) => setClientFromDate(date);
  const handleClientToDateChange = (date: Dayjs | null) => setClientToDate(date);

  const optionHeader = [
    {
      label: 'Total Files Open',
      value: current.openFile
    },
    {
      label: 'Needs Attention',
      value: current.attention
    },
    {
      label: 'Aged',
      value: current.aged
    }
  ];

  const rowDataOverall: TableDataType[] = [
    {
      averageDaysOpen: { value: overall.averageDaysOpen },
      totalOpened: { value: overall.openFile },
      totalSeized: { value: overall.seized },
      totalRedeemed: { value: overall.redemption },
      totalClosedClientRequest: { value: overall.closeClient },
      totalClosedNotFound: { value: overall.closeNotFound }
    }
  ];

  const rowDataBailiff: TableDataType[] = (bailiff ?? []).map((item) => ({
    bailiff: { value: `${item?.firstName} ${item?.lastName}`.trim() },
    totalFilesOpen: { value: item?.currentWorkload?.openFile ?? 0 },
    needAttention: { value: item?.currentWorkload?.attention ?? 0 },
    aged: { value: item?.currentWorkload?.aged ?? 0 },
    averageDaysOpen: { value: item?.overallPerformance?.averageDaysOpen ?? 0 },
    totalOpened: { value: item?.overallPerformance?.openFile ?? 0 },
    totalSeized: { value: item?.overallPerformance?.seized ?? 0 },
    totalRedeemed: { value: item?.overallPerformance?.redemption ?? 0 },
    totalClosedClientRequest: { value: item?.overallPerformance?.closeClient ?? 0 },
    totalClosedNotFound: { value: item?.overallPerformance?.closeNotFound ?? 0 }
  }));

  const rowDataClient: TableDataType[] = (client ?? []).map((item) => ({
    bailiff: { value: item?.value },
    totalFilesOpen: { value: item?.currentWorkload?.openFile ?? 0 },
    needAttention: { value: item?.currentWorkload?.attention ?? 0 },
    aged: { value: item?.currentWorkload?.aged ?? 0 },
    averageDaysOpen: { value: item?.overallPerformance?.averageDaysOpen ?? 0 },
    totalOpened: { value: item?.overallPerformance?.openFile ?? 0 },
    totalSeized: { value: item?.overallPerformance?.seized ?? 0 },
    totalRedeemed: { value: item?.overallPerformance?.redemption ?? 0 },
    totalClosedClientRequest: { value: item?.overallPerformance?.closeClient ?? 0 },
    totalClosedNotFound: { value: item?.overallPerformance?.closeNotFound ?? 0 }
  }));

  const handleClickExport = useCallback(() => {
    const params = {
      overallPerformance: {
        startDate: overallFromDate ? dayjs(overallFromDate).toISOString() : undefined,
        endDate: overallFromDate ? dayjs(overallToDate).toISOString() : undefined
      },
      bailiffPerformance: {
        startDate: bailiffFromDate ? dayjs(bailiffFromDate).toISOString() : undefined,
        endDate: bailiffToDate ? dayjs(bailiffToDate).toISOString() : undefined
      },
      clientPerformance: {
        startDate: clientFromDate ? dayjs(clientFromDate).toISOString() : undefined,
        endDate: clientToDate ? dayjs(clientToDate).toISOString() : undefined
      }
    };
    const query = qs.stringify(params);
    return saveVideoFile(`${API_URL}/dashboard/exports?${query}`, 'performance-overview.xlsx');
  }, [
    bailiffFromDate,
    bailiffToDate,
    clientFromDate,
    clientToDate,
    overallFromDate,
    overallToDate
  ]);

  return {
    optionHeader,
    rowDataOverall,
    rowDataBailiff,
    rowDataClient,
    overallFromDate,
    overallToDate,
    bailiffFromDate,
    bailiffToDate,
    clientFromDate,
    clientToDate,
    isLoadingOverall,
    isLoadingBailiff,
    isLoadingClient,
    handleBailiffFromDateChange,
    handleBailiffToDateChange,
    handleClientFromDateChange,
    handleClientToDateChange,
    handleOverallToDateChange,
    handleOverallFromDateChange,
    handleClickExport
  };
};
