import { ReactElement, ReactNode } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { StyledParams } from 'types/common';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';

type LayoutAdminTableProps = {
  children: ReactNode;
  textButton?: string;
  onClickButton?: () => void;
  element?: ReactElement;
  title?: string;
};

export const LayoutAdminTable = ({
  children,
  element,
  textButton,
  onClickButton,
  title
}: LayoutAdminTableProps) => {
  return (
    <WrapperLayout>
      <Wrapper>
        <HeaderStyled>
          {title && <TitleStyled>{title}</TitleStyled>}
          <Stack style={{ minHeight: '56px' }}>
            <Stack style={{ height: '56px', minWidth: '100px' }}>
              {textButton && (
                <ButtonCustom
                  style={{ padding: '12px 24px' }}
                  onClick={onClickButton}
                  label={textButton}
                />
              )}
            </Stack>
            {element && element}
          </Stack>
        </HeaderStyled>
        <Stack>{children}</Stack>
      </Wrapper>
    </WrapperLayout>
  );
};
const WrapperLayout = styled(Stack)(() => ({
  background: '#FFF',
  width: '100%',
  padding: '40px 0px 40px 0px',
  alignItems: 'center'
}));
const Wrapper = styled(Stack)(() => ({
  background: '#FFF',
  width: 'calc(100vw - 330px)',
  overflowX: 'auto'
}));
const HeaderStyled = styled(Stack)(() => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '14px'
}));
const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 20,
  fontWeight: 700
}));
