import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { getDetailSeizure } from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { FormDataSeizure } from '../../../../SeizureReportsPage/hooks';
import { TAB_CONTROL_PANEL } from 'const/admin';

export type SeizureFile = {
  url: string;
  name: string;
  id: string;
  storageId: string;
};

type Location = {
  value: string;
};

interface TSeizureDetails extends FormDataSeizure {
  fileId?: string;
  signedVsa: boolean;
  vsaDocuments: SeizureFile[];
  seizureReportPhotos: SeizureFile[];
  removedToLocation?: Location;
}

export const useSeizureDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState<TSeizureDetails>();

  const fetchDetailSeizure = useCallback(async () => {
    const { seizureReport } = await getDetailSeizure(Number(id));
    setDataDetail(seizureReport);
  }, [id]);

  const { asyncCallback: asyncFetchDetailSeizure } = useAsyncCallback(fetchDetailSeizure, []);

  useEffect(() => {
    asyncFetchDetailSeizure();
  }, [id]);

  const handleOnBack = () => navigate(`/admin/control-panel?tab=${TAB_CONTROL_PANEL.ARCHIVE}`);

  const handleOnClick = () =>
    navigate(`/admin/redemptions/post-seizure/create?fileId=${dataDetail?.fileId}`);

  return {
    dataDetail,
    handleOnClick,
    handleOnBack
  };
};
