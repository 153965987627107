import * as yup from 'yup';
import { TypeLead } from 'types/user';

// const latLongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const numberRegex = /^[0-9.]/g;

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid')
});
export const forgotPasswordCodeSchema = yup.object().shape({
  code: yup.string().required('Code is required')
});

export const adminEditBailiffSchema = yup.object().shape({
  firstName: yup.string().required('First name  is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required'),
  isActive: yup.string().required('Address is required')
});
export const adminEditAdminSchema = yup.object().shape({
  firstName: yup.string().required('First name  is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required')
});
export const adminCreateBailiffSchema = yup.object().shape({
  firstName: yup.string().required('First name  is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required'),
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid')
});

export const adminCreateAdminSchema = yup.object().shape({
  firstName: yup.string().required('First name  is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required'),
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid')
});

export const createFilesSchemaYup = yup.object().shape({
  fileNumber: yup.string().required('File number is required'),
  firstName: yup.string().required('First name  is required'),
  coBorrowerLastName: yup.string().optional(),
  coBorrowerFirstName: yup.string().optional(),
  lastName: yup.string().required('Last name  is required'),
  vin: yup.string().required('VIN  is required'),
  model: yup.string().required('Model  is required'),
  make: yup.string().required('Make  is required'),
  year: yup.string().required('Year  is required'),
  colour: yup.string().optional(),
  quickNote: yup.string().optional(),
  arrears: yup.number().required('Arrears  is required'),
  arrearsOther: yup.string().when(['arrears'], {
    is: (arrears: number) => arrears === 0,
    then: (schema) => schema.required('Options  is required')
  }),
  plate: yup.string().optional(),
  contractBalance: yup.number().required('Contract Balance  is required'),
  ppsaBaseRegistrationNumber: yup.string().required('PPSA Base Registration Number  is required'),
  ppsaRegistrationDate: yup.date().required('PPSA Registration Date  is required'),
  auctionDeliveryLocationId: yup.number().required('Auction Delivery Location is required'),
  clientOptionsId: yup.number().required('Client Options is required'),
  contactDate: yup.date().required('ContactDate  is required'),
  userAssign: yup.array().optional()
});
export const loginSchema = yup.object().shape({
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
});

export const updateUserSchema = yup.object().shape({
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid'),
  firstName: yup.string().required('First name  is required'),
  lastName: yup.string().required('Last name  is required'),
  address: yup.string().required('Address is required')
});

export const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required')
});

export const NewLeadSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(Object.values(TypeLead), 'Invalid Lead Type')
    .required('Lead Type is required'),
  address: yup.string().when(['type'], {
    is: (type: string) =>
      type === TypeLead.POR || type === TypeLead.POE || type === TypeLead.OTHER_ADDRESS,
    then: (schema) => schema.required('Address is required')
  }),
  city: yup.string().optional(),
  province: yup.string().optional(),
  phoneNumber: yup.string().when(['type'], {
    is: (type: string) =>
      type === TypeLead.PHONE_NUMBER ||
      type === TypeLead.REFERENCE ||
      type === TypeLead.POE ||
      type === TypeLead.OTHER_PHONE_NUMBER,
    then: (schema) =>
      schema
        .required('Phone Number is required')
        .matches(/^\+?[0-9]{8,15}$/, 'Invalid phone number')
  }),
  otherPhoneNumber: yup
    .string()
    .optional()
    .when(['type'], {
      is: (type: string) => type === TypeLead.PHONE_NUMBER,
      then: (schema) =>
        schema
          .required('Other Phone Number is required')
          .matches(/^\+?[0-9]{8,15}$/, 'Invalid other phone number')
    }),
  information: yup.string().when('type', {
    is: (value: string) =>
      value === TypeLead.OTHER_OPEN_NOTE ||
      value === TypeLead.OTHER_PHONE_NUMBER ||
      value === TypeLead.OTHER_ADDRESS,
    then: (schema) => schema.required('Information is required')
  }),
  note: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.OTHER_OPEN_NOTE,
    then: (schema) => schema.required('Note Type is required')
  }),
  firstName: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.MVS,
    then: (schema) => schema.required('First Name is required')
  }),
  lastName: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.MVS,
    then: (schema) => schema.required('Last Name is required')
  }),
  plate: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.MVS,
    then: (schema) => schema.required('Plate is required')
  }),
  colour: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.MVS,
    then: (schema) => schema.required('Colour is required')
  }),
  namePOE: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.POE,
    then: (schema) => schema.required('Name POE is required')
  }),
  nameReference: yup.string().when(['type'], {
    is: (type: string) => type === TypeLead.REFERENCE,
    then: (schema) => schema.required('Name Reference is required')
  }),
  towYards: yup
    .array()
    .optional()
    .of(
      yup.object().shape({
        value: yup.string().required('Field required')
      })
    )
});

export const TowYardFormSchema = yup.object().shape({
  towYards: yup.array().of(
    yup.object().shape({
      id: yup.number().optional(),
      value: yup.string().required('Field required'),
      checked: yup.boolean().required('Field required')
    })
  )
});
export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  newPassword: yup
    .string()
    .required('Password is required')
    .notOneOf(
      [yup.ref('currentPassword')],
      'The new password cannot be the same as the current password. Please choose a different password'
    )
    .min(8, 'Password must be at least 8 characters'),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match')
});
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required')
});

export const pinFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required'),
  address: yup.string(),
  description: yup.string(),
  longitude: yup.number().typeError('Longitude'),
  latitude: yup.number().typeError('Longitude'),
  point: yup.number().typeError('Point'),
  area: yup.string().required('Area is required'),
  qrLongitude: yup
    .number()
    .typeError('QR longitude')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : undefined)),
  qrLatitude: yup
    .number()
    .typeError('QR latitude')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : undefined)),
  qrUrl: yup
    .string()
    .required('QR URL is required')
    .url('The URL entered is invalid. Please check and try again')
});
export const MarkerFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required')
});
export const CommunityFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required'),
  area: yup.string().required('Area is required'),
  radius: yup.number().required().typeError('Radius is required'),
  description: yup.string().typeError('Description is required'),
  longitude: yup.number().required().typeError('Longitude'),
  latitude: yup.number().required().typeError('Latitude')
});
export const TermFormSchema = yup.object().shape({
  termsTitle: yup.string().required('Term Title is required'),
  termsContent: yup.string().required('Term Content is required')
});
export const MilestoneSchema = yup.object().shape({
  point: yup.number().required('Point  is required'),
  message: yup.string().required('Message  is required')
});

export const SeizureSchema = yup.object().shape({
  kilometers: yup.number().required('KM is not valid'),
  hasKey: yup.boolean().required("Select 'Yes' or 'No' for Keys"),
  run: yup.boolean().required("Select 'Yes' or 'No' for Runs"),
  signedVSA: yup.boolean().required("Select 'Yes' or 'No' for Signed VSA"),
  possibleRedemption: yup.boolean().required("Select 'Yes' or 'No' for Possible Redemption"),
  conditionReport: yup.string().required('Condition Report is required'),
  towingCompany: yup.string().required('Towing Company is required'),
  transportRequired: yup.boolean().required("Select 'Yes' or 'No' for Transport Required"),
  billsIncurred: yup.boolean().required("Select 'Yes' or 'No' for Other Bills Incurred"),
  addressLeadId: yup.string().required('Please select a address'),
  customersLeadId: yup.string().required('Please select a customer'),
  otherAddress: yup.string(),
  otherCustomerPOR: yup.string(),
  removedToId: yup.number().required('Remove is required'),
  removedToOther: yup.string().when(['removedToId'], {
    is: (remove: number) => remove === 0,
    then: (schema) => schema.required('Name Reference is required')
  }),
  otherBills: yup.string().when(['billsIncurred'], {
    is: (value: string) => value,
    then: (schema) => schema.required('Notes is required')
  }),
  otherTransport: yup.string().when(['transportRequired'], {
    is: (value: string) => value,
    then: (schema) => schema.required('Notes is required')
  })
});

export const validationAuctionSchema = yup.object().shape({
  options: yup.array().of(
    yup.object().shape({
      idOption: yup.string().optional(),
      isDelete: yup.boolean().optional(),
      value: yup.string().when(['isDelete'], {
        is: (isDelete: boolean) => !isDelete,
        then: (schema) => schema.required('Name Reference is required')
      })
    })
  )
});

export const validationClientSchema = yup.object().shape({
  options: yup.array().of(
    yup.object().shape({
      value: yup.string().when(['isDelete'], {
        is: (isDelete: boolean) => !isDelete,
        then: (schema) => schema.required('Name Reference is required')
      }),
      id: yup.string().optional(),
      isDelete: yup.boolean().optional(),
      children: yup
        .array()
        .optional()
        .of(
          yup.object().shape({
            value: yup.string().when(['isDelete'], {
              is: (isDelete: boolean) => !isDelete,
              then: (schema) => schema.required('Name Reference is required')
            }),
            id: yup.string().optional(),
            isDelete: yup.boolean().optional()
          })
        )
        .required('Children are required')
    })
  )
});

export const validationPreSeizureSchema = yup.object().shape({
  fileId: yup.number().required('File id is required'),
  arrears: yup.number().optional(),
  arrearsOther: yup.string().when(['arrears'], {
    is: (arrears: number) => arrears === 0,
    then: (schema) => schema.required('Options  is required')
  }),
  bailiffFees: yup.number().optional(),
  redemptionFees: yup.number().optional(),
  lenderFees: yup.number().optional(),
  status: yup.string().required('Awaiting POI and PAD is required'),
  statementSent: yup.date().optional(),
  followUpSent: yup.date().optional(),
  escFees: yup.number().optional(),
  transportFees: yup.number().optional(),
  mvsSearch: yup.number().optional(),
  nextPayment: yup.number().optional(),
  fctFees: yup.number().optional(),
  auctionHouseFees: yup.number().optional(),
  nextPaymentDate: yup.date().optional(),
  storageFees: yup.number().optional(),
  towBill: yup.number().optional()
});

export const validationPostSeizureSchema = yup.object().shape({
  id: yup.number(),
  arrears: yup.number().optional(),
  arrearsOther: yup.string().when(['arrears'], {
    is: (arrears: number) => arrears === 0,
    then: (schema) => schema.required('Options  is required')
  }),
  fileId: yup.number().required('File Id is required'),
  status: yup.string().required('Awaiting POI and PAD is required'),
  bailiffFees: yup.number().optional(),
  redemptionFees: yup.number().optional(),
  lenderFees: yup.number().optional(),
  escFees: yup.number().optional(),
  transportFees: yup.number().optional(),
  mvsSearch: yup.number().optional(),
  nextPayment: yup.number().optional(),
  fctFees: yup.number().optional(),
  auctionHouseFees: yup.number().optional(),
  towBill: yup.number().optional(),
  storageFees: yup.number().optional(),
  interimStatementSent: yup.date().optional(),
  followUpSent: yup.date().optional(),
  nextPaymentDate: yup.date().optional(),
  appointmentBooked: yup.date().optional(),
  finalStatementSent: yup.date().optional()
});
