/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import styled from 'styled-components';

let timerId: null | NodeJS.Timeout = null;

const ButtonHold = ({
  children,
  onHold,
  onPointerUp,
  ...rest
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    onHold: () => void;
    onPointerUp: (e: React.PointerEvent<any>) => void;
  }
>) => {
  const canClick = useRef(false);
  const onPointerDown = (e: React.PointerEvent<any>) => {
    rest.onPointerDown?.(e);
    canClick.current = true;
    timerId = setTimeout(() => {
      canClick.current = false;
      onHold();
    }, 1000);
  };

  const _onPointerUp = (e: React.PointerEvent<any>) => {
    if (canClick.current) {
      onPointerUp?.(e);
    }
    if (timerId) {
      clearTimeout(timerId);
    }
  };
  return (
    <Button {...rest} onPointerDown={onPointerDown} onPointerUp={_onPointerUp}>
      {children}
    </Button>
  );
};

export default React.memo(ButtonHold);

const Button = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;
