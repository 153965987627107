import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationClientSchema } from 'utils/validate';
import { useCallback, useEffect, useState } from 'react';
import { ClientOptionTabProps } from './index';

export type TClient = {
  value?: string;
  id?: string;
  isDelete?: boolean;
  children: { value?: string; id?: string; isDelete?: boolean }[];
};
export type ClientOption = {
  options?: TClient[];
};

export const useClientOption = ({ optionClientInit }: Partial<ClientOptionTabProps>) => {
  const [showChildren, setShowChildren] = useState<{ [key: number]: boolean }>({});

  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const toggleChildrenVisibility = (index: number) => {
    setShowChildren((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset
  } = useForm<ClientOption>({
    resolver: yupResolver(validationClientSchema),
    defaultValues: {
      options: optionClientInit
    }
  });

  const {
    fields: fieldClientOption,
    append,
    update,
    remove
  } = useFieldArray({
    control,
    name: 'options'
  });

  useEffect(() => {
    if (optionClientInit?.length) {
      const initialState: { [key: number]: boolean } = {};
      for (let i = 0; i < optionClientInit.length; i++) {
        initialState[i] = false;
      }
      setShowChildren(initialState);
    }

    reset({ options: optionClientInit });
  }, [optionClientInit]);

  const handleResetForm = useCallback(() => {
    reset({
      options: optionClientInit
    });
  }, [optionClientInit, reset]);

  const handleShowAll = useCallback(() => {
    const updatedState = Object.keys(showChildren).reduce(
      (acc, key) => {
        acc[Number(key)] = true;
        return acc;
      },
      {} as { [key: number]: boolean }
    );
    setIsShowAll(true);
    setShowChildren(updatedState);
  }, [showChildren]);

  const handleHiddenAll = useCallback(() => {
    const updatedState = Object.keys(showChildren).reduce(
      (acc, key) => {
        acc[Number(key)] = false;
        return acc;
      },
      {} as { [key: number]: boolean }
    );
    setIsShowAll(false);
    setShowChildren(updatedState);
  }, [showChildren]);
  const handleAddNew = useCallback(() => {
    const index = watch('options')?.length || 0;
    setShowChildren((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
    append({ value: '', children: [] });
  }, [append, watch]);

  return {
    register,
    control,
    fieldClientOption,
    append,
    handleSubmit,
    errors,
    showChildren,
    toggleChildrenVisibility,
    setValue,
    update,
    remove,
    handleResetForm,
    handleShowAll,
    handleHiddenAll,
    isShowAll,
    handleAddNew
  };
};
