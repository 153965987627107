import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { TextError } from './TextError';
import Typography from '@mui/material/Typography';
import theme from '../../../styles/theme';

type RadioOption = {
  label: string;
  value: string | boolean;
};

interface RadioGroupProps {
  label: string;
  options: RadioOption[];
  value: string | boolean;
  inputError?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroupCustom: React.FC<RadioGroupProps> = ({
  label,
  options,
  value,
  onChange,
  inputError
}) => {
  return (
    <FormControl component="fieldset" error={!!inputError}>
      <FormLabel>
        <Typography fontSize={'13px'} fontWeight={500} mb={1} color={theme.colors.dark1}>
          {label}
        </Typography>
      </FormLabel>
      <RadioGroup value={value} onChange={onChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {inputError && (
        <FormHelperText error={!!inputError} sx={{ ml: 0, maxWidth: '100%' }}>
          <TextError errorText={inputError} />
        </FormHelperText>
      )}
    </FormControl>
  );
};
