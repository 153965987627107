import { Typography, Stack, Box, styled, Grid, Link } from '@mui/material';
import React from 'react';
import { StyledParams } from 'types/common';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { Helmet } from 'react-helmet-async';
import { useArchiveDetails } from './hook';
import dayjs from 'dayjs';
import { saveVideoFile } from 'utils/downloadFile';

type DetailItemProps = {
  title: string;
  content?: string | number | boolean | undefined | Date;
};
const DetailItem = ({ title, content }: DetailItemProps) => (
  <WrapperContent>
    <TextTitleStyled>{title}:</TextTitleStyled>
    <TextContentStyled>
      {content instanceof Date ? content.toLocaleString() : content?.toString()}
    </TextContentStyled>
  </WrapperContent>
);

export const ArchiveDetails = () => {
  const { handleOnBack, dataDetail } = useArchiveDetails();

  return (
    <LayoutAdminTable title={'Seizure Details'} textButton={'Back'} onClickButton={handleOnBack}>
      <Helmet>
        <title>Surrey Bailiffs - Seizure Details</title>
      </Helmet>
      <Stack gap={2.5} width={'100%'} flexDirection={'row'}>
        <Wrapper maxWidth={'900px'}>
          <Stack direction={'row'} gap={2.5} sx={{ flexWrap: 'wrap' }}>
            <DetailItem title="File number" content={dataDetail?.fileNumber} />
            <DetailItem title="VIN" content={dataDetail?.vin} />
            <DetailItem title="Make" content={dataDetail?.make} />
            <DetailItem title="First Name" content={dataDetail?.firstName} />
            <DetailItem title="Last Name" content={dataDetail?.lastName} />
            <DetailItem title="Model" content={dataDetail?.model} />
            <DetailItem title="Year" content={dataDetail?.year} />
            <DetailItem title="Arrears" content={dataDetail?.arrears} />
            <DetailItem title="Co-Borrower First Name" content={dataDetail?.coBorrowerFirstName} />
            <DetailItem title="Co-Borrower Last Name" content={dataDetail?.coBorrowerLastName} />
            <DetailItem title="Client" content={dataDetail?.clientOption.value} />
            <DetailItem title="Plate" content={dataDetail?.plate} />
            <DetailItem title="Colour" content={dataDetail?.colour} />
            <DetailItem
              title="Auction Delivery Location"
              content={dataDetail?.auctionDeliveryLocation.value}
            />
            <DetailItem title="Contract Balance" content={dataDetail?.contractBalance} />
            <DetailItem
              title="PPSA Base Registration Number"
              content={dataDetail?.ppsaBaseRegistrationNumber}
            />
            <DetailItem
              title="Contact Date"
              content={dayjs(dataDetail?.contactDate).format('DD/MM/YYYY')}
            />
            <DetailItem
              title="PPSA Registration Date"
              content={dayjs(dataDetail?.ppsaRegistrationDate).format('DD/MM/YYYY')}
            />
          </Stack>

          <Grid container gap={2.5}>
            <Box minWidth={'265px'}>
              <TextTitleStyled sx={{ marginBottom: '16px' }}>Document:</TextTitleStyled>
              <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
                {dataDetail?.fileDocuments?.map((doc, index) => (
                  <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                    <Stack
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Stack flexDirection={'row'} gap={1.5} alignItems={'center'}>
                        <img src={'/icons/icon_document.svg'} alt={'icon'} />
                        <NameDocument onClick={() => saveVideoFile(doc.url, doc.name)}>
                          {doc.name}
                        </NameDocument>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box minWidth={'265px'} maxWidth={'calc(100% - 100px)'}>
              <TextTitleStyled sx={{ marginBottom: '16px' }}>Photos:</TextTitleStyled>
              <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
                {dataDetail?.photos.map((file, index) => (
                  <Grid item xs={4} sm={4} md={4} key={index}>
                    <Box
                      component="img"
                      src={file.url}
                      alt={`Image ${index + 1}`}
                      sx={{
                        width: '160px',
                        height: '160px',
                        objectFit: 'cover',
                        display: 'block',
                        borderRadius: '10px'
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Wrapper>
      </Stack>
    </LayoutAdminTable>
  );
};
const WrapperContent = styled(Box)(() => ({
  width: '265px'
}));

const Wrapper = styled(Stack)(() => ({
  padding: '40px 30px',
  border: '1px solid #ccc',
  borderRadius: '20px',
  gap: 20
}));

const TextTitleStyled = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  color: '#000000'
}));

const TextContentStyled = styled(Typography)(({ theme }: StyledParams) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: '10px',
  marginTop: theme.spacing(0.5),
  height: '50px'
}));

const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '80px',
  cursor: 'pointer'
}));
