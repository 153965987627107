import { IconButton } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { TableCommunicationMemo } from '../TableCommunication';
import React from 'react';
import { useInjection } from './hook';
import { CommunicationUpdate, dateCommunication } from '../../type';
import { Lead } from 'types/user';
export type UpdateTabProps = {
  initData: CommunicationUpdate;
  handleChangeDate: ({
    endDate,
    startDate
  }: {
    startDate?: string | null;
    endDate?: string | null;
  }) => void;
  dateSearch: dateCommunication | null;
  handleCopyAllUpdates?: (id: number) => void;
  handleDeleteNote: ({ idNote, lead }: { idNote: number; lead?: Partial<Lead> }) => void;
};
export const UpdateTab = ({
  initData,
  handleChangeDate,
  dateSearch,
  handleCopyAllUpdates,
  handleDeleteNote
}: UpdateTabProps) => {
  const { dataRow, hasMore, fetchMoreData, dataClientSelected, setDataClientSelected } =
    useInjection({ initData, dateSearch });

  return (
    <TableCommunicationMemo
      handleDeleteNote={handleDeleteNote}
      dataClientSelected={dataClientSelected}
      setDataClientSelected={setDataClientSelected}
      headers={[
        { label: 'Updated By' },
        { label: 'Date' },
        { label: 'Lead ' },
        { label: 'Details' },
        {
          label: (
            <>
              <IconButton sx={{ padding: '0 8px 0 0' }}>
                <ContentCopyOutlinedIcon />
              </IconButton>
              Copy all
            </>
          ),
          onClick: handleCopyAllUpdates
        }
      ]}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
      label={'Updates'}
      rows={dataRow}
      handleChangeDate={(startDate?: string | null, endDate?: string | null) => {
        handleChangeDate({ startDate, endDate });
        setDataClientSelected(undefined);
      }}
      date={dateSearch}
    />
  );
};
