import React from 'react';
import { IconButton, InputAdornment, Stack, styled } from '@mui/material';
import { HeaderPage } from 'app/components/elements/HeaderPage';
import { InputText } from 'app/components/elements/InputText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useInjection } from 'app/pages/SettingChangePassword/hook';
import theme from 'styles/theme';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { Helmet } from 'react-helmet-async';

function SettingChangePasswordPage() {
  const {
    showNewPassword,
    setShowPNewPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    handleSubmitForm,
    register,
    handleSubmit,
    showPassword,
    setShowPassword,
    errors,
    modalConfirm,
    setModalConfirm
  } = useInjection();

  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - Change Password</title>
      </Helmet>
      <HeaderPage title={'Change Password'} isBack />
      <WrapperContent>
        <Stack gap={2.5}>
          <Stack gap={1}>
            <InputText
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <Visibility sx={{ color: theme.colors.dark1 }} />
                    ) : (
                      <VisibilityOff sx={{ color: theme.colors.dark1 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputError={errors.currentPassword?.message}
              inputProps={{
                ...register('currentPassword'),
                type: showPassword ? 'text' : 'password'
              }}
              placeholder={'Current Password'}
            />
          </Stack>
          <Stack gap={1}>
            <InputText
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPNewPassword(!showNewPassword)}>
                    {showNewPassword ? (
                      <Visibility sx={{ color: theme.colors.dark1 }} />
                    ) : (
                      <VisibilityOff sx={{ color: theme.colors.dark1 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputError={errors.newPassword?.message}
              inputProps={{
                ...register('newPassword'),
                type: showNewPassword ? 'text' : 'password'
              }}
              placeholder={'New Password'}
            />
          </Stack>
          <Stack gap={1}>
            <InputText
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? (
                      <Visibility sx={{ color: theme.colors.dark1 }} />
                    ) : (
                      <VisibilityOff sx={{ color: theme.colors.dark1 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputError={errors.confirmNewPassword?.message}
              inputProps={{
                ...register('confirmNewPassword'),
                type: showConfirmPassword ? 'text' : 'password'
              }}
              placeholder={'Confirm New Password'}
            />
          </Stack>
          <ButtonCustom onClick={handleSubmit(() => setModalConfirm(true))} label={'Reset'} />
        </Stack>
        <DialogConfirm
          onSubmit={handleSubmit(handleSubmitForm)}
          onClose={() => setModalConfirm(false)}
          isOpen={modalConfirm}
          textTitle={'Please confirm this change'}
          textContent={
            'After confirming, you will be logged out for security reasons. Then you can log in again with your new password.'
          }
          textButton={'Change Password'}
          contentStyle={{ textAlign: 'center' }}
        />
      </WrapperContent>
    </Stack>
  );
}

export default SettingChangePasswordPage;

const WrapperContent = styled(Stack)(() => ({
  padding: '30px 16px 16px 16px'
}));
