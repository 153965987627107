import { useCallback, useEffect, useState } from 'react';
import { TFile, User } from 'types/user';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useSearchParams } from 'react-router-dom';
import { isNil, omitBy } from 'lodash';
import { dispatchFiles, getFilesNotDispatch, payloadDispatchFile } from 'services/files';
import { adminGetAllUser } from 'services/user';
import { useFlash } from 'contexts/flash';

export interface FilterFormValues {
  page: number;
  size: number;
}

export const useDispatch = () => {
  const { setFlash } = useFlash();

  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<TFile[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  const [optionsUser, setOptionsUser] = useState<{ label: string; value: string }[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<{ label: string; value: string }[]>([]);

  const fetchDataFile = useCallback(async (paramsSearch: FilterFormValues) => {
    const data = await getFilesNotDispatch(paramsSearch);
    setRows(data.files);
    setTotalRows(data.total);
  }, []);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [idFile, setIdFile] = useState<number>();

  const { asyncCallback: asyncFetchFile } = useAsyncCallback(fetchDataFile, []);

  useEffect(() => {
    asyncFetchFile({ page: searchParams.get('page') || 1, size: 20 });
  }, [searchParams]);

  const getUserBailiff = useCallback(async () => {
    const data = await adminGetAllUser();
    const dataUserConvert = (data.users as User[]).map((item) => ({
      label: `${item.firstName} ${item.lastName} ( ${item.email} )`,
      value: item.id
    }));
    setOptionsUser(dataUserConvert);
  }, []);

  const { asyncCallback: asyncFetchBailiff } = useAsyncCallback(getUserBailiff, []);

  useEffect(() => {
    asyncFetchBailiff();
  }, []);

  const onChangePage = useCallback(
    (page: number | string) => {
      const params = {
        page: `${page}`,
        earlyMorning: searchParams.get('earlyMorning'),
        doorKnock: searchParams.get('doorKnock'),
        status: searchParams.get('status'),
        types: searchParams.get('types')
      };
      setSearchParams(omitBy(params, isNil) as { [key: string]: string | string[] });
    },
    [searchParams]
  );

  const handleSaveModal = useCallback(async () => {
    const userIds = optionsSelected.map((item) => Number(item.value));
    const payload: payloadDispatchFile = {
      fileId: Number(idFile),
      userIds
    };
    await dispatchFiles(payload);
    setRows((prevState) => prevState.filter((item) => Number(item.id) !== Number(idFile)));
    setFlash({ type: 'success', message: 'Assign to bailiff successful' });
    setShowModal(false);
    setIdFile(undefined);
    setOptionsSelected([]);
  }, [idFile, optionsSelected]);
  const { asyncCallback: asyncHandleSaveModal } = useAsyncCallback(handleSaveModal, []);

  return {
    rows,
    totalRows,
    page: searchParams.get('page') || 1,
    onChangePage,

    showModal,
    idFile,
    optionsUser,
    optionsSelected,
    setOptionsSelected,
    handleSaveModal: asyncHandleSaveModal,
    onCloseModal: useCallback(() => {
      setShowModal(false);
      setIdFile(undefined);
      setOptionsSelected([]);
    }, []),
    handleClickRow: useCallback(
      (id: string) => {
        setShowModal(true);
        setIdFile(Number(id));
        const selected =
          rows
            .find((item) => item.id === id)
            ?.dispatchFiles.map((item) => ({
              value: `${item.user.id}`,
              label: `${item.user.firstName} ${item.user.lastName} ( ${item.user.email} )`
            })) || [];
        setOptionsSelected(selected);
      },
      [rows]
    )
  };
};
