import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Colors } from 'types/theme';

const themeColors: Colors = {
  base: '#22374A',
  light1: '#686e7d',
  light2: '#8f96a0',
  light3: '#d2d2cf',
  light4: '#cbd5d5',
  dark1: '#22374A',
  dark2: '#566977',
  dark3: '#babab6',
  dark4: '#acbebf',
  grayscale1: '#a9abae',
  grayscale2: '#dfe0e1',
  grayscale3: '#6d6e70',
  grayscale4: '',
  purple: '#9747FF',
  red: '#FF4773',
  orange: '#FFAA47',
  blue: '#E3EEF9',
  white: '#fff',
  success: '#4CAF50',
  pending: '#FFC107'
};

// Create a theme instance.
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        c_xs: 0,
        c_sm: 600,
        c_md: 961,
        c_lg: 1281,
        c_xl: 1537
      }
    },
    spacing: 8,
    palette: {
      primary: {
        main: themeColors.base,
        light: '#5CA0E3'
      },
      secondary: {
        main: '#19857b',
        light: themeColors.light1
      },
      error: {
        main: red.A400,
        light: themeColors.base
      },
      text: {
        primary: themeColors.dark1
      }
    },
    typography: {
      fontFamily: '"Encode Sans", sans-serif'
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily: '"Encode Sans", sans-serif'
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '13px'
          }
        }
      }
    }
  },
  { colors: themeColors }
);

export default theme;
