import { Lead, TypeLead } from 'types/user';

export const renderTitleLead = (item: Partial<Lead>, isShowFullInformation = true) => {
  let title = '';
  if (item?.type === TypeLead.POR) {
    title = `POR - ${item?.address}`;
  }
  if (item?.type === TypeLead.POE) {
    title = `POE - ${item?.address}`;
  }
  if (item?.type === TypeLead.PHONE_NUMBER) {
    title = `Phone Number- ${item?.phoneNumber}, CB Phone: ${item?.otherPhoneNumber}`;
  }
  if (item?.type === TypeLead.REFERENCE) {
    title = `REFERENCE - ${item?.nameReference}: ${item?.phoneNumber}`;
  }
  if (item?.type === TypeLead.TOW_YARDS) {
    title = `TOW YARDS `;
  }
  if (item?.type === TypeLead.OTHER_ADDRESS) {
    title = `${item.information} - ${item?.address}`;
  }
  if (item?.type === TypeLead.OTHER_PHONE_NUMBER) {
    title = `${item.information}: ${item?.phoneNumber}`;
  }
  if (item?.type === TypeLead.OTHER_OPEN_NOTE) {
    title = `${item?.information}`;
  }
  if (item?.type === TypeLead.MVS) {
    title = `MVS - ${item?.address}`;
  }
  if (item?.type === TypeLead.VIN) {
    title = `VIN Check`;
  }
  if (item?.type === TypeLead.CARFAX) {
    title = `Carfax`;
  }
  const newTitle = title.includes('-') ? title.split('-') : title.split(',');
  return isShowFullInformation ? title : newTitle[0];
};
