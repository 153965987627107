import { Stack, styled as styledMui, InputAdornment, Typography } from '@mui/material';
import {
  DirectionsRenderer,
  GoogleMap,
  Marker as MarkerCurrentLocation
} from '@react-google-maps/api';
import Marker from './marker';
import styled from 'styled-components';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useInjection } from './hooks';
import ModalDetailLead from 'app/components/elements/Modal/ModalDetailLead';
import FilterModal from 'app/components/elements/ModalFilter';
import { InputText } from 'app/components/elements/InputText';
import { LeadMap } from './type';
import React, { memo } from 'react';
import { TableInfiniteScroll } from 'app/components/elements/TableInfiniteScroll';
import { headerTableFile, infoStickies, renderDataMapFileTable } from 'const/table';
import { widthScreen } from 'const/common';
import { ButtonFilter } from 'app/components/elements/ButtonFilter';

const RenderMap = memo(
  ({
    isLoaded,
    center,
    onLoad,
    onUnmount,
    leadsMap,
    activeIndex,
    onUpdateActiveIndex,
    onHold,
    currentLocation,
    directions,
    onGetDirection
  }: {
    isLoaded: boolean;
    center?: {
      lat: number;
      lng: number;
    };
    onLoad: (map: google.maps.Map) => void;
    onUnmount: () => void;
    leadsMap: LeadMap[];
    activeIndex: number[];
    onGetDirection: (newActiveIndex?: number[] | undefined) => void;
    directions?: google.maps.DirectionsResult;
    currentLocation?: {
      lat: number;
      lng: number;
    };
    onHold: (index: number) => void;
    onUpdateActiveIndex: (index: number) => void;
  }) => {
    return (
      <Stack height={'50%'}>
        {isLoaded && center && (
          <GoogleMap
            center={center}
            zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ disableDefaultUI: true, clickableIcons: false }}
            mapContainerStyle={{ height: '50vh' }}>
            {leadsMap.map((item, index) => (
              <Marker
                item={item}
                key={`map${index}`}
                activeIndex={activeIndex.indexOf(index)}
                isActive={activeIndex.includes(index)}
                onPointerUp={() => {
                  onUpdateActiveIndex(index);
                }}
                onHold={() => {
                  onHold(index);
                }}
              />
            ))}

            {currentLocation && <MarkerCurrentLocation position={currentLocation} />}

            {directions && (
              <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
            )}
            {activeIndex.length > 0 && (
              <ButtonRoute
                onClick={() => {
                  onGetDirection();
                }}>
                Route
              </ButtonRoute>
            )}
            <BoxNotify>
              <Item>
                <Dot color="#9747FF" />
                <Text>New</Text>
              </Item>
              <Item>
                <Dot color="#FFAA47" />
                <Text>Attention</Text>
              </Item>
              <Item>
                <Dot color="#FF4773" />
                <Text>Aged</Text>
              </Item>
            </BoxNotify>
          </GoogleMap>
        )}
      </Stack>
    );
  }
);

function MapPage() {
  const {
    isLoaded,
    currentLocation,
    activeIndex,
    onLoad,
    onUnmount,
    directions,
    rows,
    searchInput,
    onGetDirection,
    onClose,
    onOpenModalFilter,
    open,
    onFilter,
    formValue,
    handleInputSearch,
    leadsMap,
    center,
    onUpdateActiveIndex,
    onHold,
    isOpenModalDetailLead,
    onCloseModalDetailLead,
    handleClickRow,
    fetchMoreData,
    hasMore,
    onSortHeader
  } = useInjection();
  const headers = headerTableFile([
    { index: 4, label: 'Lead Title', width: 200 },
    { index: 20, label: 'Address', width: 200 }
  ]);

  return (
    <Stack style={{ overflow: 'hidden', height: '100%' }}>
      <RenderMap
        onGetDirection={onGetDirection}
        onHold={onHold}
        onUpdateActiveIndex={onUpdateActiveIndex}
        center={center}
        leadsMap={leadsMap}
        directions={directions}
        onUnmount={onUnmount}
        onLoad={onLoad}
        activeIndex={activeIndex}
        isLoaded={isLoaded}
        currentLocation={currentLocation}
      />

      <Stack
        height={'50%'}
        overflow={'scroll'}
        id={'divScrollMap'}
        maxWidth={widthScreen < 500 ? widthScreen : '450px'}
        mt={2.5}
        marginInline="auto">
        <Header>
          <TitleStyled>Leads</TitleStyled>
          <HeaderRight>
            <InputText
              placeholder={'Enter your search'}
              inputProps={{
                defaultValue: searchInput
              }}
              hasInputSearch
              onChange={handleInputSearch}
              startAdornment={
                <InputAdornment sx={{ marginLeft: 1.25 }} position="end">
                  <img alt={'search'} src={'/icons/icon_search.svg'} />
                </InputAdornment>
              }
            />
            <ButtonFilter
              isFiltering={
                !(
                  !formValue.doorKnock &&
                  !formValue.earlyMorning &&
                  !formValue.status.length &&
                  !formValue.types.length
                )
              }
              onClick={onOpenModalFilter}
            />
          </HeaderRight>
        </Header>
        <TableInfiniteScroll
          onSortHeader={onSortHeader}
          handleChooseRow={handleClickRow}
          data={renderDataMapFileTable(rows)}
          headers={headers}
          handleFetchMore={fetchMoreData}
          hasMore={hasMore}
          infoStickies={infoStickies}
          idElementScroll={'divScrollMap'}
        />
      </Stack>
      <FilterModal
        isShowChecked
        isShowStatus
        open={open}
        onClose={onClose}
        onSubmit={onFilter}
        value={formValue}
      />
      <ModalDetailLead isOpen={isOpenModalDetailLead} onClose={onCloseModalDetailLead} />
    </Stack>
  );
}

export default MapPage;

const ButtonRoute = styled(ButtonCustom)`
  color: #d2d2cf;
  font-weight: 600;
  font-size: 16px;
  height: 32px;
  padding-inline: 16px;
  background: #22374a;
  border-radius: 100px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
  outline: none;
  border: none;
  width: fit-content;
`;

const BoxNotify = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: white;
  z-index: 9;
  width: 98px;
  height: 78px;
  border: 1px solid #22374a;
  border-radius: 10px;
  padding: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${({ color }) => color};
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #686e7d;
`;

const TitleStyled = styledMui(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '25px',
  marginRight: 'auto'
}));

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 16px;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 999;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
`;
