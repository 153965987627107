import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  getFilesById,
  deleteFile,
  dispatchFiles,
  getOptionFile,
  updateFiles,
  updateStatusTransition
} from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { ClientOption, CreateFiles } from 'app/pages/Admin/FilesPage/components/FileForm/hook';
import { useFlash } from 'contexts/flash';
import { useNavigate, useParams } from 'react-router-dom';
import { NewLeadForm } from 'app/components/modules/ModalNewLead';
import { compact, omit } from 'lodash';
import { Lead, TFile, TypeLead } from 'types/user';
import { AddNote } from 'app/pages/FileDetail/components/LeadBox';
import { createLead, CreateLeadPayload, deleteLead, updateLead } from 'services/leads';
import { TypeOption } from 'app/pages/Admin/NewFileSetUpPage/hook';

export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const { id } = useParams();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [file, setFile] = useState<TFile>();
  const [showModalDeleteFile, setShowModalDeleteFile] = useState<boolean>(false);
  const [showModalDeleteLead, setShowModalDeleteLead] = useState<boolean>(false);
  const [idLeadDelete, setIdLeadDelete] = useState<number>();
  const [optionClient, setOptionClient] = useState<ClientOption[]>([]);
  const [optionClientChild, setOptionClientChild] = useState<ClientOption[]>([]);
  const [optionAuctionDeliveryLocation, setOptionAuctionDeliveryLocation] = useState<TypeOption[]>(
    []
  );

  const getInitFile = useCallback(async () => {
    const [dataFile, dataOption] = await Promise.all([getFilesById(Number(id)), getOptionFile()]);
    const { resOptionClient, resOptionAuctionDeliveryLocation } = dataOption;
    setOptionClient(resOptionClient.clientOptions);
    setOptionAuctionDeliveryLocation(
      resOptionAuctionDeliveryLocation.auctionHouses?.map(
        (item: { id: number; value: string }) => ({
          value: item.id,
          label: item.value
        })
      )
    );
    const fileRes = dataFile.file as TFile;
    const hasClientChild = fileRes?.clientOption?.parentId;
    if (hasClientChild) {
      const listChild = resOptionClient.clientOptions?.find(
        (item: { id: number; value: string; parentId: number }) =>
          item.id === fileRes?.clientOption?.parentId
      )?.children;
      setOptionClientChild(listChild);
    }
    const userAssign = fileRes.dispatchFiles.map((item) => ({
      value: `${item.user.id}`,
      label: `${item.user.firstName} ${item.user.lastName} ( ${item.user.email} )`
    }));
    const fileConvert = {
      ...fileRes,
      userAssign,
      clientOptionsId: fileRes?.clientOption?.parentId
        ? fileRes?.clientOption?.parentId
        : fileRes?.clientOption?.id,
      clientOptionsChildId: fileRes?.clientOption?.parentId ? fileRes?.clientOption?.id : undefined
    };
    setFile(fileConvert);
    setLeads(fileRes.leads);
  }, [id]);

  const changeOptionClient = useCallback(
    (value: number) => {
      const listChild = optionClient.find((item) => item.id === value)?.children;
      if (listChild?.length) {
        setOptionClientChild(listChild);
        return;
      }
      setOptionClientChild([]);
    },
    [optionClient]
  );
  const handleUpdateFile = useCallback(
    async ({
      value,
      fileDocument,
      filePhotos
    }: {
      value: CreateFiles;
      filePhotos?: { file: File; url: string }[];
      fileDocument?: { file: File; url: string }[];
    }) => {
      const formData = new FormData();
      let filePhotosIds: number[] = [];
      let fileDocumentsIds: number[] = [];
      if (filePhotos?.length) {
        for (let x = 0; x < filePhotos.length; x++) {
          formData.append('files', filePhotos[x].file, filePhotos[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/file-photo', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        filePhotosIds = resListIdFile.files;
        formData.delete('files');
      }
      if (fileDocument?.length) {
        for (let x = 0; x < fileDocument.length; x++) {
          formData.append('files', fileDocument[x].file, fileDocument[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/file-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        fileDocumentsIds = resListIdFile.files;
      }
      const filePhotoOld = compact(value.photos?.map((item) => Number(item.storageId)));
      const fileDocsOld = compact(value.fileDocuments?.map((item) => Number(item.storageId)));
      const payload = {
        ...omit(value, ['fileDocuments', 'photos', 'leads', 'id']),
        clientOptionsId: value.clientOptionsChildId || value.clientOptionsId,
        filePhotosIds: [...filePhotosIds, ...filePhotoOld],
        fileDocumentsIds: [...fileDocumentsIds, ...fileDocsOld]
      };
      const userIds = compact(payload.userAssign?.map((item) => Number(item.value))) || [];
      await Promise.all([
        updateFiles(Number(id), payload),
        dispatchFiles({ fileId: Number(id), userIds })
      ]);
      await getInitFile();
      setFlash({ type: 'success', message: 'Update files successful' });
      if (!userIds.length) {
        navigate('/admin/file-overview');
      }
    },
    [getInitFile, id, navigate, setFlash]
  );

  const handleAddNewLead = useCallback(
    async (value: NewLeadForm) => {
      let earlyMorning = false;
      let doorKnock = false;
      if (value.type === TypeLead.POR || value.type === TypeLead.MVS) {
        earlyMorning = true;
        doorKnock = true;
      }
      const leadTowYards = value.towYards?.map((item) => ({ content: item.value })) || [];
      const payload = {
        ...omit(value, ['type']),
        earlyMorning,
        doorKnock,
        leadTowYards,
        type: value.type,
        fileId: Number(id),
        notes:
          value?.noteAdmin && value.noteAdmin.trim() !== ''
            ? [{ content: value.noteAdmin }]
            : undefined
      };
      const data = await createLead(payload);
      setFlash({ type: 'success', message: 'Create leads successful' });
      setLeads((prevState) => [...prevState, data.lead]);
    },
    [id]
  );

  const handleDeleteLead = useCallback(async () => {
    if (!idLeadDelete) return;
    await deleteLead(idLeadDelete);
    setLeads((prevState) => prevState.filter((item) => item.id !== idLeadDelete));
    setShowModalDeleteLead(false);
    setFlash({ type: 'success', message: 'Delete note leads successful' });
  }, [idLeadDelete]);

  const handleDeleteFile = useCallback(async () => {
    if (!id) return;
    await deleteFile(Number(id));
    setShowModalDeleteFile(false);
    setFlash({ type: 'success', message: 'Delete file successful' });
    navigate('/admin/file');
  }, [id]);

  const handleCloseNotFound = useCallback(async () => {
    if (!id) return;
    await updateStatusTransition(Number(id), 'CLOSE_NOT_FOUND');
    setFlash({ type: 'success', message: 'Close not found successful' });
    navigate('/admin/file');
  }, [id]);

  const handleCloseClient = useCallback(async () => {
    if (!id) return;
    await updateStatusTransition(Number(id), 'CLOSE_CLIENT_REQUEST');
    setFlash({ type: 'success', message: 'Close client successful' });
    navigate('/admin/file');
  }, [id]);

  const handleUpdateLead = useCallback(
    async ({
      idLead,
      payload,
      message
    }: {
      idLead: number;
      payload: Partial<CreateLeadPayload>;
      message: string;
    }) => {
      const data = await updateLead(idLead, { ...payload, fileId: Number(id) });
      setLeads((prevState) =>
        prevState.map((item) => {
          if (item.id === idLead) {
            return data.lead;
          }
          return item;
        })
      );
      const newLead = leads.map((item) => {
        if (Number(item.id) === Number(idLead)) {
          return data.lead;
        }
        return item;
      });
      setLeads(newLead);
      setFlash({ type: 'success', message });
    },
    [leads, id]
  );

  const { asyncCallback: asyncUpdateLead } = useAsyncCallback(handleUpdateLead, [id]);

  const { asyncCallback: asyncDeleteFile } = useAsyncCallback(handleDeleteFile, []);
  const { asyncCallback: asyncGetInitFiles } = useAsyncCallback(getInitFile, []);
  const { asyncCallback: asyncUpdateFile } = useAsyncCallback(handleUpdateFile, []);
  const { asyncCallback: asyncCreateLeads } = useAsyncCallback(handleAddNewLead, []);
  const { asyncCallback: asyncHandleDeleteLead } = useAsyncCallback(handleDeleteLead, []);
  const { asyncCallback: asyncCloseNotFound } = useAsyncCallback(handleCloseNotFound, []);
  const { asyncCallback: asyncCloseClient } = useAsyncCallback(handleCloseClient, []);

  useEffect(() => {
    asyncGetInitFiles();
  }, [id]);

  return {
    asyncUpdateFile,
    leads,
    setLeads,
    handleAddNewLead: asyncCreateLeads,
    handleDeleteLead: asyncHandleDeleteLead,
    handleDeleteFile: asyncDeleteFile,
    handleCloseNotFound: asyncCloseNotFound,
    handleCloseClient: asyncCloseClient,
    file,
    showModalDeleteFile,
    setShowModalDeleteFile,
    showModalDeleteLead,
    setShowModalDeleteLead,
    setIdLeadDelete,
    optionClient,
    optionClientChild,
    optionAuctionDeliveryLocation,
    changeOptionClient,
    handleAddNewNote: async ({ idLead, textNote }: AddNote) =>
      await asyncUpdateLead({
        idLead,
        payload: {
          notes: [{ content: textNote }]
        },
        message: 'Add note leads successful'
      }),
    handleEditNote: async (idLead: number, idNote: number, content: string) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          notes: [{ id: idNote, content: content }]
        },
        message: 'Edit note leads successful'
      });
    },
    handleAddTowYards: async ({
      idLead,
      towYards
    }: {
      idLead: number;
      towYards: { value: string; id?: number; checked: boolean }[];
    }) => {
      const leadTowYards =
        compact(
          towYards?.map((item) => {
            if (!item.id) {
              return { content: item.value, checked: item.checked };
            }
            return { id: item.id, content: item.value, checked: item.checked };
          })
        ) || [];
      if (!leadTowYards.length) return;
      await asyncUpdateLead({
        idLead,
        payload: {
          leadTowYards
        },
        message: 'Add tow yards successful'
      });
    },
    handleDeleteTowYards: async ({
      idTowYards,
      idLead
    }: {
      idLead: number;
      idTowYards: number;
    }) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          leadTowYards: [
            {
              id: idTowYards,
              isDelete: true
            }
          ]
        },
        message: 'Add tow yards successful'
      });
    },
    handleChangeVinStatus: async ({ vinStatus, idLead }: { idLead: number; vinStatus: string }) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          vinStatus
        },
        message: 'update VIN check successful'
      });
    },
    handleUploadFileCarfax: async ({
      files,
      idLead,
      oldDocIds
    }: {
      idLead: number;
      files: File[];
      oldDocIds: number[];
    }) => {
      const formData = new FormData();
      let leadDocumentIds: number[] = [];
      if (files?.length) {
        for (let x = 0; x < files.length; x++) {
          formData.append('files', files[x], files[x].name);
        }
        const { data: resListIdFile } = await axios.post('/storages/lead-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        leadDocumentIds = [...resListIdFile.files, ...oldDocIds];
        await asyncUpdateLead({
          idLead: idLead,
          payload: {
            leadDocumentIds
          },
          message: 'Update leads successful'
        });
      }
    },
    handleDeleteDocumentLead: async ({
      idLead,
      oldDocIds
    }: {
      idLead: number;
      oldDocIds: number[];
    }) => {
      await asyncUpdateLead({
        idLead: idLead,
        payload: {
          leadDocumentIds: [...oldDocIds]
        },
        message: 'Update leads successful'
      });
    }
  };
};
