import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { resetPasswordSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputPassword } from 'app/components/elements/InputPassword';

export type FormResetPasswordType = {
  password: string;
  confirmPassword: string;
};

type FormResetPasswordProps = {
  onSubmit: (data: FormResetPasswordType) => void;
};
export const FormResetPassword = ({ onSubmit }: FormResetPasswordProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormResetPasswordType>({ resolver: yupResolver(resetPasswordSchema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack width={'100%'} alignItems={'center'} mt={'17px'} mb={8}>
          Please enter your new password below.
        </Stack>
        <InputPassword
          inputProps={register('password')}
          inputError={errors.password?.message}
          placeholder="Password"
        />
        <InputPassword
          inputProps={register('confirmPassword')}
          inputError={errors.confirmPassword?.message}
          placeholder="Confirm Password"
        />
        <ButtonCustom type={'submit'} label={'Reset'} />
      </Stack>
    </form>
  );
};
