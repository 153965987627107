import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dashboard, TFile } from 'types/user';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { getCountFileByStatus, getFiles } from 'services/files';
export const TAB = {
  OPEN_FILES: 'open-files',
  ON_HOLD: 'on-hold',
  REDEMPTION: 'redemption'
};

export const headerTab = [
  { label: 'Open Files', param: TAB.OPEN_FILES, value: 0 },
  { label: 'On Hold', param: TAB.ON_HOLD, value: 1 },
  { label: 'Redemption', param: TAB.REDEMPTION, value: 2 }
];

type FileState = {
  files: TFile[];
  total: number;
};

type PageState = {
  [key: string]: number;
};

export const useInjection = () => {
  const navigate = useNavigate();
  const { loading } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dashboard, setDashboard] = useState<Dashboard>();

  const [fileStates, setFileStates] = useState<{ [key: string]: FileState }>({
    [TAB.OPEN_FILES]: { files: [], total: 0 },
    [TAB.ON_HOLD]: { files: [], total: 0 },
    [TAB.REDEMPTION]: { files: [], total: 0 }
  });

  const [pages, setPages] = useState<PageState>({
    [TAB.OPEN_FILES]: 1,
    [TAB.ON_HOLD]: 1,
    [TAB.REDEMPTION]: 1
  });
  const fetchDataForTab = useCallback(async (page: number, tab: string) => {
    const data = await getFiles({
      page,
      size: SIZE_PAGE_CONTROL_PANEL,
      fileStatus: tab
    });
    setFileStates((prev) => ({
      ...prev,
      [tab]: { files: data.files, total: data.total }
    }));
  }, []);
  const { asyncCallback: asyncFetchUser } = useAsyncCallback(fetchDataForTab, []);

  const fetchDataDashboard = useCallback(async () => {
    const [dashboardData, ...tabData] = await Promise.all([
      getCountFileByStatus(),
      ...Object.values(TAB).map((tab) =>
        getFiles({
          page: 1,
          size: SIZE_PAGE_CONTROL_PANEL,
          fileStatus: tab
        })
      )
    ]);

    setDashboard(dashboardData.dashboard);

    Object.values(TAB).forEach((tab, index) => {
      setFileStates((prev) => ({
        ...prev,
        [tab]: { files: tabData[index].files, total: tabData[index].total }
      }));
    });
  }, []);

  useEffect(() => {
    fetchDataDashboard();
  }, []);

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel, tab: string) => {
      const newPage = newPaginationModel.page + 1;
      setPages((prev) => ({ ...prev, [tab]: newPage }));
      asyncFetchUser(newPage, tab);
    },
    [asyncFetchUser]
  );
  const handleRowClick = (params: GridRowParams) => {
    navigate(`/admin/files/${params.id}`);
  };

  const handleChangeTab = useCallback(
    (newValue: number) => {
      const nameTab = headerTab.find((item) => item.value === newValue)?.param;
      setSearchParams({ tab: nameTab || TAB.OPEN_FILES });
    },
    [setSearchParams]
  );
  const currentTab = useMemo(() => {
    const params = searchParams.get('tab');
    return headerTab.find((item) => item.param === params)?.value || 0;
  }, [searchParams]);

  const optionHeader = useMemo(() => {
    return [
      {
        label: 'Open Files',
        value: dashboard?.openFile
      },
      {
        label: 'On Hold',
        value: dashboard?.onHold
      },
      {
        label: 'Redemption',
        value: dashboard?.redemption
      }
    ];
  }, [dashboard]);

  return {
    tab: currentTab,
    searchParams,
    fileStates,
    pages,
    handleRowClick,
    loading,
    handlePaginationModelChange,
    handleChangeTab,
    dashboard,
    optionHeader
  };
};
