import { Typography, Stack, Box, styled, Grid, Link } from '@mui/material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import React from 'react';
import { useSeizureDetails } from './hooks';
import { StyledParams } from 'types/common';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { Helmet } from 'react-helmet-async';
import { saveVideoFile } from 'utils/downloadFile';

type DetailItemProps = {
  title: string;
  content: string | number | boolean | undefined;
};
const DetailItem = ({ title, content }: DetailItemProps) => (
  <WrapperContent>
    <TextTitleStyled>{title}:</TextTitleStyled>
    <TextContentStyled>{content}</TextContentStyled>
  </WrapperContent>
);

export const SeizureDetails = () => {
  const { handleOnClick, dataDetail, handleOnBack } = useSeizureDetails();

  return (
    <LayoutAdminTable title={'Seizure Details'} textButton={'Back'} onClickButton={handleOnBack}>
      <Helmet>
        <title>Surrey Bailiffs - Seizure Details</title>
      </Helmet>
      <Stack gap={2.5} width={'100%'} flexDirection={'row'}>
        <Wrapper maxWidth={'900px'}>
          <Stack direction={'row'} gap={2.5} sx={{ flexWrap: 'wrap' }}>
            <DetailItem title="KM" content={dataDetail?.kilometers} />
            <DetailItem
              title="Seize Address"
              content={dataDetail?.addressLeadId || dataDetail?.otherAddress}
            />
            <DetailItem
              title="Customers POR"
              content={dataDetail?.customersLeadId || dataDetail?.otherCustomerPOR}
            />
            <DetailItem
              title="Removed To"
              content={dataDetail?.removedToLocation?.value || dataDetail?.removedToOther}
            />
            <DetailItem title="Keys" content={dataDetail?.hasKey ? 'Yes' : 'No'} />
            <DetailItem title="Runs" content={dataDetail?.run ? 'Yes' : 'No'} />
            <DetailItem title="Signed VSA" content={dataDetail?.signedVsa ? 'Yes' : 'No'} />
            <DetailItem
              title="Possible Redemption"
              content={dataDetail?.possibleRedemption ? 'Yes' : 'No'}
            />
            <DetailItem title="Condition Report" content={dataDetail?.conditionReport} />
            <DetailItem title="Towing Company Used" content={dataDetail?.towingCompany} />
            <DetailItem
              title="Transport Required"
              content={dataDetail?.transportRequired ? dataDetail?.otherTransport : 'No'}
            />
            <DetailItem
              title="Other Bills Incurred"
              content={dataDetail?.billsIncurred ? dataDetail?.otherBills : 'No'}
            />
          </Stack>

          <Grid container gap={2.5}>
            {dataDetail?.signedVsa && (
              <Box minWidth={'265px'}>
                <TextTitleStyled sx={{ marginBottom: '16px' }}>Document:</TextTitleStyled>
                <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
                  {dataDetail?.vsaDocuments?.map((doc, index) => (
                    <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}>
                        <Stack flexDirection={'row'} gap={1.5} alignItems={'center'}>
                          <img src={'/icons/icon_document.svg'} alt={'icon'} />
                          <NameDocument onClick={() => saveVideoFile(doc.url, doc.name)}>
                            {doc.name}
                          </NameDocument>
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <Box
              minWidth={'265px'}
              maxWidth={dataDetail?.signedVsa ? '530px' : 'calc(100% - 100px)'}>
              <TextTitleStyled sx={{ marginBottom: '16px' }}>Photos:</TextTitleStyled>
              <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
                {dataDetail?.seizureReportPhotos.map((file, index) => (
                  <Grid item xs={4} sm={4} md={4} lg={dataDetail?.signedVsa ? 4 : 3} key={index}>
                    <Box
                      component="img"
                      src={file.url}
                      alt={`Image ${index + 1}`}
                      sx={{
                        width: '160px',
                        height: '160px',
                        objectFit: 'cover',
                        display: 'block',
                        borderRadius: '10px'
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Wrapper>
      </Stack>

      <Stack flexDirection={'row'} justifyContent={'end'}>
        <ButtonCustom
          style={{ width: '250px', marginTop: '20px' }}
          onClick={handleOnClick}
          label={'Move to post seizure'}
        />
      </Stack>
    </LayoutAdminTable>
  );
};
const WrapperContent = styled(Box)(() => ({
  width: '265px'
}));

const Wrapper = styled(Stack)(() => ({
  padding: '40px 30px',
  border: '1px solid #ccc',
  borderRadius: '20px',
  gap: 20
}));

const TextTitleStyled = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  color: '#000000'
}));

const TextContentStyled = styled(Typography)(({ theme }: StyledParams) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: '10px',
  marginTop: theme.spacing(0.5),
  height: '50px'
}));

const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '80px',
  cursor: 'pointer'
}));
