import { useCallback, useEffect, useState } from 'react';
import { TFile } from 'types/user';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFiles } from 'services/files';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';

const CATEGORY = {
  REDEMPTION: 'redemption'
};

export const useRedemption = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<TFile[]>([]);

  const [totalRows, setTotalRows] = useState<number>(0);
  const fetchDataFile = useCallback(async () => {
    const data = await getFiles({
      fileStatus: CATEGORY.REDEMPTION,
      page: Number(searchParams.get('page')) || 1,
      size: SIZE_PAGE_CONTROL_PANEL
    });
    setRows(data.files);
    setTotalRows(data.total);
  }, []);

  const { asyncCallback: asyncFetchFile } = useAsyncCallback(fetchDataFile, []);

  useEffect(() => {
    asyncFetchFile({ page: searchParams.get('page') || 1 });
  }, [searchParams]);

  const onChangePage = useCallback(
    (page: number | string) => {
      setSearchParams({ page: String(page) });
    },
    [searchParams]
  );

  const handleClickRow = useCallback((id: string, isPostSeizure: boolean, fileId: number) => {
    if (isPostSeizure) {
      return navigate(`/admin/redemptions/post-seizure/edit/${id}`);
    }
    if (!id) {
      return navigate(`/admin/redemptions/pre-seizure/create?fileId=${fileId}`);
    }
    return navigate(`/admin/redemptions/pre-seizure/edit/${id}`);
  }, []);

  return {
    rows,
    totalRows,
    page: searchParams.get('page') || 1,
    onChangePage,
    handleClickRow
  };
};
