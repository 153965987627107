/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { User } from 'types/user';

type PaginationType = {
  page: number;
  size: number;
};

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  lastLoginAt: any;
  isActive: boolean;
  userDocuments: any[];
}

export const adminGetUser = async ({ page, size }: PaginationType) => {
  const { data } = await axios.get(`/admin/users?page=${page || 1}&size=${size}`);
  return data;
};

export const adminGetAllUser = async () => {
  const { data } = await axios.get(`/admin/users-all`);
  return data;
};
export const adminGetAdmins = async ({ page, size }: PaginationType) => {
  const { data } = await axios.get(`/admin/users-admin?page=${page || 1}&size=${size}`);
  return data;
};

export const adminGetDetailUser = async (id: number) => {
  const { data } = await axios.get(`/admin/users/${id}`);
  return data;
};
export const adminUpdateUser = async (id: number, payload: Partial<User>) => {
  const { data } = await axios.put(`/admin/users/${id}`, payload);
  return data;
};
export const adminCreateUser = async (payload: Partial<User>) => {
  const { data } = await axios.post(`/admin/users`, payload);
  return data;
};

export const adminGetArchive = async ({ page, size }: PaginationType) => {
  const { data } = await axios.get(`/admin/archives?page=${page || 1}&size=${size}`);
  return data;
};
