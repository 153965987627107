import axios from 'axios';
import { FormLoginType } from '../app/pages/LoginPage/components/FormLogin';
import { ProfileForm } from 'app/pages/SettingProfileInformation/hook';

export const changePassword = async (payload: { currentPassword: string; newPassword: string }) => {
  const { data } = await axios.post('/auth/change-password', payload);
  return data;
};

export const loginAuth = async (payload: FormLoginType) => {
  const { data } = await axios.post('/auth/signin', payload);
  return data;
};

export const signUp = async (payload: { password: string; code: string }) => {
  const { data } = await axios.post('/auth/active-account', payload);
  return data;
};

export const getUserInfo = async () => {
  const { data } = await axios.get('/auth/me');
  return data;
};

export const updateUser = async (payload: ProfileForm) => {
  const { data } = await axios.put('/auth/me', payload);
  return data;
};
