import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Lead } from 'services/leads';
import styled from 'styled-components';

const ModalDetailLead = ({ isOpen, onClose }: { isOpen?: Lead; onClose: () => void }) => {
  const div = useRef<HTMLDivElement | null>(null);
  const portElement = useMemo(() => {
    if (!div.current) {
      div.current = document.createElement('div');
      document.body.append(div.current);
    }
    return div.current;
  }, []);
  useEffect(() => () => portElement.remove(), []);
  if (!portElement) return null;

  return createPortal(
    <Container $isOpen={isOpen}>
      {isOpen && <Overlay onClick={onClose} />}
      <Content>
        <Status>New</Status>
        <ListItem>
          <Item>
            <ItemHalfWidth>
              <Label>Client Name: </Label>
              <Value>{isOpen?.file?.clientName}</Value>
            </ItemHalfWidth>
            <ItemHalfWidth>
              <Label>Client File Number: </Label>
              <Value>{isOpen?.file?.fileNumber}</Value>
            </ItemHalfWidth>
          </Item>
          <Item>
            <ItemHalfWidth>
              <Label>Last Name: </Label>
              <Value>{isOpen?.file?.lastName}</Value>
            </ItemHalfWidth>
            <ItemHalfWidth>
              <Label>First Name: </Label>
              <Value>{isOpen?.file?.firstName}</Value>
            </ItemHalfWidth>
          </Item>
          <Item>
            <ItemHalfWidth>
              <Label>Auction House: </Label>
              <Value>{isOpen?.file?.auctionHouse}</Value>
            </ItemHalfWidth>
            <ItemHalfWidth>
              <Label>Year, Make, Model: </Label>
              <Value>
                {[
                  isOpen?.file?.year ?? '',
                  isOpen?.file?.make ?? '',
                  isOpen?.file?.model ?? ''
                ].join(' ')}
              </Value>
            </ItemHalfWidth>
          </Item>
          <Item>
            <ItemFullWidth>
              <Label>VIN Check: </Label>
              <Value>{isOpen?.file?.vin}</Value>
            </ItemFullWidth>
          </Item>
          <Item>
            <ItemHalfWidth>
              <Label>Colour: </Label>
              <Value>{isOpen?.file?.colour}</Value>
            </ItemHalfWidth>
            <ItemHalfWidth>
              <Label>Plate: </Label>
              <Value>{isOpen?.file?.plate}</Value>
            </ItemHalfWidth>
          </Item>
        </ListItem>
      </Content>
    </Container>,
    portElement
  );
};

export default React.memo(ModalDetailLead);

const Container = styled.div<{ $isOpen?: Lead }>`
  height: 100dvh;
  background: transparent;
  position: fixed;
  transition: all 0.15s linear;
  top: ${({ $isOpen }) => ($isOpen ? '0%' : '-100%')};
  left: 0%;
  z-index: 99999;
  width: 100vw;
  display: flex;
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100dvh;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  max-width: 273px;
  background: white;
  border: 3px solid rgba(34, 55, 74, 1);
  border-radius: 20px;
  margin: auto;
  position: relative;
  z-index: 9999;
  padding: 15px 25px 20px 25px;
`;

const Status = styled.span`
  height: 20px;
  background: #9747ff;
  padding: 0 8px;
  color: white;
  border-radius: 5px;
  font-size: 10px;
  display: inline-block;
  line-height: 20px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
`;

const ItemHalfWidth = styled.div`
  width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;
const ItemFullWidth = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #686e7d;
`;

const Value = styled.span`
  color: #22374a;
  font-size: 10px;
  font-weight: 500;
`;
