import React, { FC } from 'react';
import { Box, Grid, IconButton, Link, Stack, styled, TextField, Typography } from '@mui/material';
import { InputText } from 'app/components/elements/InputText';
import { RadioGroupCustom } from 'app/components/elements/RadioGroupCustom';
import { StyledParams } from 'types/common';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SeizureSchema } from 'utils/validate';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { TextError } from 'app/components/elements/TextError';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { FormDataSeizure } from 'app/pages/SeizureReportsPage/hooks';
import dayjs from 'dayjs';
import { TypeOption } from 'app/pages/Admin/NewFileSetUpPage/hook';
import { InputNumber } from 'app/components/elements/InputNumber';

type FormSeizureProps = {
  photos: File[];
  documents: File[];
  optionAuctionDeliveryLocation: TypeOption[];
  onSubmit: (data: FormDataSeizure) => void;
  handleDeleteDocument: (indexToDelete: number) => void;
  fileInputPhotoRef: React.MutableRefObject<HTMLInputElement | null>;
  fileInputDocumentRef: React.MutableRefObject<HTMLInputElement | null>;
  optionsAddress: SelectOptions[];
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'photo' | 'document'
  ) => void;
};

export const FormSeizure: FC<FormSeizureProps> = ({
  photos,
  documents,
  onSubmit,
  fileInputPhotoRef,
  fileInputDocumentRef,
  handleFileChange,
  handleDeleteDocument,
  optionsAddress,
  optionAuctionDeliveryLocation
}) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    control,
    clearErrors,
    setValue
  } = useForm<FormDataSeizure>({
    resolver: yupResolver(SeizureSchema)
  });
  const watchShowSigned = watch('signedVSA');
  const watchShowOtherAddress = watch('addressLeadId');
  const watchShowOtherCustomer = watch('customersLeadId');
  const watchRemove = watch('removedToId');
  const billsIncurred = watch('billsIncurred');
  const transportRequired = watch('transportRequired');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2.5}>
        <WrapperTable>
          <TableRowCustom sx={{ borderTopLeftRadius: '10px' }}>Seize Date</TableRowCustom>
          <TableCellCustom sx={{ borderTopRightRadius: '10px', paddingLeft: '4px' }}>
            <Typography sx={{ padding: '8px', fontSize: '13px', fontWeight: 500 }}>
              {dayjs().format('MMM D, YYYY')}
            </Typography>
          </TableCellCustom>
        </WrapperTable>
        <WrapperTable>
          <TableRowCustom>Seize Address</TableRowCustom>
          <TableCellCustom
            sx={{ paddingLeft: '4px', flexDirection: 'column', gap: '6px', padding: '8px' }}>
            <Controller
              control={control}
              name="addressLeadId"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  displayEmpty>
                  {optionsAddress &&
                    optionsAddress?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </StyledSelect>
              )}
            />
            {watchShowOtherAddress === 'other' && (
              <InputText
                hasInputSearch
                placeholder={'Other Address'}
                inputProps={{
                  ...register('otherAddress')
                }}
              />
            )}
          </TableCellCustom>
        </WrapperTable>
        <WrapperTable>
          <TableRowCustom>Customers POR</TableRowCustom>
          <TableCellCustom
            sx={{ paddingLeft: '4px', flexDirection: 'column', gap: '6px', padding: '8px' }}>
            <Controller
              control={control}
              name="customersLeadId"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  displayEmpty>
                  {optionsAddress &&
                    optionsAddress?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </StyledSelect>
              )}
            />
            {watchShowOtherCustomer === 'other' && (
              <InputText
                hasInputSearch
                placeholder={'Other CustomerPOR'}
                inputProps={{
                  ...register('otherCustomerPOR')
                }}
              />
            )}
          </TableCellCustom>
        </WrapperTable>
        <WrapperTable>
          <TableRowCustom
            sx={{ borderBottomLeftRadius: '10px', borderBottom: '1px solid #8F96A0' }}>
            Removed To
          </TableRowCustom>
          <TableCellCustom
            sx={{
              paddingLeft: '4px',
              flexDirection: 'column',
              gap: '6px',
              padding: '8px',
              borderBottom: '1px solid #8F96A0'
            }}>
            <Controller
              control={control}
              name="removedToId"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  displayEmpty>
                  {optionAuctionDeliveryLocation &&
                    optionAuctionDeliveryLocation?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </StyledSelect>
              )}
            />
            {watchRemove === 0 && (
              <InputText
                hasInputSearch
                placeholder={'Other Location'}
                inputProps={{
                  ...register('removedToOther')
                }}
              />
            )}
          </TableCellCustom>
        </WrapperTable>
        {(errors.addressLeadId?.message ||
          errors.customersLeadId?.message ||
          errors.removedToId?.message ||
          errors.removedToOther?.message) && (
          <FormHelperText error sx={{ ml: 0, maxWidth: '100%' }}>
            <TextError errorText="Table is invalid" />
          </FormHelperText>
        )}
      </Box>
      <Box>
        <Controller
          name={'kilometers'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputNumber
              title={'KM'}
              inputError={errors.kilometers?.message}
              placeholder={'KM'}
              inputProps={{}}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Box>

      <WrapperBorder>
        <Controller
          control={control}
          name="hasKey"
          render={({ field: { onChange, value } }) => (
            <RadioGroupCustom
              label={'Keys:'}
              inputError={errors.hasKey?.message}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </WrapperBorder>

      <WrapperBorder>
        <Controller
          control={control}
          name="run"
          render={({ field: { onChange, value } }) => (
            <RadioGroupCustom
              label={'Runs:'}
              inputError={errors.run?.message}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </WrapperBorder>

      <WrapperBorder>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Controller
            control={control}
            name="signedVSA"
            render={({ field: { onChange, value } }) => (
              <RadioGroupCustom
                label={'Signed VSA:'}
                inputError={errors.signedVSA?.message}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {watchShowSigned && JSON.parse(`${watchShowSigned}`) && (
            <>
              <IconButton
                onClick={() => {
                  if (fileInputDocumentRef.current) {
                    fileInputDocumentRef.current.click();
                  }
                }}
                sx={{ width: '40px', height: '40px', marginTop: '-12px' }}>
                <img src={'/icons/icon_upload.svg'} alt={'icon-upload'} />
                <input
                  accept="image/pdf"
                  ref={fileInputDocumentRef}
                  hidden
                  onChange={(e) => handleFileChange(e, 'document')}
                  type="file"
                  multiple
                />
              </IconButton>
            </>
          )}
        </Box>
        {watchShowSigned && JSON.parse(`${watchShowSigned}`) && documents.length === 0 && (
          <FormHelperText sx={{ ml: 0, maxWidth: '100%' }}>
            <TextError errorText="Please upload a file" />
          </FormHelperText>
        )}
        {documents.length > 0 && (
          <Stack gap={1.25}>
            <WrapperHeaderBox>
              <TitleLead>Documents</TitleLead>
            </WrapperHeaderBox>
            <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
              {documents.map((doc, index) => (
                <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Stack
                      width={'calc(100% - 20px)'}
                      flexDirection={'row'}
                      gap={1.5}
                      alignItems={'center'}>
                      <img src={'/icons/icon_document.svg'} alt={'icon'} />
                      <NameDocument>{doc.name}</NameDocument>
                    </Stack>
                    <img
                      onClick={() => handleDeleteDocument(index)}
                      style={{ cursor: 'pointer' }}
                      src={'/icons/icon_delete_file.svg'}
                      alt={'icon'}
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </WrapperBorder>

      <WrapperBorder>
        <Controller
          control={control}
          name="possibleRedemption"
          render={({ field: { onChange, value } }) => (
            <RadioGroupCustom
              label={'Possible Redemption:'}
              inputError={errors.possibleRedemption?.message}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </WrapperBorder>

      <WrapperBorder>
        <FormControl sx={{ marginBottom: '10px' }}>
          <FormLabelCustom>Condition Report:</FormLabelCustom>
          <TextField {...register('conditionReport')} multiline rows={2} />
        </FormControl>
        {errors.conditionReport?.message && (
          <FormHelperText
            error={!!errors.conditionReport?.message}
            sx={{ ml: 0, maxWidth: '100%' }}>
            <TextError errorText={errors.conditionReport?.message} />
          </FormHelperText>
        )}
      </WrapperBorder>

      <Box mt={2.5}>
        <FormControl>
          <FormLabelCustom>Towing Company Used:</FormLabelCustom>
        </FormControl>
        <InputText
          hasInputSearch
          inputProps={{ ...register('towingCompany') }}
          onChange={(e) => {
            clearErrors('towingCompany');
            setValue('towingCompany', e.target.value);
          }}
          inputError={errors.towingCompany?.message}
        />
      </Box>

      <WrapperBorder>
        <Controller
          control={control}
          name="transportRequired"
          render={({ field: { onChange, value } }) => (
            <RadioGroupCustom
              label={'Transport Required?'}
              inputError={errors.transportRequired?.message}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {JSON.parse(`${transportRequired || false}`) && (
          <Box mt={2.5}>
            <FormControl>
              <FormLabelCustom>Notes:</FormLabelCustom>
            </FormControl>
            <InputText
              hasInputSearch
              inputProps={{ ...register('otherTransport') }}
              onChange={(e) => {
                clearErrors('otherTransport');
                setValue('otherTransport', e.target.value);
              }}
              inputError={errors.otherTransport?.message}
            />
          </Box>
        )}
      </WrapperBorder>

      <WrapperBorder>
        <Controller
          control={control}
          name="billsIncurred"
          render={({ field: { onChange, value } }) => (
            <RadioGroupCustom
              label={'Other Bills Incurred?'}
              inputError={errors.billsIncurred?.message}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {JSON.parse(`${billsIncurred || false}`) && (
          <Box mt={2.5}>
            <FormControl>
              <FormLabelCustom>Notes:</FormLabelCustom>
            </FormControl>
            <InputText
              hasInputSearch
              inputProps={{ ...register('otherBills') }}
              onChange={(e) => {
                clearErrors('otherBills');
                setValue('otherBills', e.target.value);
              }}
              inputError={errors.otherBills?.message}
            />
          </Box>
        )}
      </WrapperBorder>

      <WrapperHeaderBox>
        <TitleLead>Photos of vehicle</TitleLead>
        <IconButton
          onClick={() => {
            if (fileInputPhotoRef.current) {
              fileInputPhotoRef.current.click();
            }
          }}
          sx={{ width: '40px', height: '40px' }}>
          <img src={'/icons/icon_upload.svg'} alt={'icon-upload'} />
          <input
            accept="image/*"
            ref={fileInputPhotoRef}
            hidden
            onChange={(e) => handleFileChange(e, 'photo')}
            type="file"
            multiple
          />
        </IconButton>
      </WrapperHeaderBox>

      <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
        {photos.map((file, index) => (
          <Grid item xs={4} sm={4} md={4} lg={4} key={index}>
            <Box
              component="img"
              src={URL.createObjectURL(file)}
              alt={`Image ${index + 1}`}
              sx={{
                width: '100%',
                height: '120px',
                objectFit: 'cover',
                display: 'block',
                borderRadius: '10px'
              }}
            />
          </Grid>
        ))}
      </Grid>
      <ButtonCustom sx={{ marginTop: '20px' }} type={'submit'} label={'Submit'} />
    </form>
  );
};

const WrapperTable = styled(Box)(() => ({
  display: 'flex'
}));

const TableRowCustom = styled(Box)(() => ({
  fontSize: '13px',
  width: '120px',
  minHeight: '40px',
  borderTop: '1px solid #8F96A0',
  borderLeft: '1px solid #8F96A0',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
  fontWeight: 500
}));

const TableCellCustom = styled(Box)(() => ({
  flex: 1,
  fontSize: '13px',
  borderTop: '1px solid #8F96A0',
  borderLeft: '1px solid #8F96A0',
  borderRight: '1px solid #8F96A0',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '18px',
  paddingRight: '10px',
  fontWeight: 500
}));

const FormLabelCustom = styled(FormLabel)(() => ({
  fontSize: '13px',
  color: '#566977',
  marginBottom: '12px'
}));

const WrapperBorder = styled(Stack)(({ theme }: StyledParams) => ({
  padding: '20px 0 10px',
  borderBottom: `1px solid ${theme.colors.light3}`
}));

const WrapperHeaderBox = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '4px'
}));

const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%'
}));

const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 24px)'
}));

const StyledSelect = styled(Select)({
  height: '36px',
  width: '100%',
  maxWidth: '272px',
  borderRadius: '10px',
  '& .MuiInputBase-root': {
    height: '36px',
    fontSize: '13px',
    fontWeight: 500
  }
});
