import { Stack } from '@mui/material';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { FormSignUp } from 'app/pages/SignUpPage/components/FormSignUp';
import { useSignUp } from 'app/pages/SignUpPage/hooks';
import { Helmet } from 'react-helmet-async';
import React from 'react';

export const SignUpPage = () => {
  const { handleSubmitForm } = useSignUp();
  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Surrey Bailiffs - SignUp</title>
      </Helmet>
      <LayoutContent
        title={'Register Yourself'}
        textFooter={'Already have an account?'}
        textNavigation={'/login'}
        textLink={'Sign In'}
        children={<FormSignUp onSubmit={handleSubmitForm} />}
      />
    </Stack>
  );
};
