import { NumericFormat } from 'react-number-format';
import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { TextError } from 'app/components/elements/TextError';
import theme from 'styles/theme';

export type RequiredInputProps = { required: true };

export type InputNumberProps = {
  sizeTitle?: string;
  title?: string;
  inputProps:
    | RequiredInputProps
    | Omit<
        OutlinedInputProps,
        'defaultValue' | 'type' | 'value' | 'onChange' | 'autoComplete' | 'onBlur'
      >;
  inputError?: string;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  errorWithoutText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement | null> | null;
  onChange?: (value?: number) => void;
  value?: number;
  onBlur?: () => void;
  autoComplete?: string;
  decimalScale?: number;
};

/**
 * @returns Element InputText
 */
export const InputNumber: FC<InputNumberProps> = ({
  sizeTitle,
  title,
  inputProps,
  inputError,
  placeholder,
  startAdornment,
  endAdornment,
  errorWithoutText = false,
  inputRef,
  onChange = () => {},
  value
}) => {
  return (
    <FormControl fullWidth error={!!inputError}>
      {title && (
        <FormLabel>
          <Typography
            fontSize={sizeTitle || '13px'}
            fontWeight={500}
            mb={1}
            color={theme.colors.dark1}>
            {title}
          </Typography>
        </FormLabel>
      )}
      <NumericFormat
        {...inputProps}
        value={value}
        endAdornment={endAdornment}
        inputProps={{
          ref: inputRef
        }}
        placeholder={placeholder}
        startAdornment={startAdornment}
        customInput={InputNumberStyled}
        allowLeadingZeros
        thousandSeparator=","
        allowNegative={false}
        onValueChange={(values) => {
          onChange(values.floatValue);
        }}
        isAllowed={(values) => {
          if (!values.value?.toString()) return true;

          if (values.value.startsWith('00')) return false;

          return true;
        }}
      />
      {inputError && !errorWithoutText && (
        <FormHelperText error={!!inputError} sx={{ ml: 0, maxWidth: '100%' }}>
          <TextError errorText={inputError} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

const InputNumberStyled = styled(OutlinedInput)(() => ({
  minHeight: 56,
  paddingRight: 8,
  paddingLeft: 0,
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    padding: '16px 12px',
    fontWeight: 400,
    fontSize: 16
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    borderRadius: 10
  }
}));
