import axios from 'axios';
import * as qs from 'qs';

export const getUpdatesCommunication = async (params?: {
  tab?: string;
  startDate?: string | null;
  endDate?: string | null;
  page: number;
  size: number;
}) => {
  const query = qs.stringify({
    ...params
  });
  const { data: res } = await axios.get(`/communication/updates?${query}`);
  return res;
};
