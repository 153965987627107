import React, { useCallback } from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';
import { HeaderPage } from 'app/components/elements/HeaderPage';
import { ArrowRight } from 'assets/icon';
import theme from 'styles/theme';
import { StyledParams } from 'types/common';
import { useNavigate } from 'react-router-dom';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { StorageServices } from 'services/storage';
import { Helmet } from 'react-helmet-async';

const Option = [
  {
    label: 'Change Password',
    link: '/setting/change-password'
  },
  {
    label: 'Profile Information',
    link: '/setting/profile-information'
  }
];
const storage = new StorageServices();
function SettingPage() {
  const navigate = useNavigate();
  const logout = useCallback(() => {
    storage.removeAccessToken();
    storage.removeRefreshToken();
    navigate('/login');
  }, [storage]);
  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - Setting</title>
      </Helmet>
      <HeaderPage title={'Setting'} />
      <WrapperContent>
        <TitlePage>Account</TitlePage>
        <ListStyled>
          {Option?.map((item, index) => {
            return (
              <WrapperItem isBottom={index !== 0} key={index} onClick={() => navigate(item.link)}>
                <TextItem>{item.label}</TextItem>
                <ArrowRight color={theme.colors.dark1} />
              </WrapperItem>
            );
          })}
        </ListStyled>
        <ButtonCustom onClick={logout} label={'Logout'} />
      </WrapperContent>
    </Stack>
  );
}

export default SettingPage;

const WrapperContent = styled(Stack)(() => ({
  padding: '30px 16px 16px 16px'
}));
const ListStyled = styled(Stack)(() => ({
  paddingTop: '10px',
  marginBottom: '30px'
}));

const TitlePage = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '20px'
}));

const WrapperItem = styled(Button)<{ isBottom?: boolean }>(
  ({ theme, isBottom }: StyledParams & { isBottom?: boolean }) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 42,
    border: `1px solid ${theme.colors.light3}`,
    borderWidth: isBottom ? `1px 0 1px 0` : `1px 0 0 0`,
    borderRadius: 0,
    textTransform: 'unset'
  })
);

const TextItem = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '17px'
}));
