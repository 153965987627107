import React, { useEffect, useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';
import { Button, Dialog, Stack, styled } from '@mui/material';
import { StyledParams } from 'types/common';
import { useFlash } from 'contexts/flash';
type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
};
export const CameraCustom = ({ isOpen, onClose }: DialogConfirmType) => {
  const { setFlash } = useFlash();
  const camera = useRef<CameraType>(null);
  const [image, setImage] = useState<string | null>(null);
  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        setFlash({ type: 'error', message: 'Không thể truy cập camera:' });
      }
    };

    if (isOpen) {
      checkCameraPermission();
    }
  }, [isOpen]);
  console.log(image);
  return (
    <DialogStyled open={isOpen} onClose={onClose}>
      <WrapperDialog>
        <Camera
          ref={camera}
          errorMessages={{
            noCameraAccessible:
              'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
              'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.'
          }}
        />
        <Button
          onClick={() => {
            if (camera.current) {
              setImage(camera.current.takePhoto() as string);
            }
          }}>
          Take photo
        </Button>
      </WrapperDialog>
    </DialogStyled>
  );
};
const DialogStyled = styled(Dialog)(({ theme }: StyledParams) => ({
  [theme.breakpoints.up('sm')]: {
    '.MuiDialog-paper': {
      maxWidth: '450px'
    }
  },
  borderRadius: '20px',
  '.MuiDialog-paper': {
    margin: 0,
    width: '100%',
    minHeight: '100dvh'
  }
}));
const WrapperDialog = styled(Stack)(() => ({
  flex: 1,
  padding: '15px 32px 32px 32px'
}));
