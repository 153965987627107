import { useLoading } from 'contexts/loading';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TowYardFormSchema } from 'utils/validate';
import { IconButton, Stack, Typography } from '@mui/material';
import theme from 'styles/theme';
import { AddIcon } from 'assets/icon';
import { InputText } from 'app/components/elements/InputText';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React, { memo, useEffect } from 'react';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { CheckBoxCustom } from '../../../components/elements/CheckBoxCustom';
type TTowYardForm = {
  towYards?: {
    value: string;
    checked: boolean;
    id?: number;
    user?: { role: string };
  }[];
};
type TowYardFormProps = {
  initTowYards?: {
    value: string;
    checked: boolean;
    id: number;
    user?: { role: string };
  }[];
  handleAddTowYards: (
    towYards: { value: string; checked: boolean; user?: { role: string } }[]
  ) => void;
  handleDeleteTowYards?: (idTowYard: number) => void;
  isAdminPage?: boolean;
};

const FormTowYard = ({
  handleAddTowYards,
  initTowYards,
  handleDeleteTowYards,
  isAdminPage
}: TowYardFormProps) => {
  const { loading } = useLoading();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm<TTowYardForm>({
    resolver: yupResolver(TowYardFormSchema),
    defaultValues: {
      towYards: initTowYards
    }
  });
  useEffect(() => {
    setValue('towYards', initTowYards);
  }, [initTowYards]);

  const {
    fields: fieldTowYards,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'towYards'
  });
  return (
    <Stack gap={2}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
        <Typography fontSize={'13px'} fontWeight={500} color={theme.colors.dark1}>
          Add New Field
        </Typography>
        <IconButton
          onClick={() =>
            append({ value: '', checked: false, user: { role: isAdminPage ? 'ADMIN' : 'USER' } })
          }
          sx={{ width: '40px', height: '40px' }}>
          <AddIcon color={'#000'} />
        </IconButton>
      </Stack>
      <Stack gap={1}>
        {fieldTowYards.map((fieldTowYard, index) => {
          return (
            <Stack key={fieldTowYard.id} direction="row" spacing={1} alignItems="center" mb={2}>
              <Controller
                name={`towYards.${index}.checked`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return <CheckBoxCustom onChange={onChange} checked={value} />;
                }}
              />
              <Controller
                name={`towYards.${index}.value`}
                control={control}
                render={({ field }) => {
                  return (
                    <InputText
                      {...field}
                      inputError={errors.towYards?.[index]?.value?.message || ''}
                      placeholder={'Enter Note'}
                      title={`Tow Yards ${index + 1}`}
                      inputProps={{
                        ...field,
                        disabled: !isAdminPage && fieldTowYard?.user?.role === 'ADMIN'
                      }}
                    />
                  );
                }}
              />
              {(isAdminPage || (!isAdminPage && fieldTowYard?.user?.role === 'USER')) && (
                <IconButton
                  onClick={() => {
                    const list: { id?: number; value: string }[] = watch('towYards') || [];
                    remove(index);
                    if (list[index]?.id) {
                      handleDeleteTowYards && handleDeleteTowYards(Number(list[index].id));
                    }
                  }}>
                  <RemoveCircleOutlineIcon color={'error'} />
                </IconButton>
              )}
            </Stack>
          );
        })}
      </Stack>
      <ButtonCustom
        loading={loading}
        sx={{ width: '130px', height: '46px' }}
        label={'Update'}
        onClick={handleSubmit((value) => {
          if (!value.towYards?.length) return;
          handleAddTowYards(value.towYards);
          reset({});
        })}
      />
    </Stack>
  );
};

export const TowYardFormMemo = memo(FormTowYard);
