import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFilesSchemaYup } from 'utils/validate';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FileFormProps } from './index';
import { TFilePhoto, User } from 'types/user';
import { StorageServices } from 'services/storage';
import { ROLE } from 'const/common';
import { optionArrearsOther } from 'const/option';
import { adminGetAllUser } from 'services/user';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { OptionAutocomplete } from '../../../../../components/elements/MultiSelectAutocomplete';

export type CreateFiles = {
  fileNumber: string;
  firstName: string;
  lastName: string;
  coBorrowerFirstName?: string;
  coBorrowerLastName?: string;
  vin: string;
  model: string;
  make: string;
  year: string;
  colour?: string;
  plate?: string;
  quickNote?: string;
  fileDocuments?: TFilePhoto[];
  photos?: TFilePhoto[];
  arrears: number;
  arrearsOther?: string;
  contractBalance: number;
  ppsaBaseRegistrationNumber: string;
  ppsaRegistrationDate: Date;
  contactDate: Date;
  auctionDeliveryLocationId: number;
  clientOptionsId: number;
  clientOptionsChildId?: number | null;
  userAssign?: OptionAutocomplete[];
};

export type ClientOption = {
  id: number;
  value: string;
  parentId?: number;
  children?: ClientOption[];
};

const storage = new StorageServices();

export const useInjection = ({ createForm, initFile }: FileFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<CreateFiles>({
    resolver: yupResolver(createFilesSchemaYup),
    defaultValues: { ...initFile, arrearsOther: optionArrearsOther[0].value }
  });
  const [optionsUser, setOptionsUser] = useState<OptionAutocomplete[]>([]);
  const [fileDocument, setFileDocument] = useState<{ file: File; url: string }[]>([]);
  const [filePhotos, setFilePhotos] = useState<{ file: File; url: string }[]>([]);
  const fileInputDocumentRef = useRef<HTMLInputElement | null>(null);
  const fileInputPhotosRef = useRef<HTMLInputElement | null>(null);
  const [idsLeadOpen, setIdsLeadOpen] = useState<number[]>([]);
  const [modalNewLead, setModalNewLead] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);

  const fileDocumentsInit = watch('fileDocuments');
  const photosInit = watch('photos');
  useEffect(() => {
    reset({ ...initFile, arrearsOther: optionArrearsOther[0].value });
    if (initFile?.leads?.at(-1)?.id) {
      setIdsLeadOpen([Number(initFile?.leads?.at(-1)?.id)]);
      const leadItem = document.querySelector(
        `#lead-detail-${initFile?.leads?.at(-1)?.id}`
      ) as HTMLDivElement;
      leadItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [initFile]);
  const watchArrears = watch('arrears');

  const getUserBailiff = useCallback(async () => {
    const data = await adminGetAllUser();
    const dataUserConvert = (data.users as User[]).map((item) => ({
      label: `${item.firstName} ${item.lastName} ( ${item.email} )`,
      value: item.id
    }));
    setOptionsUser(dataUserConvert);
  }, []);
  const { asyncCallback: asyncFetchBailiff } = useAsyncCallback(getUserBailiff, []);

  useEffect(() => {
    asyncFetchBailiff();
  }, []);
  useEffect(() => {
    if (initFile?.id && !initFile?.leads.length) {
      setModalNewLead(true);
    }
  }, [initFile]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    const fileConvert = newFiles.map((item) => ({
      file: item,
      url: URL.createObjectURL(new Blob([item], { type: item?.type }))
    }));
    setFileDocument((prevFiles) => [...prevFiles, ...fileConvert]);
  };
  const handleDeleteDocument = (indexToDelete: number) => {
    setFileDocument((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  const isAdmin = useMemo(() => {
    const role = storage.getRole();
    return role === ROLE.ADMIN || role === ROLE.ROOT_ADMIN;
  }, [storage.getRole()]);

  const isCompleted = useMemo(() => {
    return initFile?.status === 'COMPLETED';
  }, [initFile?.status]);

  const handleChangePhotos = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    const fileConvert = newFiles.map((item) => ({
      file: item,
      url: URL.createObjectURL(new Blob([item], { type: item?.type }))
    }));
    setFilePhotos((prevFiles) => [...prevFiles, ...fileConvert]);
  };
  const handleDeletePhotos = (indexToDelete: number) => {
    setFilePhotos((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  const onSubmitForm = useCallback(
    (value: CreateFiles) => {
      createForm({ value, fileDocument, filePhotos });
      setFileDocument([]);
      setFilePhotos([]);
    },
    [createForm, fileDocument, filePhotos]
  );

  return {
    handleSubmit,
    register,
    errors,
    fileDocument,
    handleDeleteDocument,
    fileInputDocumentRef,
    handleFileChange,
    handleChangePhotos,
    handleDeletePhotos,
    fileInputPhotosRef,
    filePhotos,
    onSubmitForm,
    fileDocumentsInit,
    photosInit,
    setValue,
    isAdmin,
    control,
    isCompleted,
    idsLeadOpen,
    setIdsLeadOpen,
    watchArrears,
    optionsUser,
    modalNewLead,
    setModalNewLead,
    openForm,
    setOpenForm
  };
};
