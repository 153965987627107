import { Lead, PreSeizure } from 'types/user';
import { FormPostSeizure } from '../RedemptionPage/PostSeizurePage/components/modules/FormPostSeizure/FormPostSeizure';
import { SeizureFile } from '../ControlPanelPage/ArchiveTab/SeizureDetails/hooks';

export const TAB = {
  UPDATES: 'updates',
  SEIZURE_REPORTS: 'seizureReports',
  OUT_OF_LEADS: 'outOfLeads'
} as const;

export const headerTab = [
  { label: 'Updates', param: TAB.UPDATES, value: 0 },
  { label: 'Seizure Reports', param: TAB.SEIZURE_REPORTS, value: 1 },
  { label: 'Out-of-Leads', param: TAB.OUT_OF_LEADS, value: 2 }
];
export type fileResCommunication = {
  id: number;
  fileNumber: number;
  createdAt: string;
  dispatchFiles?: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
    };
  }[];
  clientOption: {
    id: number;
    value: string;
  };
  leads: Partial<Lead>[];
  filePreSeizures?: PreSeizure[];
  filePostSeizures?: FormPostSeizure[];
  seizureReports?: SeizureReports[];
};
export type SeizureReports = {
  id: number;
  kilometers: number;
  hasKey: boolean;
  run: boolean;
  signedVSA: boolean;
  possibleRedemption: boolean;
  conditionReport: string;
  towingCompany: string;
  transportRequired: boolean;
  billsIncurred: boolean;
  otherAddress?: string;
  otherCustomerPOR?: string;
  addressLeadId: string;
  customersLeadId: string;
  removedToId: number;
  removedToOther?: string;
  createdAt?: string;
  addressLead: {
    address: string;
  };
  customersLead: {
    address: string;
  };
  removedToLocation: {
    value: string;
  };
  seizureReportPhotos: SeizureFile[];
  vsaDocuments: SeizureFile[];
};
export type dateCommunication = {
  startDate?: string | null;
  endDate?: string | null;
};
export type CommunicationUpdate = { total: number; updates: fileResCommunication[] };
export type Communication = {
  updates: CommunicationUpdate;
  seizureReports: CommunicationUpdate;
  outOfLeads: CommunicationUpdate;
};
export type DateCommunication = {
  updates: dateCommunication | null;
  seizureReports: dateCommunication | null;
  outOfLeads: dateCommunication | null;
};
