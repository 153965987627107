import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { PostSeizureForm } from '../components/modules/FormPostSeizure/FormPostSeizure';
import { useEditPostSeizure } from './hook';

export const EditPostSeizure = () => {
  const { onSubmitUpdatePostSeizure, handleOnBack, dataDetail, handleMoveToArchive } =
    useEditPostSeizure();
  return (
    <LayoutAdminTable title={'Post Seizure'} textButton={'Back'} onClickButton={handleOnBack}>
      <Helmet>
        <title>Surrey Bailiffs - Post Seizure</title>
      </Helmet>
      <PostSeizureForm
        initDataForm={dataDetail}
        onSubmitForm={onSubmitUpdatePostSeizure}
        handleMoveToArchive={handleMoveToArchive}
      />
    </LayoutAdminTable>
  );
};
