import { Stack } from '@mui/material';
import { ResetPasswordSuccess } from 'app/pages/ResetPasswordPage/ResetPasswordSuccessPage/ResetPasswordSuccess';
import { LayoutContent } from 'app/components/templates/LayoutContent';
export const ResetPasswordSuccessPage = () => {
  return (
    <Stack width={'100%'}>
      <LayoutContent
        title={'Forgot Password'}
        textFooter={''}
        textNavigation={'/'}
        textLink={''}
        textUnderlineNone
        children={<ResetPasswordSuccess />}
      />
    </Stack>
  );
};
