import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { Stack } from '@mui/material';
import React from 'react';
import { DataTable } from 'app/components/elements/TableCustom';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { useDispatch } from './hooks';
import { Helmet } from 'react-helmet-async';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import MultiSelectAutocomplete from 'app/components/elements/MultiSelectAutocomplete';
import { HEADER_FILE } from '../FilesPage';
import { ButtonCustom } from '../../../components/elements/ButtonCustom';

export const DispatchPage = () => {
  const {
    rows,
    totalRows,
    page,
    optionsSelected,
    onChangePage,
    handleClickRow,
    showModal,
    setOptionsSelected,
    optionsUser,
    onCloseModal,
    handleSaveModal
  } = useDispatch();

  return (
    <LayoutAdminTable title={'Dispatch'}>
      <Helmet>
        <title>Surrey Bailiffs - Dispatch</title>
      </Helmet>
      <DataTable
        data={rows}
        totalRows={totalRows}
        paginationModel={{
          page: page ? Number(page) - 1 : 1,
          pageSize: SIZE_PAGE_CONTROL_PANEL
        }}
        loading={false}
        handlePaginationModelChange={(newPaginationModel) =>
          onChangePage(newPaginationModel.page + 1)
        }
        handleRowClick={(params) => handleClickRow(params.row.id)}
        header={HEADER_FILE}
      />
      <DialogConfirm
        width={'650px'}
        isOpen={showModal}
        onSubmit={handleSaveModal}
        onClose={onCloseModal}
        textTitle={'Dispatch File'}>
        <Stack
          width={'550px'}
          minHeight={'120px'}
          mb={2}
          gap={2}
          flexDirection={'row'}
          alignItems={'center'}>
          <MultiSelectAutocomplete
            value={optionsSelected}
            label={'Assign to Bailiff'}
            onChange={(_, value) => {
              setOptionsSelected(value);
            }}
            options={optionsUser}
          />
          <ButtonCustom sx={{ width: 200 }} label={'Save'} onClick={handleSaveModal} fullWidth />
        </Stack>
      </DialogConfirm>
    </LayoutAdminTable>
  );
};
