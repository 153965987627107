import React, { memo } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import theme from 'styles/theme';
import FormControl from '@mui/material/FormControl';
export type OptionAutocomplete = {
  label: string;
  value: string;
};

type MultiSelectAutocompleteProps = {
  value?: OptionAutocomplete[];
  onChange: (event: React.SyntheticEvent, value: OptionAutocomplete[]) => void;
  options: OptionAutocomplete[];
  label?: string;
  title?: string;
};
const MultiSelectAutocomplete = ({
  value,
  onChange,
  options,
  label,
  title
}: MultiSelectAutocompleteProps) => {
  const isSelected = (option: OptionAutocomplete) =>
    value?.some((selectedOption) => selectedOption.value === option.value);
  return (
    <FormControl fullWidth>
      {title && (
        <FormLabel>
          <Typography fontSize={'13px'} fontWeight={500} mb={1} color={theme.colors.dark1}>
            {title}
          </Typography>
        </FormLabel>
      )}
      <Autocomplete
        multiple
        options={options}
        disableCloseOnSelect
        value={value || []}
        onChange={onChange}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option) => {
          const checked = isSelected(option);
          return (
            <li
              {...props}
              style={{
                backgroundColor: checked ? 'rgba(0, 123, 255, 0.1)' : 'inherit'
              }}>
              <Checkbox checked={checked} style={{ marginRight: 8 }} />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label || 'Select options'} />
        )}
      />
    </FormControl>
  );
};

export default memo(MultiSelectAutocomplete);
