import React from 'react';
import { Files } from 'services/leads';
import { StatusFiles } from 'const/option';
import { Stack, Typography } from '@mui/material';
type HeaderTableFile = {
  index: number;
  label: string;
  width: number;
  sort?: string;
};
export const headerTableFile = (customHeaders?: HeaderTableFile[]) => {
  const defaultHeaders = [
    { label: 'First Name', width: 80, sort: 'firstName' },
    { label: 'Last Name', width: 80, sort: 'lastName' },
    { label: 'Status', width: 120 },
    { label: 'Year', width: 100, sort: 'year' },
    { label: 'Make', width: 100, sort: 'make' },
    { label: 'Model', width: 100, sort: 'model' },
    { label: 'VIN', width: 100, sort: 'vin' },
    { label: 'Colour', width: 100, sort: 'colour' },
    { label: 'Plate', width: 100, sort: 'plate' }
  ];

  if (customHeaders?.length) {
    customHeaders.forEach(({ index, ...header }) => {
      const validIndex = index > defaultHeaders.length ? defaultHeaders.length : index - 1;
      defaultHeaders.splice(validIndex, 0, header);
    });
  }

  return defaultHeaders;
};
export const infoStickies = [
  {
    index: 0,
    leftSpace: 0
  },
  {
    index: 1,
    leftSpace: 80,
    isBorder: true
  }
];

export const renderDataMapFileTable = (rows: Files[]) => {
  return rows.map((item: Files) => {
    const status = item.status;
    const findStatus = StatusFiles.find((item) => item.value === status);
    return {
      firstName: {
        value: <Typography>{item.firstName}</Typography>
      },
      lastName: {
        value: <Typography>{item.lastName}</Typography>
      },
      status: {
        value: (
          <Stack
            style={{
              alignItems: 'flex-start',
              justifyContent: 'center',
              height: '100%'
            }}>
            <Stack
              style={{
                background: findStatus?.color || '#9747FF',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 10px',
                height: '20px',
                borderRadius: '5px'
              }}>
              <Typography
                style={{
                  color: '#fff',
                  fontSize: '10px',
                  fontWeight: 600
                }}>
                {[
                  findStatus?.value?.charAt(0)?.toUpperCase() ?? '',
                  findStatus?.value?.slice(1)?.toLocaleLowerCase() ?? ''
                ].join('')}
              </Typography>
            </Stack>
          </Stack>
        )
      },
      ...(item.leadTitle
        ? { leadTitle: { value: <Typography>{item.leadTitle}</Typography> } }
        : {}),
      year: {
        value: <Typography>{item.year}</Typography>
      },
      make: {
        value: <Typography>{item.make}</Typography>
      },
      model: {
        value: <Typography>{item.model}</Typography>
      },
      vin: {
        value: <Typography>{item.vin}</Typography>
      },
      colour: {
        value: <Typography>{item.colour}</Typography>
      },
      plate: {
        value: <Typography>{item.plate}</Typography>
      },
      ...(item.phoneNumber
        ? { phoneNumber: { value: <Typography>{item.phoneNumber}</Typography> } }
        : {}),
      ...(item.address ? { address: { value: <Typography>{item.address}</Typography> } } : {})
    };
  });
};
