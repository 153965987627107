import { FC, ReactNode, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { TextError } from 'app/components/elements/TextError';
import theme from 'styles/theme';

export type InputProps = {
  value: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | number) => void;
  onBlur?: () => void;
  autoComplete?: string;
};

export type RequiredInputProps = InputProps & { required: true };

export type InputTextProps = {
  sizeTitle?: string;
  title?: string;
  inputProps: InputProps | RequiredInputProps | OutlinedInputProps;
  inputError?: string;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  errorWithoutText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement | null> | null;
  maxLength?: number;
  hasInputSearch?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

/**
 * @returns Element InputText
 */
export const InputText: FC<InputTextProps> = ({
  sizeTitle,
  title,
  inputProps,
  inputError,
  placeholder,
  startAdornment,
  endAdornment,
  errorWithoutText = false,
  inputRef,
  hasInputSearch,
  onChange
}) => {
  return (
    <FormControl fullWidth error={!!inputError}>
      {title && (
        <FormLabel>
          <Typography
            fontSize={sizeTitle || '13px'}
            fontWeight={500}
            mb={1}
            color={theme.colors.dark1}>
            {title}
          </Typography>
        </FormLabel>
      )}
      <InputStyled
        {...inputProps}
        search={hasInputSearch}
        autoComplete={inputProps?.autoComplete || 'off'}
        endAdornment={endAdornment}
        onChange={onChange}
        inputProps={{
          ref: inputRef
        }}
        placeholder={placeholder}
        startAdornment={startAdornment}
      />
      {inputError && !errorWithoutText && (
        <FormHelperText error={!!inputError} sx={{ ml: 0, maxWidth: '100%' }}>
          <TextError errorText={inputError} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

const InputStyled = styled(OutlinedInput)(({ search }: { search?: boolean }) => ({
  minHeight: search ? 36 : 56,
  paddingRight: 8,
  paddingLeft: 0,
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    padding: search ? '0px 10px' : '16px 12px',
    fontWeight: search ? 500 : 400,
    fontSize: search ? 14 : 16
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    borderRadius: 10
  }
}));
