import { Stack } from '@mui/material';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import {
  FormResetPassword,
  FormResetPasswordType
} from 'app/pages/ResetPasswordPage/components/FormResetPassword';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const handleSubmit = async (value: FormResetPasswordType) => {
    const code = searchParam.get('code');
    const email = searchParam.get('email');
    await axios.post('/auth/reset-password', { password: value.password, code, email });
    navigate('/reset-password/success');
  };

  const { asyncCallback: asyncHandSubmit } = useAsyncCallback(handleSubmit, []);

  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Surrey Bailiffs - Forgot Password</title>
      </Helmet>
      <LayoutContent
        title={'Forgot Password'}
        textFooter={''}
        textNavigation={'/'}
        textLink={''}
        textUnderlineNone
        children={<FormResetPassword onSubmit={asyncHandSubmit} />}
      />
    </Stack>
  );
};
