import { useFlash } from 'contexts/flash';
import { useCallback, useEffect, useState } from 'react';
import {
  getDetailPostSeizure,
  updateDetailPostSeizure,
  updateStatusTransition
} from 'services/files';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { FormPostSeizure } from '../components/modules/FormPostSeizure/FormPostSeizure';
import { isNull, omitBy } from 'lodash';
import axios from 'axios';

export const useEditPostSeizure = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState<FormPostSeizure>();

  const fetchDetail = useCallback(async () => {
    const { filePostSeizure } = await getDetailPostSeizure(Number(id));
    const dataConvert = omitBy(filePostSeizure, isNull) as FormPostSeizure;
    setDataDetail(dataConvert);
  }, [id]);

  const { asyncCallback: asyncFetchDetail } = useAsyncCallback(fetchDetail, []);

  useEffect(() => {
    asyncFetchDetail();
  }, [id]);

  const onSubmitUpdatePostSeizure = useCallback(
    async (formData: FormPostSeizure) => {
      const formData2 = new FormData();
      let filePhotosIds: number[] = [];
      const filePhotos = formData.postSeizuresDocuments?.filter((item) => item.file);
      if (filePhotos?.length) {
        for (let x = 0; x < filePhotos.length; x++) {
          formData2.append('files', filePhotos[x]!.file!, filePhotos[x]!.file!.name);
        }
        const { data: resListIdFile } = await axios.post(
          '/storages/post_seizures_document',
          formData2,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
        filePhotosIds = resListIdFile.files;
        formData2.delete('files');
      }
      await updateDetailPostSeizure(Number(id), { ...formData, ...{ documentIds: filePhotosIds } });
      setFlash({ type: 'success', message: 'Update post seizure successful' });
      navigate(`/admin/redemptions`);
    },
    [id]
  );

  const handleMoveToArchive = useCallback(async () => {
    await updateStatusTransition(Number(dataDetail?.fileId), 'CLOSE_REDEMPTION');
    setFlash({ type: 'success', message: 'Move to archive successful' });
    navigate('/admin/control-panel?tab=archive');
  }, [dataDetail?.fileId]);

  return {
    onSubmitUpdatePostSeizure,
    handleOnBack: () => navigate(`/admin/redemptions`),
    dataDetail,
    handleMoveToArchive
  };
};
