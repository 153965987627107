import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminEditBailiffSchema } from 'utils/validate';
import { useNavigate, useParams } from 'react-router-dom';
import { adminGetDetailUser, adminUpdateUser } from 'services/user';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { User, UserDocuments } from 'types/user';
import { useFlash } from 'contexts/flash';
import axios from 'axios';
import { isEqual } from 'lodash';
import { useJsApiLoader } from '@react-google-maps/api';
export type EditBailiffForm = {
  firstName: string;
  lastName: string;
  address: string;
  isActive: string;
  documentFilesOld?: UserDocuments[];
};
export const useInjection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setFlash } = useFlash();
  const inputRef = useRef(null);

  const [initForm, setInitForm] = useState<EditBailiffForm>();
  const fileInputDocumentRef = useRef<HTMLInputElement | null>(null);
  const [fileDocument, setFileDocument] = useState<{ file: File; url: string }[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!
  });

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm<EditBailiffForm>({ resolver: yupResolver(adminEditBailiffSchema) });
  const filesOld = watch('documentFilesOld');
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    const fileConvert = newFiles.map((item) => ({
      file: item,
      url: URL.createObjectURL(new Blob([item], { type: item?.type }))
    }));
    setFileDocument((prevFiles) => [...prevFiles, ...fileConvert]);
  };

  const handleDeleteDocument = (indexToDelete: number) => {
    setFileDocument((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };
  const handleDeleteDocumentOld = (id: number) => {
    const newFileOld = filesOld?.filter((file) => file.id !== id);
    setValue('documentFilesOld', newFileOld);
  };
  const handleFetchInit = useCallback(async () => {
    const data = await adminGetDetailUser(Number(id));
    const user = data.user as User;
    setInitForm({
      firstName: user.firstName,
      lastName: user.lastName,
      address: user.address,
      isActive: user.isActive ? 'active' : 'pending',
      documentFilesOld: user?.userDocuments
    });
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      address: user.address,
      isActive: user.isActive ? 'active' : 'pending',
      documentFilesOld: user?.userDocuments
    });
  }, [reset, id]);

  const { asyncCallback: asyncFetchInit } = useAsyncCallback(handleFetchInit, []);

  useEffect(() => {
    asyncFetchInit();
  }, [id]);

  const updateUser = useCallback(
    async (value: EditBailiffForm) => {
      const formData = new FormData();
      let arrayIdFile: number[] = [];
      if (fileDocument.length) {
        for (let x = 0; x < fileDocument.length; x++) {
          formData.append('files', fileDocument[x].file, fileDocument[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/bailiffs-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        arrayIdFile = resListIdFile.files;
      }
      const arrayIdOld = value?.documentFilesOld?.map((doc) => doc.id);
      const arrayIdDoc = arrayIdOld ? [...arrayIdFile, ...arrayIdOld] : arrayIdFile;
      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        address: value.address,
        isActive: value.isActive === 'active',
        documentIds: arrayIdDoc
      };
      if (
        isEqual(value.firstName, initForm?.firstName) &&
        isEqual(value.lastName, initForm?.lastName) &&
        isEqual(value.address, initForm?.address) &&
        isEqual(value.isActive, initForm?.isActive) &&
        isEqual(value.documentFilesOld, initForm?.documentFilesOld) &&
        fileDocument.length === 0
      ) {
        return;
      }
      await adminUpdateUser(Number(id), payload);
      setFlash({ type: 'success', message: 'Update bailiff successful' });
      navigate('/admin/control-panel?tab=bailiffs');
    },
    [fileDocument, id, initForm]
  );

  const { asyncCallback: asyncUpdateUser } = useAsyncCallback(updateUser, []);

  return {
    control,
    handleSubmit,
    register,
    errors,
    asyncUpdateUser,
    setValue,
    fileInputDocumentRef,
    handleFileChange,
    fileDocument,
    handleDeleteDocument,
    handleDeleteDocumentOld,
    filesOld,
    isLoaded,
    inputRef
  };
};
