import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { StyledParams } from 'types/common';
import { Lead } from 'types/user';
import { partition } from 'lodash';
import { renderTitleLead } from 'utils/renderTitleLead';
type SummaryLogProps = {
  leads: Partial<Lead>[];
};
export const SummaryLog = ({ leads }: SummaryLogProps) => {
  return (
    <WrapperNoBorder>
      <WrapperHeaderBox>
        <TitleLead>Notes</TitleLead>
      </WrapperHeaderBox>
      <WrapperContentNote>
        <TextNote>Summary Log</TextNote>
        <Stack gap={1.5}>
          {leads?.map((item) => {
            const [noteAdmin, noteBailiff] = partition(
              item.notes,
              (note) => note.user.role === 'ADMIN'
            );
            if (!item.notes?.length) return;
            return (
              <WrapperNoteContent>
                <Typography sx={{ fontWeight: 700 }}>{renderTitleLead(item)}</Typography>
                {noteBailiff?.length !== 0 && (
                  <Stack gap={1}>
                    {noteBailiff?.map((note, index) => {
                      return (
                        <Stack key={index} gap={0.5} flexDirection={'row'}>
                          <TextDate>
                            {`${dayjs(note.updatedAt).format('M/D/YYYY, h:mm A')}
                        -`}
                          </TextDate>
                          <Stack
                            width={'100%'}
                            flexDirection={'row'}
                            gap={0.5}
                            alignItems={'flex-start'}
                            justifyContent={'space-between'}>
                            <Stack width={'100%'} gap={0.7}>
                              <TextNote>
                                {note.content} -
                                <span style={{ color: '#000', fontWeight: 500 }}>
                                  {note.user.firstName} {note.user.lastName}
                                </span>
                              </TextNote>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
                {noteAdmin?.length !== 0 && (
                  <Stack gap={1}>
                    <TextNote>Admin Note:</TextNote>
                    {noteAdmin?.map((item, index) => {
                      return (
                        <Stack gap={0.5} flexDirection={'row'}>
                          <TextDate>
                            {`${dayjs(item.updatedAt).format('M/D/YYYY, h:mm A')}
                        -`}
                          </TextDate>
                          <TextNoteAdmin key={index}>{item?.content}</TextNoteAdmin>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </WrapperNoteContent>
            );
          })}
        </Stack>
      </WrapperContentNote>
    </WrapperNoBorder>
  );
};
const WrapperNoteContent = styled(Stack)(() => ({
  gap: '10px'
}));
const WrapperContentNote = styled(Stack)(({ theme }: StyledParams) => ({
  background: theme.colors.blue,
  borderRadius: '5px',
  width: '100%',
  padding: '8px 10px',
  gap: '10px'
}));
const TextNote = styled(Typography)(() => ({
  color: '#565656',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'pre-line'
}));

const TextDate = styled(Typography)(() => ({
  color: '#ADADAD',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '100%',
  minWidth: '145px'
}));
const TextNoteAdmin = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'pre-line'
}));
const WrapperNoBorder = styled(Stack)(() => ({
  paddingBottom: '16px'
}));
const WrapperHeaderBox = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '10px'
}));
const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '100%'
}));
