import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import OutlinedInput from '@mui/material/OutlinedInput';
import { StorageServices } from 'services/storage';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ROLE } from 'const/common';

type SideMenu = {
  label: string;
  link: string;
  iconActive?: string;
  iconInactive?: string;
  hidden?: boolean;
  paths?: string[];
  items?: SideMenu[];
};

type LayoutProps = {
  children: ReactNode;
};

const storage = new StorageServices();

export const LayoutAdmin = ({ children }: LayoutProps) => {
  const role = storage.getRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading: callbackLoading } = useLoading();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const refScroll = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (refScroll.current) {
      refScroll.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  const MENU: SideMenu[] = useMemo(() => {
    return [
      {
        label: 'File Overview',
        link: '/admin/file-overview'
      },
      {
        label: 'Performance Overview',
        link: '/admin/performance-overview'
      },
      {
        label: 'Communication',
        link: '/admin/communication'
      },
      {
        label: 'New File Set Up',
        link: '/admin/set-up-file'
      },
      {
        label: 'Dispatch',
        link: '/admin/dispatch'
      },
      {
        label: 'Redemptions',
        link: '/admin/redemptions'
      },
      {
        label: 'Control Panel',
        link: '/admin/control-panel'
      }
    ];
  }, []);

  const handleLogout = useCallback(() => {
    storage.removeAccessToken();
    storage.removeRefreshToken();
    storage.removeRole();
    navigate('/admin/login');
  }, [storage]);

  useEffect(() => {
    if (role === ROLE.USER) {
      navigate('/forbidden');
    }
  }, [role]);
  return (
    <Stack
      minHeight={'100dvh'}
      flexDirection={isMobile ? 'column' : 'row'}
      width={'100%'}
      position={'relative'}>
      {callbackLoading && <LinearLoading color="success" />}
      <WrapperNavbar>
        <img
          style={{ cursor: 'pointer' }}
          alt={'img'}
          width={143}
          src={'/images/logo.png'}
          onClick={() => navigate('/admin')}
        />
        <Wrapper>
          {MENU.map((item, index) => {
            if (item.hidden) return;
            return (
              <NavLink to={item.link} key={index}>
                <WrapperItem className={'button-navbar'} gap={'14px'}>
                  <TextStyled className={'text-menu'}>{item.label}</TextStyled>
                </WrapperItem>
              </NavLink>
            );
          })}
        </Wrapper>
      </WrapperNavbar>
      <Stack flex={5} sx={{ background: '#F3F5FA' }} width={'100%'} alignItems={'center'}>
        <WrapperHeader>
          <InputStyled
            startAdornment={
              <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
                <img alt={'user'} src={'/icons/search.svg'} />
              </InputAdornment>
            }
            placeholder={'Search'}
          />
          <WrapperRightHeader>
            <WrapperAvatar>
              <AccountCircleIcon sx={{ width: '44px', height: ' 44px' }} />
            </WrapperAvatar>
            <WrapperName>
              <TextStyled>Name User</TextStyled>
              <TextRoleStyled>{storage.getRole()?.replace('_', ' ')}</TextRoleStyled>
            </WrapperName>
            <WrapperIcon onClick={() => setOpenMenu(true)}>
              <img alt={'icon'} src={'/icons/chevron_down.svg'} />
            </WrapperIcon>
            <MenuStyled
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem style={{ gap: '10px' }} onClick={handleLogout}>
                <LogoutIcon />
                <Typography style={{ fontWeight: 500 }}>Logout</Typography>
              </MenuItem>
            </MenuStyled>
          </WrapperRightHeader>
        </WrapperHeader>
        <ScrollStyled ref={refScroll}>{children}</ScrollStyled>
      </Stack>
    </Stack>
  );
};

const MenuStyled = styled(Menu)(() => ({
  '.MuiPaper-root': {
    minWidth: 130,
    top: '53px !important',
    right: '32px !important',
    bottom: 'unset !important',
    left: 'unset !important'
  }
}));

const Wrapper = styled(Stack)(() => ({
  width: '100%',
  marginTop: '20px',
  '.active': {
    '.button-navbar': {
      background: `#22374A !important`,
      '.text-menu': {
        color: '#fff'
      }
    }
  }
}));

const LinearLoading = styled(LinearProgress)(() => ({
  position: 'fixed',
  width: '100vw',
  zIndex: 1000
}));
const WrapperNavbar = styled(Stack)(() => ({
  flex: 1,
  padding: '40px 0',
  maxWidth: 240,
  background: '#F4F5F6',
  alignItems: 'center',
  borderRight: '1px solid #BABAB6'
}));
const WrapperItem = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingLeft: '48px',
  height: '50px'
}));
const WrapperName = styled(Stack)(() => ({
  gap: '4px'
}));
const TextStyled = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '700',
  color: '#202224'
}));
const TextRoleStyled = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  color: '#565656'
}));
const WrapperHeader = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '70px',
  background: '#F4F5F6',
  borderBottom: '1px solid #BABAB6',
  flexDirection: 'row',
  padding: '0 30px 0 40px'
}));
const ScrollStyled = styled(Stack)(() => ({
  flexGrow: 1,
  overflowY: 'auto',
  background: '#fff',
  width: '100%',
  height: 'calc(100dvh - 70px)'
}));
const InputStyled = styled(OutlinedInput)(() => ({
  minHeight: '38px',
  width: '388px',
  height: '38px',
  backgroundColor: '#fff',
  borderRadius: 20,
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    padding: '8px 8px',
    borderRadius: 20
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    boxShadow: 'inset 0 5px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: 20
  }
}));
const WrapperRightHeader = styled(Stack)(() => ({
  gap: 20,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row'
}));
const WrapperAvatar = styled(Box)(() => ({
  height: 44,
  width: 44,
  borderRadius: 22
}));
const WrapperIcon = styled(Button)(() => ({
  height: 18,
  width: 18,
  borderRadius: 9,
  border: '0.2px solid #5C5C5C',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 0
}));
