import React, { useCallback } from 'react';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { TabsCustom } from 'app/components/elements/TabsCutom';
import { TAB, useInjection } from './hook';
import { GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import theme from 'styles/theme';
import { StatusArchive } from 'const/option';
import { capitalizeFirstLetter } from 'const/common';
import { ActiveTab } from './ArchiveTab';
import { AuctionDeliveryLocationTab } from './AuctionDeliveryLocationTab';
import { ClientOptionTab } from './ClientOptionTab';
import { Helmet } from 'react-helmet-async';

const HEADER_ADMIN: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 40 },
  { field: 'firstName', headerName: 'First Name', width: 130 },
  { field: 'lastName', headerName: 'Last Name', width: 130 },
  { field: 'address', headerName: 'Address', width: 250 },
  {
    field: 'email',
    headerName: 'Email',
    width: 250
  }
];
const HEADER: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 40 },
  { field: 'firstName', headerName: 'First Name', width: 130 },
  { field: 'lastName', headerName: 'Last Name', width: 130 },
  { field: 'address', headerName: 'Address', width: 250 },
  {
    field: 'email',
    headerName: 'Email',
    width: 250
  },
  {
    field: 'isActive',
    headerName: 'Account Status',
    width: 130,
    renderCell: (params: GridRenderCellParams) => {
      const isApproved = params?.value;
      return (
        <Stack
          style={{
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%'
          }}>
          <Stack
            style={{
              background: isApproved ? theme.colors.success : theme.colors.pending,
              alignItems: 'center',
              justifyContent: 'center',
              width: '90px',
              height: '36px',
              borderRadius: '20px'
            }}>
            {isApproved ? 'Active' : 'Inactive'}
          </Stack>
        </Stack>
      );
    }
  }
];
const HEADER_ARCHIVE: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.row.status;
      const findStatus = StatusArchive.find((item) => item.value === status);
      return (
        <Stack
          style={{
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%'
          }}>
          <Stack
            style={{
              background: findStatus?.color || '#686E7D',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 10px',
              height: '20px',
              borderRadius: '5px'
            }}>
            <Typography
              style={{
                color: '#fff',
                fontSize: '10px',
                fontWeight: 600
              }}>
              {capitalizeFirstLetter(findStatus?.value.replaceAll('_', ' ') || 'Close Seized')}
            </Typography>
          </Stack>
        </Stack>
      );
    }
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 130,
    valueGetter: (_, row) => row.file?.firstName
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 130,
    valueGetter: (_, row) => row.file?.lastName
  },
  { field: 'year', headerName: 'Year', width: 130, valueGetter: (_, row) => row.file.year },
  { field: 'make', headerName: 'Make', width: 130, valueGetter: (_, row) => row.file.make },
  { field: 'model', headerName: 'Model', width: 130, valueGetter: (_, row) => row.file.model },
  {
    field: 'vin',
    headerName: 'VIN',
    width: 130,
    valueGetter: (_, row) => row?.file?.vin
  },
  {
    field: 'fileNumber',
    headerName: 'File Number',
    width: 130,
    valueGetter: (_, row) => row.file.fileNumber
  },
  {
    field: 'file',
    headerName: 'Client',
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      const value = params?.value?.clientOption?.value;
      return (
        <Stack
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%'
          }}
          gap={1}>
          <Typography
            style={{
              color: '#22374A',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}>
            {value}
          </Typography>
        </Stack>
      );
    }
  },
  { field: 'plate', headerName: 'Plate', width: 130, valueGetter: (_, row) => row.file.plate }
];

function ControlPanelPage() {
  const {
    tab,
    handleChangeTab,
    renderButtonHeader,
    onClickButtonHeader,
    tabHeader,
    asyncOnSubmitAuctionDelivery,
    asyncOnSubmitClient,
    handlePaginationModelChange,
    handleRowClick,
    handleRowArchiveClick,
    loading,
    optionAuctionDelivery,
    optionClient,
    pages,
    rowStates,
    searchParams
  } = useInjection();

  const renderTab = useCallback(() => {
    const tab = (searchParams.get('tab') as keyof typeof TAB) || TAB.BAILIFFS;
    const { rows, total } = rowStates[tab] || {};
    if (tab === TAB.BAILIFFS)
      return (
        <ActiveTab
          header={HEADER}
          page={pages[tab]}
          totalRows={total}
          handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
            handlePaginationModelChange(newPaginationModel, tab)
          }
          handleRowClick={handleRowClick}
          rows={rows}
          loading={loading}
        />
      );
    if (tab === TAB.ADMIN)
      return (
        <ActiveTab
          header={HEADER_ADMIN}
          page={pages[tab]}
          totalRows={total}
          handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
            handlePaginationModelChange(newPaginationModel, tab)
          }
          handleRowClick={handleRowClick}
          rows={rows}
          loading={loading}
        />
      );
    if (tab === TAB.ARCHIVE)
      return (
        <ActiveTab
          header={HEADER_ARCHIVE}
          page={pages[tab]}
          totalRows={total}
          handlePaginationModelChange={(newPaginationModel: GridPaginationModel) =>
            handlePaginationModelChange(newPaginationModel, tab)
          }
          handleRowClick={handleRowArchiveClick}
          rows={rows}
          loading={loading}
        />
      );
    if (tab === TAB.AUCTION)
      return (
        <AuctionDeliveryLocationTab
          optionInit={optionAuctionDelivery}
          onSubmitForm={asyncOnSubmitAuctionDelivery}
        />
      );
    if (tab === TAB.CLIENT)
      return <ClientOptionTab onSubmitForm={asyncOnSubmitClient} optionClientInit={optionClient} />;
    return <> </>;
  }, [
    asyncOnSubmitAuctionDelivery,
    asyncOnSubmitClient,
    handlePaginationModelChange,
    handleRowClick,
    loading,
    optionAuctionDelivery,
    optionClient,
    pages,
    rowStates,
    searchParams
  ]);

  return (
    <LayoutAdminTable
      title={'Control Panel'}
      textButton={renderButtonHeader()}
      onClickButton={onClickButtonHeader}>
      <Helmet>
        <title>Surrey Bailiffs - Control Panel</title>
      </Helmet>
      <TabsCustom
        tab={tab}
        contentTab={renderTab()}
        headerTab={tabHeader}
        onChangeTab={handleChangeTab}
      />
    </LayoutAdminTable>
  );
}

export default ControlPanelPage;
