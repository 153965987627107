import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  Stack
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { Fragment, ReactNode } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type PrimaryTableHeader = {
  label: ReactNode;
  width?: number | string;
  isHide?: boolean;
  sort?: string;
  onClick?: (index: number) => void;
};
export type TableDataType = Record<string, CellDataType>;
export type CellDataType = {
  value: ReactNode;
  isHide?: boolean;
};
type TableInfiniteScrollProps = {
  data: TableDataType[];
  handleFetchMore: () => void;
  headers: PrimaryTableHeader[];
  hasMore: boolean;
  handleChooseRow: (indexRow: number) => void;
  idElementScroll?: string;
  infoStickies?: { index: number; leftSpace: number; isBorder?: boolean }[];
  styledTableRow?: React.CSSProperties;
  onSortHeader?: (sortField: string) => void;
};
export const TableInfiniteScroll = ({
  data,
  handleFetchMore,
  headers,
  hasMore,
  handleChooseRow,
  idElementScroll,
  infoStickies,
  styledTableRow,
  onSortHeader
}: TableInfiniteScrollProps) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={handleFetchMore}
      hasMore={hasMore}
      scrollableTarget={idElementScroll}
      loader={
        <Box p={1}>
          <CircularProgress />
        </Box>
      }>
      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {headers.map((header: PrimaryTableHeader, index: number) => {
                const infoSticky = infoStickies?.find((item) => item.index === index);
                return (
                  <Fragment key={index}>
                    {!header.isHide && (
                      <Fragment>
                        {infoSticky ? (
                          <TableCellHeaderStyled
                            onClick={() => {
                              if (!header.sort) return;
                              onSortHeader && onSortHeader(header.sort);
                            }}
                            sx={{
                              position: 'sticky',
                              backgroundColor: '#fff',
                              left: infoSticky.leftSpace,
                              cursor: 'pointer'
                            }}
                            width={header.width}
                            key={index}>
                            <Stack
                              style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: '10px',
                                cursor: header.sort ? 'pointer' : 'unset'
                              }}>
                              {header.label}
                              {header.sort && (
                                <Stack>
                                  <ArrowDropUpIcon sx={{ mb: -0.5 }} fontSize={'small'} />
                                  <ArrowDropDownIcon sx={{ mt: -0.5 }} fontSize={'small'} />
                                </Stack>
                              )}
                            </Stack>
                          </TableCellHeaderStyled>
                        ) : (
                          <TableCellHeaderStyled
                            width={header.width}
                            key={index}
                            onClick={() => {
                              if (!header.sort) return;
                              onSortHeader && onSortHeader(header.sort);
                            }}>
                            <Stack
                              style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: '10px',
                                cursor: header.sort ? 'pointer' : 'unset'
                              }}>
                              {header.label}
                              {header.sort && (
                                <Stack>
                                  <ArrowDropUpIcon sx={{ mb: -0.5 }} fontSize={'small'} />
                                  <ArrowDropDownIcon sx={{ mt: -0.5 }} fontSize={'small'} />
                                </Stack>
                              )}
                            </Stack>
                          </TableCellHeaderStyled>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: TableDataType, rowIndex: number) => (
              <TableRowStyled
                sx={{
                  backgroundColor: rowIndex % 2 === 0 ? '#F1F1E8' : '#fff'
                }}
                style={styledTableRow}
                key={rowIndex}
                onClick={() => handleChooseRow(rowIndex)}>
                {Object.values(row).map((record: CellDataType, colIndex: number) => {
                  const infoSticky = infoStickies?.find((item) => item.index === colIndex);
                  return (
                    <Fragment key={colIndex}>
                      {!record.isHide && (
                        <Fragment>
                          {infoSticky ? (
                            <TableCellStyled
                              sx={{
                                position: 'sticky',
                                backgroundColor: '#E3EEF9',
                                left: infoSticky.leftSpace
                              }}>
                              {record.value}
                            </TableCellStyled>
                          ) : (
                            <TableCellStyled>{record.value}</TableCellStyled>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  );
                })}
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

const TableRowStyled = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  }
}));
const TableCellStyled = styled(TableCell)(() => ({
  padding: '14px 8px'
}));
const TableCellHeaderStyled = styled(TableCell)(() => ({
  padding: '14px 8px',
  '&:hover': {
    background: 'rgb(245, 245, 245) '
  }
}));
