import React from 'react';
import { Stack, styled, Typography, Box, IconButton, InputAdornment } from '@mui/material';
import { StyledParams } from 'types/common';
import { SubmitButton } from 'app/components/elements/SubmitButton';
import { InputText } from 'app/components/elements/InputText';
import { useHomePage } from 'app/pages/HomePage/hooks';
import { StatusFileFilter, widthScreen } from 'const/common';
import FilterModal from 'app/components/elements/ModalFilter';
import { Helmet } from 'react-helmet-async';
import { TableInfiniteScroll } from 'app/components/elements/TableInfiniteScroll';
import { headerTableFile, infoStickies, renderDataMapFileTable } from 'const/table';
import { ButtonFilter } from 'app/components/elements/ButtonFilter';
function HomePage() {
  const {
    open,
    search,
    searchInput,
    selectedStatus,
    rows,
    setSearch,
    handleOpen,
    handleClose,
    handleCancel,
    handleInputSearch,
    handleStatusChange,
    handleClickRow,
    onFilter,
    handleResetFilter,
    formValue,
    fetchMoreData,
    hasMore,
    onSortHeader
  } = useHomePage();

  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - Home</title>
      </Helmet>
      <Box mx={2}>
        <TitleStyled>All Files</TitleStyled>
        <Stack direction={'row'} mt={2.5} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={1.25} flexWrap={'wrap'}>
            <ButtonCustom
              defaultValue={selectedStatus}
              active={selectedStatus === StatusFileFilter.OPEN_FILE}
              onClick={() => handleStatusChange(StatusFileFilter.OPEN_FILE)}>
              Active Files
            </ButtonCustom>
            <ButtonCustom
              defaultValue={selectedStatus}
              active={selectedStatus === StatusFileFilter.ON_HOLD}
              onClick={() => handleStatusChange(StatusFileFilter.ON_HOLD)}>
              On Hold
            </ButtonCustom>
          </Stack>
          <Stack direction={'row'} gap={1.25}>
            <ButtonFilter
              isFiltering={
                !(
                  !formValue.doorKnock &&
                  !formValue.earlyMorning &&
                  !formValue.status.length &&
                  !formValue.types.length
                )
              }
              onClick={handleOpen}
            />
            {search ? (
              <IconButtonStyled onClick={handleCancel}>
                <img src={'/icons/icon_cancel_bold.svg'} alt={'icon-cancel'} />
              </IconButtonStyled>
            ) : (
              <IconButtonStyled onClick={() => setSearch(!search)}>
                <img src={'/icons/icon_search.svg'} alt={'icon-search'} />
              </IconButtonStyled>
            )}
          </Stack>
        </Stack>
        {search && (
          <Box mt={2.5} mb={2}>
            <InputText
              placeholder={'Enter your search'}
              inputProps={{
                defaultValue: searchInput
              }}
              hasInputSearch
              onChange={handleInputSearch}
              startAdornment={
                <InputAdornment sx={{ marginLeft: 1.25 }} position="end">
                  <img alt={'search'} src={'/icons/icon_search.svg'} />
                </InputAdornment>
              }
            />
          </Box>
        )}
      </Box>
      <Stack maxWidth={widthScreen < 500 ? widthScreen : '450px'} mt={search ? 0 : 2.5}>
        <TableInfiniteScroll
          onSortHeader={onSortHeader}
          handleChooseRow={handleClickRow}
          data={renderDataMapFileTable(rows)}
          headers={headerTableFile()}
          handleFetchMore={fetchMoreData}
          hasMore={hasMore}
          infoStickies={infoStickies}
          idElementScroll={'scrollableDiv'}
        />
      </Stack>
      <FilterModal
        isShowChecked
        isShowStatus
        value={formValue}
        open={open}
        onClose={handleClose}
        onReset={handleResetFilter}
        onSubmit={onFilter}
      />
    </Stack>
  );
}

export default HomePage;

const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '25px',
  marginTop: theme.spacing(4)
}));

const IconButtonStyled = styled(IconButton)(() => ({
  border: '1px solid #22374A',
  borderRadius: 5,
  height: 36,
  width: 36,
  '&:hover': {
    backgroundColor: 'white',
    opacity: 0.8
  }
}));

const ButtonCustom = styled(SubmitButton)(({ active }: { active?: boolean }) => ({
  borderRadius: 5,
  textTransform: 'none',
  paddingLeft: 10,
  paddingRight: 10,
  height: 36,
  background: active ? '#22374A' : 'white',
  color: active ? 'white' : '#22374A',
  border: active ? 'none' : '1px solid #E9EAEB',
  '&:hover': {
    backgroundColor: active ? '#22374A' : 'white',
    opacity: 0.8
  }
}));
