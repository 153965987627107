import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
import { ROLE } from 'const/common';
const storage = new StorageServices();
/**
 *
 * RequireAuth Component check auth
 *
 * */
export function RequireAuth({ children }: { children: ReactElement }) {
  const hasToken = storage.getAccessToken();
  const role = storage.getRole();

  if (!hasToken || role === ROLE.ADMIN || role === ROLE.ROOT_ADMIN) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={'/login'} />;
  }

  return children;
}

/**
 *
 * RequireAdminAuth Component check auth
 *
 * */
export function RequireAdminAuth({ children }: { children: ReactElement }) {
  const hasAdminToken = storage.getAccessToken();
  const role = storage.getRole();

  if (!hasAdminToken || role === ROLE.USER) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={'/admin/login'} />;
  }

  return children;
}
