import { LoginPage } from 'app/pages/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LayoutAdmin } from 'app/components/templates/LayoutAdmin';
import { RequireAdminAuth, RequireAuth } from 'app/components/modules/AuthRouter';
import HomePage from 'app/pages/HomePage';
import { LayoutApp } from 'app/components/templates/LayoutApp';
import { LayoutButtonNavigation } from 'app/components/templates/LayoutButtonNavigation';
import MapPage from 'app/pages/MapPage';
import CallsPage from 'app/pages/CallsPage';
import SettingPage from 'app/pages/SettingPage';
import LeadsPage from 'app/pages/LeadsPage';
import { SignUpPage } from 'app/pages/SignUpPage';
import { ForgotPasswordPage } from 'app/pages/ForgotPasswordPage';
import ControlPanelPage from 'app/pages/Admin/ControlPanelPage';
import { EditBailiff } from 'app/pages/Admin/ControlPanelPage/BailiffTab/Edit';
import { ResetPasswordPage } from 'app/pages/ResetPasswordPage';
import { ResetPasswordSuccessPage } from 'app/pages/ResetPasswordPage/ResetPasswordSuccessPage';
import { ForgotPasswordCodePage } from 'app/pages/ForgotPasswordPage/ForgotPasswordCodePage';
import SettingChangePasswordPage from 'app/pages/SettingChangePassword';
import { SettingProfileInformation } from 'app/pages/SettingProfileInformation';
import { BailiffCreate } from './pages/Admin/ControlPanelPage/BailiffTab/Create';
import FileDetail from './pages/FileDetail';
import { CreateFilesPage } from './pages/Admin/NewFileSetUpPage';
import { EditFilesPage } from './pages/Admin/FilesPage/Edit';
import FilePage from './pages/Admin/FilesPage';
import { AdminTabCreate } from './pages/Admin/ControlPanelPage/AdminTab/Create';
import { EditAdmin } from './pages/Admin/ControlPanelPage/AdminTab/Edit';
import { HelmetProvider } from 'react-helmet-async';
import { SeizureReportsPage } from './pages/SeizureReportsPage';
import { DispatchPage } from './pages/Admin/DispatchPage';
import { RedemptionPage } from './pages/Admin/RedemptionPage';
import { CreatePreSeizure } from './pages/Admin/RedemptionPage/PreSeizurePage/create';
import { EditPreSeizure } from './pages/Admin/RedemptionPage/PreSeizurePage/edit';
import { CreatePostSeizurePage } from './pages/Admin/RedemptionPage/PostSeizurePage/Create';
import { EditPostSeizure } from './pages/Admin/RedemptionPage/PostSeizurePage/Edit';
import { SeizureDetails } from './pages/Admin/ControlPanelPage/ArchiveTab/SeizureDetails';
import { PerformancePage } from './pages/Admin/PerformancePage';
import { ArchiveDetails } from './pages/Admin/ControlPanelPage/ArchiveTab/ArchiveDetails';
import CommunicationPage from './pages/Admin/CommunicationPage';
import ForbiddenPage from './pages/forbidden';

export default function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route
          path="/admin/*"
          element={
            <RequireAdminAuth>
              <LayoutAdmin>
                <Routes>
                  <Route path="file-overview" element={<FilePage />} />
                  <Route path="files/:id" element={<EditFilesPage />} />
                  <Route path="performance-overview" element={<PerformancePage />} />
                  <Route path="communication" element={<CommunicationPage />} />
                  <Route path="set-up-file" element={<CreateFilesPage />} />
                  <Route path="dispatch" element={<DispatchPage />} />
                  <Route path="redemptions" element={<RedemptionPage />} />
                  <Route path="redemptions/post-seizure/edit/:id" element={<EditPostSeizure />} />
                  <Route
                    path="redemptions/post-seizure/create"
                    element={<CreatePostSeizurePage />}
                  />
                  <Route path="redemptions/pre-seizure/create" element={<CreatePreSeizure />} />
                  <Route path="redemptions/pre-seizure/edit/:id" element={<EditPreSeizure />} />
                  <Route path="control-panel" element={<ControlPanelPage />} />
                  <Route path="control-panel/bailiffs" element={<BailiffCreate />} />
                  <Route path="control-panel/bailiffs/:id" element={<EditBailiff />} />
                  <Route path="control-panel/admin" element={<AdminTabCreate />} />
                  <Route path="control-panel/admin/:id" element={<EditAdmin />} />
                  <Route path="control-panel/archive/:id" element={<SeizureDetails />} />
                  <Route path="control-panel/archive/file/:id" element={<ArchiveDetails />} />
                  <Route path="*" element={<Navigate to="file-overview" />} />
                </Routes>
              </LayoutAdmin>
            </RequireAdminAuth>
          }
        />
        <Route path="/admin/login" element={<LoginPage />} />
        <Route path="/forbidden" element={<ForbiddenPage />} />
        <Route
          path="/*"
          element={
            <LayoutApp>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/active-account" element={<SignUpPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/forgot-password/code" element={<ForgotPasswordCodePage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/reset-password/success" element={<ResetPasswordSuccessPage />} />
                <Route
                  path="*"
                  element={
                    <RequireAuth>
                      <LayoutButtonNavigation>
                        <Routes>
                          <Route path="/" element={<HomePage />} />
                          <Route path="/file/:id" element={<FileDetail />} />
                          <Route path="/map/file/:id" element={<FileDetail />} />
                          <Route path="/leads/file/:id" element={<FileDetail />} />
                          <Route path="/calls/file/:id" element={<FileDetail />} />
                          <Route path="/map" element={<MapPage />} />
                          <Route path="/calls" element={<CallsPage />} />
                          <Route path="/setting" element={<SettingPage />} />
                          <Route
                            path="/setting/change-password"
                            element={<SettingChangePasswordPage />}
                          />
                          <Route
                            path="/setting/profile-information"
                            element={<SettingProfileInformation />}
                          />
                          <Route path="/leads" element={<LeadsPage />} />
                          <Route path="/seizure/:id" element={<SeizureReportsPage />} />
                          <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                      </LayoutButtonNavigation>
                    </RequireAuth>
                  }
                />
              </Routes>
            </LayoutApp>
          }
        />
      </Routes>
    </HelmetProvider>
  );
}
