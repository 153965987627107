import { useSearchParams } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUpdatesCommunication } from 'services/communication';
import {
  CommunicationUpdate,
  DateCommunication,
  fileResCommunication,
  headerTab,
  TAB
} from './type';
import dayjs from 'dayjs';
import { SIZE_PAGE_COMMUNICATION } from 'const/paging';
import { compact } from 'lodash';
import { renderTitleLead } from 'utils/renderTitleLead';
import { Lead } from 'types/user';
import { updateLead } from 'services/leads';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const useInjection = () => {
  const { loading } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();

  const [communicationUpdate, setCommunicationUpdate] = useState<CommunicationUpdate>({
    total: 0,
    updates: []
  });
  const [communicationSeizure, setCommunicationSeizure] = useState<CommunicationUpdate>({
    total: 0,
    updates: []
  });
  const [communicationOutOfLead, setCommunicationOutOfLead] = useState<CommunicationUpdate>({
    total: 0,
    updates: []
  });

  const [isDeleteNote, setIdDeleteNote] = useState<number | null>(null);
  const [leadDelete, setLeadDelete] = useState<Partial<Lead> | undefined>(undefined);

  const [dates, setDates] = useState<DateCommunication>({
    updates: null,
    seizureReports: null,
    outOfLeads: null
  });

  const currentTab = useMemo(() => {
    const params = searchParams.get('tab');
    return headerTab.find((item) => item.param === params)?.value || 0;
  }, [searchParams]);

  const fetchDataCommunicationUpDate = useCallback(
    async (startDate?: string | null, endDate?: string | null) => {
      const tab = searchParams.get('tab') || TAB.UPDATES;

      const data = await getUpdatesCommunication({
        tab: tab,
        page: 1,
        size: SIZE_PAGE_COMMUNICATION,
        endDate,
        startDate
      });
      if (tab === TAB.UPDATES) {
        setCommunicationUpdate(data);
      }
      if (tab === TAB.SEIZURE_REPORTS) {
        setCommunicationSeizure(data);
      }
      if (tab === TAB.OUT_OF_LEADS) {
        setCommunicationOutOfLead(data);
      }
    },
    [searchParams]
  );

  const fetchDataCommunication = useCallback(async () => {
    const [dataUpdate, dataSeizure, dataOutOfLead] = await Promise.all([
      getUpdatesCommunication({ page: 1, size: SIZE_PAGE_COMMUNICATION, tab: TAB.UPDATES }),
      getUpdatesCommunication({ page: 1, size: SIZE_PAGE_COMMUNICATION, tab: TAB.SEIZURE_REPORTS }),
      getUpdatesCommunication({ page: 1, size: SIZE_PAGE_COMMUNICATION, tab: TAB.OUT_OF_LEADS })
    ]);
    setCommunicationUpdate(dataUpdate);
    setCommunicationSeizure(dataSeizure);
    setCommunicationOutOfLead(dataOutOfLead);
  }, []);

  useEffect(() => {
    fetchDataCommunication();
  }, []);

  const handleChangeTab = useCallback(
    (newValue: number) => {
      const nameTab = headerTab.find((item) => item.value === newValue)?.param;
      setSearchParams({ tab: nameTab || TAB.UPDATES });
    },
    [setSearchParams]
  );

  const handleChangeDate = useCallback(
    async ({ endDate, startDate }: { startDate?: string | null; endDate?: string | null }) => {
      const tab = searchParams.get('tab') || TAB.UPDATES;

      setDates((prevState) => ({
        ...prevState,
        [tab]: {
          startDate,
          endDate
        }
      }));

      if ((startDate && !dayjs(startDate).isValid()) || (endDate && !dayjs(endDate).isValid()))
        return;
      await fetchDataCommunicationUpDate(startDate, endDate);
    },
    [fetchDataCommunicationUpDate, searchParams]
  );

  const handleCopyAllUpdates = useCallback(
    async (indexRow: number, data: fileResCommunication[]) => {
      let countIndex = 0;
      const findCommunicationByIndex = data.find((item) => Number(item.id) === Number(indexRow));
      if (!findCommunicationByIndex) return;
      const textCopyArray = compact(
        findCommunicationByIndex?.leads.map((itemLead) => {
          const titleLead = renderTitleLead(itemLead);
          const content = compact(
            itemLead?.notes?.map((itemNote) => {
              return `${titleLead} - ${dayjs(itemNote.updatedAt).format('M/D/YYYY, h:mm A')} - ${itemNote.content}`;
            })
          );
          if (!content.length) return;
          countIndex++;
          return `${countIndex} .${content.join('')}`;
        })
      );
      if (!textCopyArray.length) {
        alert('Cannot copy note');
        return;
      }
      try {
        await navigator.clipboard.writeText(textCopyArray.join('\n'));
      } catch (error) {
        console.error('Failed to copy text to clipboard: ', error);
      }
    },
    []
  );

  const handleDeleteNote = ({ idNote, lead }: { idNote: number; lead?: Partial<Lead> }) => {
    setIdDeleteNote(idNote);
    setLeadDelete(lead);
  };
  const onDeleteLead = useCallback(async () => {
    if (!isDeleteNote) return;
    if (!leadDelete?.id) return;
    const tab = searchParams.get('tab') || TAB.UPDATES;
    setIdDeleteNote(null);
    setLeadDelete(undefined);
    const noteDelete = compact(
      leadDelete?.notes?.map((item) => {
        if (Number(item.id) === Number(isDeleteNote)) {
          return {
            ...item,
            isDelete: true
          };
        }
        return item;
      })
    );
    await updateLead(leadDelete.id, { fileId: Number(leadDelete.fileId), notes: noteDelete });
    await getUpdatesCommunication({ page: 1, size: SIZE_PAGE_COMMUNICATION, tab: tab });
  }, [isDeleteNote, leadDelete?.id, leadDelete?.notes, leadDelete?.fileId, searchParams]);
  const { asyncCallback: asyncOnDeleteLead } = useAsyncCallback(onDeleteLead, []);

  return {
    tab: currentTab,
    searchParams,
    loading,
    handleChangeTab,
    handleChangeDate,
    dates,
    handleCopyAllUpdates,
    communicationUpdate,
    communicationSeizure,
    communicationOutOfLead,
    fetchDataCommunication,
    handleDeleteNote,
    onDeleteLead: asyncOnDeleteLead,
    leadDelete,
    setLeadDelete,
    isDeleteNote,
    setIdDeleteNote
  };
};
