import React from 'react';
import { IconButton, styled } from '@mui/material';
type ButtonFilterProp = {
  isFiltering: boolean;
  onClick: () => void;
};
export const ButtonFilter = ({ isFiltering, onClick }: ButtonFilterProp) => {
  return (
    <IconButtonStyled style={{ background: isFiltering ? '#000' : 'unset' }} onClick={onClick}>
      {isFiltering ? (
        <img src={'/icons/icon_filtering.svg'} alt={'icon-filter'} />
      ) : (
        <img src={'/icons/icon_filter.svg'} alt={'icon-filter'} />
      )}
    </IconButtonStyled>
  );
};
const IconButtonStyled = styled(IconButton)(() => ({
  border: '1px solid #22374A',
  borderRadius: 5,
  height: 36,
  width: 36,
  '&:hover': {
    backgroundColor: 'white',
    opacity: 0.8
  }
}));
