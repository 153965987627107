import { IconButton, Stack, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { memo, useEffect, useState } from 'react';
import { StyledParams } from 'types/common';
import { TNote } from 'types/user';
import { AddIcon, EditIcon } from '../../../assets/icon';
import EditIconMui from '@mui/icons-material/Edit';
import { ButtonCustom } from './ButtonCustom';
type NoteCustomProp = {
  onlyView?: boolean;
  notes?: TNote[];
  handleDelete?: (idNote: number) => void;
  notesAdmin?: TNote[];
  handleAddNewNote?: (textNote: string, isAdmin?: boolean) => void;
  handleEditNote?: ({ idNote, textNote }: { idNote: number; textNote: string }) => void;
  adminEditNote?: (idNote: number, content: string) => void;
  isAdmin?: boolean;
};
const NoteCustom = ({
  notes,
  handleDelete,
  notesAdmin,
  isAdmin,
  adminEditNote,
  handleAddNewNote,
  handleEditNote,
  onlyView
}: NoteCustomProp) => {
  const [activeNote, setActiveNote] = useState<string>('');
  const [textNote, setTextNote] = useState<string>('');
  const [dateNewNote, setDateNewNote] = useState<string>('');
  const [noteEdit, setNoteEdit] = useState<{ content: string; idNote?: number }>();

  useEffect(() => {
    if (activeNote === 'add' || !isAdmin || !notesAdmin?.length) return;
    setTextNote(notesAdmin[0]?.content || '');
  }, [activeNote]);

  return (
    <WrapperNoBorder>
      <WrapperHeaderBox>
        <TitleLead>Notes</TitleLead>
        {!onlyView && (
          <Stack flexDirection={'row'}>
            {(!isAdmin && notes?.length) || (isAdmin && notesAdmin?.length) ? (
              <IconButton
                onClick={() => {
                  setNoteEdit(undefined);
                  if (activeNote === 'edit') {
                    setActiveNote('');
                    return;
                  }
                  setActiveNote('edit');
                }}
                sx={{ width: '46px', height: '46px' }}>
                <EditIcon
                  width={'40px'}
                  fill={activeNote === 'edit' ? '#22374A' : '#fff'}
                  color={activeNote === 'edit' ? '#fff' : '#22374A'}
                />
              </IconButton>
            ) : (
              <> </>
            )}
            {isAdmin && notesAdmin?.length ? (
              <> </>
            ) : (
              <IconButton
                onClick={() => {
                  setNoteEdit(undefined);
                  if (activeNote === 'add') {
                    setActiveNote('');
                    return;
                  }
                  setActiveNote('add');
                  setDateNewNote(dayjs().format('M/D/YYYY, h:mm A'));
                }}
                sx={{ width: '46px', height: '46px' }}>
                <AddIcon
                  fill={activeNote === 'add' ? '#22374A' : '#fff'}
                  color={activeNote === 'add' ? '#fff' : '#22374A'}
                />
              </IconButton>
            )}
          </Stack>
        )}
      </WrapperHeaderBox>
      <WrapperContentNote>
        <TextNote>Summary Log</TextNote>

        <Stack gap={1.25}>
          {notes?.map((note, index) => {
            if (note.isDelete || note.isAdmin) return;
            return (
              <Stack key={index} gap={0.5} flexDirection={'row'}>
                <TextDate>
                  {(noteEdit?.idNote === note.id ? dayjs() : dayjs(note.updatedAt)).format(
                    'M/D/YYYY, h:mm A'
                  )}
                  -
                </TextDate>
                <Stack
                  width={'100%'}
                  flexDirection={'row'}
                  gap={0.5}
                  alignItems={'flex-start'}
                  justifyContent={'space-between'}>
                  {!onlyView && noteEdit?.idNote === note.id ? (
                    <Stack gap={1} alignItems={'flex-end'}>
                      <InputAdd
                        rows={4}
                        value={noteEdit?.content || ''}
                        onChange={(e) => setNoteEdit({ content: e.target.value, idNote: note?.id })}
                      />
                      <ButtonCustom
                        sx={{ width: '96px', height: '32px' }}
                        label={'Edit note'}
                        onClick={() => {
                          if (!noteEdit?.content) {
                            return;
                          }
                          if (!note.id) return;
                          handleEditNote &&
                            handleEditNote({ idNote: note.id, textNote: noteEdit.content });
                          setNoteEdit(undefined);
                        }}
                      />
                    </Stack>
                  ) : (
                    <Stack width={'100%'} gap={0.7}>
                      <TextNote>
                        {note.content} -{' '}
                        <span style={{ color: '#000', fontWeight: 500 }}>
                          {note.user.firstName} {note.user.lastName}
                        </span>
                      </TextNote>
                    </Stack>
                  )}
                  {!isAdmin && activeNote === 'edit' && (
                    <Stack width={'40px'} flexDirection={'row'} gap={'5px'}>
                      <EditIconMui
                        style={{ width: '14px', cursor: 'pointer' }}
                        fontSize={'small'}
                        color={'error'}
                        onClick={() =>
                          setNoteEdit({
                            content: note.content,
                            idNote: note?.id
                          })
                        }
                      />
                      <img
                        onClick={() => {
                          if (note?.id) {
                            handleDelete && handleDelete(note.id);
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                        src={'/icons/icon_delete_file.svg'}
                        alt={'icon'}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Stack flexDirection={'row'} gap={1.5}>
          {activeNote === 'add' && !isAdmin && (
            <>
              <InputTime disabled value={dateNewNote} onChange={() => {}} />
              <Stack gap={1} width={'100%'} alignItems={'flex-end'}>
                <InputAdd rows={4} value={textNote} onChange={(e) => setTextNote(e.target.value)} />
                <ButtonCustom
                  sx={{ width: '96px', height: '32px' }}
                  label={'Add Note'}
                  onClick={() => {
                    handleAddNewNote && handleAddNewNote(textNote);
                    setActiveNote('');
                    setTextNote('');
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
        <TextNote>Admin Note:</TextNote>
        {isAdmin && activeNote === 'edit' ? (
          <Stack alignItems={'flex-end'} gap={2}>
            <InputAdd rows={4} value={textNote} onChange={(e) => setTextNote(e.target.value)} />
            <ButtonCustom
              sx={{ width: '96px', height: '32px' }}
              label={'Edit Note'}
              onClick={() => {
                if (notesAdmin?.length && notesAdmin[0]?.id) {
                  adminEditNote && adminEditNote(notesAdmin?.length && notesAdmin[0].id, textNote);
                  setActiveNote('');
                  setTextNote('');
                }
              }}
            />
          </Stack>
        ) : (
          <Stack gap={0.8}>
            {notesAdmin?.map((item, index) => {
              return <TextNoteAdmin key={index}>{item?.content}</TextNoteAdmin>;
            })}
          </Stack>
        )}
        <Stack flexDirection={'row'} gap={1.5}>
          {activeNote === 'add' && isAdmin && (
            <>
              <Stack gap={1} width={'100%'} alignItems={'flex-end'}>
                <InputAdd rows={4} value={textNote} onChange={(e) => setTextNote(e.target.value)} />
                <ButtonCustom
                  sx={{ width: '96px', height: '32px' }}
                  label={'Add Note'}
                  onClick={() => {
                    handleAddNewNote && handleAddNewNote(textNote, isAdmin);
                    setActiveNote('');
                    setTextNote('');
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
      </WrapperContentNote>
    </WrapperNoBorder>
  );
};

export const NoteCustomMemo = memo(NoteCustom);
const InputTime = styled('input')(() => ({
  border: 'none',
  height: '30px',
  width: '140px',
  fontSize: '14px',
  borderRadius: '5px',
  paddingLeft: '5px',
  color: '#ADADAD',
  '&:disabled': {
    background: '#fff'
  }
}));
const InputAdd = styled('textarea')(() => ({
  border: 'none',
  minHeight: '30px',
  fontSize: '14px',
  borderRadius: '5px',
  padding: '6px 10px',
  width: '100%',
  color: '#565656'
}));
const WrapperContentNote = styled(Stack)(({ theme }: StyledParams) => ({
  background: theme.colors.blue,
  borderRadius: '5px',
  width: '100%',
  padding: '8px 10px',
  gap: '10px'
}));
const TextNote = styled(Typography)(() => ({
  color: '#565656',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'pre-line'
}));

const TextDate = styled(Typography)(() => ({
  color: '#ADADAD',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '100%',
  minWidth: '140px'
}));
const TextNoteAdmin = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'pre-line'
}));
const WrapperNoBorder = styled(Stack)(() => ({
  paddingBottom: '16px'
}));
const WrapperHeaderBox = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '10px'
}));
const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '100%'
}));
