import { Box, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

import { InputText } from 'app/components/elements/InputText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { loginSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconUser } from 'assets/icon';

export type FormLoginType = {
  email: string;
  password: string;
};

type FormLoginProps = {
  onSubmit: (data: FormLoginType) => void;
};
export const FormLogin = ({ onSubmit }: FormLoginProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm<FormLoginType>({ resolver: yupResolver(loginSchema) });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputText
            inputError={errors.email?.message}
            placeholder={'Email'}
            inputProps={{
              ...register('email')
            }}
            onChange={(e) => {
              clearErrors('email');
              setValue('email', e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
                <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                  <IconUser />
                </Stack>
              </InputAdornment>
            }
          />
        </Stack>
        <Stack gap={1}>
          <InputText
            placeholder={'Password'}
            inputError={errors.password?.message}
            inputProps={{
              ...register('password'),
              type: showPassword ? 'text' : 'password'
            }}
            onChange={(e) => {
              clearErrors('password');
              setValue('password', e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <Visibility sx={{ color: '#22374A' }} />
                  ) : (
                    <VisibilityOff sx={{ color: '#22374A' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Stack>
        <Box style={{ marginBottom: '18px', fontWeight: 600, textAlign: 'right' }}>
          <Link style={{ cursor: 'pointer' }} href={'/forgot-password'}>
            Forgot Password?
          </Link>
        </Box>
        <ButtonCustom type={'submit'} label={'Log In'} />
      </Stack>
    </form>
  );
};
