import { Grid, Stack } from '@mui/material';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
type HeaderFileProps = {
  optionHeader: { label: string; value?: number }[];
  icon?: boolean;
};
const HeaderFile = ({ optionHeader, icon }: HeaderFileProps) => {
  const parcel = useMemo(() => {
    if (!optionHeader.length) return 12;
    return 12 / optionHeader.length;
  }, [optionHeader]);
  return (
    <WrapperHeader>
      <GridContainer container spacing={10} justifyContent="flex-start" alignItems="center">
        {optionHeader.map((option, index) => (
          <Grid item xs={parcel} sm={parcel} md={parcel} lg={parcel} key={index}>
            <BoxItem>
              <TextLabel>{option.label}</TextLabel>
              <TextNumber>
                {icon && '#'} {option?.value || 0}
              </TextNumber>
            </BoxItem>
          </Grid>
        ))}
      </GridContainer>
    </WrapperHeader>
  );
};

export const HeaderFileMemo = memo(HeaderFile);

const WrapperHeader = styled(Stack)(() => ({
  width: '100%',
  padding: '32px 16px'
}));
const BoxItem = styled(Stack)(() => ({
  background: '#EFF8FF',
  padding: '5px 12px',
  borderRadius: '20px',
  gap: '8px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    right: -40,
    top: 0,
    height: '100%',
    width: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
}));
const TextLabel = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#404040'
}));
const TextNumber = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '36px',
  color: '#1D7CBC'
}));
const GridContainer = styled(Grid)(() => ({
  '& .MuiGrid-item:last-child': {
    '& > div::after': {
      display: 'none'
    }
  }
}));
