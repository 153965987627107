import { StatusFiles } from 'const/option';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { capitalizeFirstLetter } from 'const/common';
import React from 'react';

export const BoxStatus = ({ status }: { status: string }) => {
  const findStatus = StatusFiles.find((item) => item.value === status);
  return (
    <Stack
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%'
      }}>
      <Stack
        sx={{
          background: findStatus?.color || '#9747FF',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 10px',
          height: '20px',
          borderRadius: '5px'
        }}>
        <Typography
          sx={{
            color: '#fff',
            fontSize: '10px',
            fontWeight: 600
          }}>
          {capitalizeFirstLetter(findStatus?.value.replace('_', ' ') || 'New')}
        </Typography>
      </Stack>
    </Stack>
  );
};
