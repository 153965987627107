import React, { useMemo } from 'react';
import { Box, IconButton, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { InputText } from 'app/components/elements/InputText';
import { CATEGORY, widthScreen } from 'const/common';
import FilterModal from 'app/components/elements/ModalFilter';
import { useLeads } from 'hooks/useLeads';
import { Helmet } from 'react-helmet-async';
import { TableInfiniteScroll } from 'app/components/elements/TableInfiniteScroll';
import { headerTableFile, infoStickies, renderDataMapFileTable } from 'const/table';
import { Files } from 'services/leads';
import { ButtonFilter } from 'app/components/elements/ButtonFilter';
import { renderTitleLead } from 'utils/renderTitleLead';

function LeadsPage() {
  const {
    open,
    search,
    searchInput,
    rows,
    setSearch,
    handleOpen,
    handleClose,
    handleCancel,
    handleInputSearch,
    onFilter,
    handleResetFilter,
    formValue,
    handleClickRow,
    fetchMoreData,
    hasMore,
    onSortHeader
  } = useLeads({ category: CATEGORY.NONE });
  const headers = headerTableFile([{ index: 4, label: 'Lead Title', width: 200 }]);
  const dataFile = useMemo(
    () => rows.map((item) => ({ ...item.file, leadTitle: renderTitleLead(item, false) })),
    [rows]
  ) as Files[];
  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - Leads</title>
      </Helmet>
      <Box mx={2}>
        <Stack direction={'row'} mt={4} justifyContent={'space-between'}>
          <TitleStyled>Leads</TitleStyled>
          <Stack direction={'row'} gap={2.5}>
            {search ? (
              <IconButtonStyled onClick={handleCancel}>
                <img src={'/icons/icon_cancel_bold.svg'} alt={'icon-cancel'} />
              </IconButtonStyled>
            ) : (
              <IconButtonStyled onClick={() => setSearch(!search)}>
                <img src={'/icons/icon_search.svg'} alt={'icon-search'} />
              </IconButtonStyled>
            )}
            <ButtonFilter
              isFiltering={
                !(
                  !formValue.doorKnock &&
                  !formValue.earlyMorning &&
                  !formValue.status.length &&
                  !formValue.types.length
                )
              }
              onClick={handleOpen}
            />
          </Stack>
        </Stack>
        {search && (
          <Box mt={2.5} mb={2}>
            <InputText
              placeholder={'Enter your search'}
              inputProps={{
                defaultValue: searchInput
              }}
              hasInputSearch
              onChange={handleInputSearch}
              startAdornment={
                <InputAdornment sx={{ marginLeft: 1.25 }} position="end">
                  <img alt={'search'} src={'/icons/icon_search.svg'} />
                </InputAdornment>
              }
            />
          </Box>
        )}
      </Box>
      <Stack maxWidth={widthScreen < 500 ? widthScreen : '450px'} mt={search ? 0 : 2.5}>
        <TableInfiniteScroll
          onSortHeader={onSortHeader}
          handleChooseRow={handleClickRow}
          data={renderDataMapFileTable(dataFile)}
          headers={headers}
          handleFetchMore={fetchMoreData}
          hasMore={hasMore}
          infoStickies={infoStickies}
          idElementScroll={'scrollableDiv'}
        />
      </Stack>
      <FilterModal
        value={formValue}
        open={open}
        onClose={handleClose}
        onReset={handleResetFilter}
        onSubmit={onFilter}
      />
    </Stack>
  );
}

export default LeadsPage;

const TitleStyled = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '25px'
}));

const IconButtonStyled = styled(IconButton)(() => ({
  border: '1px solid #22374A',
  borderRadius: 5,
  height: 36,
  width: 36,
  '&:hover': {
    backgroundColor: 'white',
    opacity: 0.8
  }
}));
