import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { StatusRedemptions, stepRedemptionsSeizure } from 'const/option';
import { Stack, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'const/common';
import React from 'react';
import { DataTable } from 'app/components/elements/TableCustom';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { useRedemption } from './hooks';
import { Helmet } from 'react-helmet-async';

const HEADER: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.row.fileStatusTransition.status;
      const findStatus = StatusRedemptions.find((item) => item.value === status);
      return (
        <Stack
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
            gap: 10
          }}>
          <Stack
            style={{
              background: findStatus?.color || '#9747FF',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 10px',
              height: '20px',
              borderRadius: '5px'
            }}>
            <Typography
              style={{
                color: '#fff',
                fontSize: '10px',
                fontWeight: 600
              }}>
              {capitalizeFirstLetter(findStatus?.value.replace('_', ' ') || 'Pre Seizure')}
            </Typography>
          </Stack>
          {params.row.isFileReopen && (
            <Stack
              style={{
                background: params.row.isFileReopen ? '#FF4773' : 'blue',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 10px',
                height: '20px',
                borderRadius: '5px'
              }}>
              <Typography
                style={{
                  color: '#fff',
                  fontSize: '10px',
                  fontWeight: 600
                }}>
                Reopened
              </Typography>
            </Stack>
          )}
        </Stack>
      );
    }
  },

  {
    field: 'step',
    headerName: 'Step',
    width: 130,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.row.filePreSeizures.status || params.row.filePostSeizures.status;
      const findStatus = stepRedemptionsSeizure.find((item) => item.value === status);
      return (
        <Stack
          style={{
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%'
          }}>
          {findStatus?.label}
        </Stack>
      );
    }
  },
  { field: 'firstName', headerName: 'First Name', width: 130 },
  { field: 'lastName', headerName: 'Last Name', width: 130 },
  { field: 'year', headerName: 'Year', width: 80 },
  { field: 'make', headerName: 'Make', width: 80 },
  { field: 'model', headerName: 'Model', width: 80 },
  { field: 'coBorrowerFirstName', headerName: 'Co-Borrower First Name', width: 130 },
  { field: 'coBorrowerLastName', headerName: 'Co-Borrower Last Name', width: 130 },
  { field: 'fileNumber', headerName: 'File number', width: 130 },
  { field: 'vin', headerName: 'VIN', width: 80 },
  { field: 'colour', headerName: 'Colour', width: 80 },
  { field: 'plate', headerName: 'Plate', width: 80 },
  { field: 'outOfLeads', headerName: 'Out Of Leads', width: 120 }
];

export const RedemptionPage = () => {
  const { rows, totalRows, page, onChangePage, handleClickRow } = useRedemption();

  return (
    <LayoutAdminTable title={'Redemptions'}>
      <Helmet>
        <title>Surrey Bailiffs - Redemptions</title>
      </Helmet>
      <DataTable
        data={rows}
        totalRows={totalRows}
        paginationModel={{
          page: page ? Number(page) - 1 : 1,
          pageSize: SIZE_PAGE_CONTROL_PANEL
        }}
        loading={false}
        handlePaginationModelChange={(newPaginationModel) =>
          onChangePage(newPaginationModel.page + 1)
        }
        handleRowClick={(params) => {
          const isPostSeizure = !!params.row.filePostSeizures.id;
          handleClickRow(
            params.row.filePostSeizures.id || params.row.filePreSeizures.id,
            isPostSeizure,
            Number(params.row.id)
          );
        }}
        header={HEADER}
      />
    </LayoutAdminTable>
  );
};
