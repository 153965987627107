import React from 'react';
import { Stack, Typography, styled, CircularProgress } from '@mui/material';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { StyledParams } from 'types/common';
import { HeaderFileMemo } from 'app/pages/Admin/FilesPage/components/HeaderFile';
import CustomDatePicker from 'app/components/elements/InputDate';
import { TableInfiniteScroll } from 'app/components/elements/TableInfiniteScroll';
import { usePerformance } from 'app/pages/Admin/PerformancePage/hooks';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';

const HEADER_OVERALL = [
  { label: 'Average Days Open', width: 200 },
  { label: 'Total Opened', width: 200 },
  { label: 'Total Seized', width: 200 },
  { label: 'Total Redeemed', width: 200 },
  { label: 'Total Closed Client Request', width: 250 },
  { label: 'Total Closed Not Found', width: 250 }
];

const HEADER_BAILIFF = [
  { label: 'Bailiff', width: 200 },
  { label: 'Total Files Open', width: 120 },
  { label: 'Need Attention', width: 120 },
  { label: 'Aged', width: 120 },
  { label: 'Average Days Open', width: 200 },
  { label: 'Total Opened', width: 120 },
  { label: 'Total Seized', width: 120 },
  { label: 'Total Redeemed', width: 120 },
  { label: 'Total Closed Client Request', width: 200 },
  { label: 'Total Closed Not Found', width: 200 }
];

const HEADER_CLIENT = [
  { label: 'Client', width: 200 },
  { label: 'Total Files Open', width: 120 },
  { label: 'Need Attention', width: 120 },
  { label: 'Aged', width: 120 },
  { label: 'Average Days Open', width: 200 },
  { label: 'Total Opened', width: 120 },
  { label: 'Total Seized', width: 120 },
  { label: 'Total Redeemed', width: 120 },
  { label: 'Total Closed Client Request', width: 200 },
  { label: 'Total Closed Not Found', width: 200 }
];

export const PerformancePage = () => {
  const {
    optionHeader,
    rowDataOverall,
    overallFromDate,
    overallToDate,
    bailiffFromDate,
    bailiffToDate,
    clientFromDate,
    clientToDate,
    rowDataBailiff,
    rowDataClient,
    isLoadingOverall,
    isLoadingBailiff,
    isLoadingClient,
    handleBailiffFromDateChange,
    handleBailiffToDateChange,
    handleClientFromDateChange,
    handleClientToDateChange,
    handleOverallToDateChange,
    handleOverallFromDateChange,
    handleClickExport
  } = usePerformance();

  return (
    <LayoutAdminTable
      title={'Performance Overview'}
      textButton={'Export'}
      onClickButton={handleClickExport}>
      <Helmet>
        <title>Surrey Bailiffs - Performance</title>
      </Helmet>
      <WrapperBox>
        <TextTitleBoxStyled>Top Level Performance</TextTitleBoxStyled>
        <TextTitleContentStyled>Current Workload</TextTitleContentStyled>
        <HeaderFileMemo icon optionHeader={optionHeader} />
        <>
          <Stack direction="row" justifyContent={'space-between'}>
            <TextTitleContentStyled>Overall Performance</TextTitleContentStyled>
            <Stack direction="row" alignItems={'center'} gap={1.5}>
              <TextDateStyled>From</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleOverallFromDateChange}
                value={overallFromDate}
                maxDate={overallToDate ? dayjs(overallToDate) : undefined}
              />
              <TextDateStyled>To</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleOverallToDateChange}
                value={overallToDate}
                minDate={overallFromDate ? dayjs(overallFromDate) : undefined}
              />
            </Stack>
          </Stack>
          {isLoadingOverall ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <TableInfiniteScroll
              data={rowDataOverall}
              handleFetchMore={() => {}}
              headers={HEADER_OVERALL}
              hasMore={false}
              handleChooseRow={() => {}}
              styledTableRow={{ cursor: ' default ' }}
            />
          )}
        </>
      </WrapperBox>

      <WrapperBox sx={{ marginTop: '20px' }}>
        <>
          <Stack direction="row" justifyContent={'space-between'}>
            <TextTitleContentStyled>Bailiff Performance</TextTitleContentStyled>
            <Stack direction="row" alignItems={'center'} gap={1.5}>
              <TextDateStyled>From</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleBailiffFromDateChange}
                value={bailiffFromDate}
                maxDate={bailiffToDate ? dayjs(bailiffToDate) : undefined}
              />
              <TextDateStyled>To</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleBailiffToDateChange}
                value={bailiffToDate}
                minDate={bailiffFromDate ? dayjs(bailiffFromDate) : undefined}
              />
            </Stack>
          </Stack>
          {isLoadingBailiff ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <TableInfiniteScroll
              data={rowDataBailiff}
              handleFetchMore={() => {}}
              headers={HEADER_BAILIFF}
              hasMore={false}
              handleChooseRow={() => {}}
              styledTableRow={{ cursor: ' default ' }}
            />
          )}
        </>
      </WrapperBox>

      <WrapperBox sx={{ marginTop: '20px' }}>
        <>
          <Stack direction="row" justifyContent={'space-between'}>
            <TextTitleContentStyled>Client Performance</TextTitleContentStyled>
            <Stack direction="row" alignItems={'center'} gap={1.5}>
              <TextDateStyled>From</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleClientFromDateChange}
                value={clientFromDate}
                maxDate={clientToDate ? dayjs(clientToDate) : undefined}
              />
              <TextDateStyled>To</TextDateStyled>
              <CustomDatePicker
                title={''}
                onChange={handleClientToDateChange}
                value={clientToDate}
                minDate={clientFromDate ? dayjs(clientFromDate) : undefined}
              />
            </Stack>
          </Stack>
          {isLoadingClient ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <TableInfiniteScroll
              data={rowDataClient}
              handleFetchMore={() => {}}
              headers={HEADER_CLIENT}
              hasMore={false}
              handleChooseRow={() => {}}
              styledTableRow={{ cursor: ' default ' }}
            />
          )}
        </>
      </WrapperBox>
    </LayoutAdminTable>
  );
};

const WrapperBox = styled(Stack)(() => ({
  border: '1px solid #686E7D',
  padding: '14px 16px 20px 16px',
  borderRadius: '16px',
  flexDirection: 'column'
}));
const TextTitleBoxStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '18px',
  fontWeight: 700,
  color: theme.colors.dark1,
  marginBottom: '20px'
}));
const TextTitleContentStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '16px',
  fontWeight: 700,
  color: theme.colors.dark1
}));

const TextDateStyled = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400,
  color: '#0A0A0A'
}));
