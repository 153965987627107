import React from 'react';
import { Helmet } from 'react-helmet-async';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { useCreatePostSeizure } from 'app/pages/Admin/RedemptionPage/PostSeizurePage/Create/hooks';
import { PostSeizureForm } from '../components/modules/FormPostSeizure/FormPostSeizure';

export const CreatePostSeizurePage = () => {
  const { handleOnBack, onSubmitPostSeizure, searchParams } = useCreatePostSeizure();

  return (
    <LayoutAdminTable title={'Post Seizure'} textButton={'Back'} onClickButton={handleOnBack}>
      <Helmet>
        <title>Surrey Bailiffs - Create Post Seizure</title>
      </Helmet>
      <PostSeizureForm
        initDataForm={{ fileId: Number(searchParams.get('fileId')) }}
        onSubmitForm={onSubmitPostSeizure}
      />
    </LayoutAdminTable>
  );
};
