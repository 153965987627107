import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import React, { memo } from 'react';
import { StyledParams } from 'types/common';
import { TFilePhoto } from 'types/user';

type BoxPhotoProps = {
  photos?: TFilePhoto[];
};
const BoxPhoto = ({ photos }: BoxPhotoProps) => {
  return (
    <Stack>
      {photos && photos?.length > 0 && (
        <WrapperHeaderBox>
          <TitleLead>Photos</TitleLead>
        </WrapperHeaderBox>
      )}
      {photos && (
        <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
          {photos.map((file, index) => (
            <Grid item xs={4} sm={4} md={4} lg={4} key={index}>
              <Box
                component="img"
                src={file.url}
                alt={`Image ${index + 1}`}
                sx={{
                  width: '100%',
                  height: '120px',
                  objectFit: 'cover',
                  display: 'block',
                  borderRadius: '10px'
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

export const BoxPhotoMemo = memo(BoxPhoto);

const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '100%'
}));
const WrapperHeaderBox = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '10px'
}));
