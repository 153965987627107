import * as React from 'react';
import { DataGrid, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { Stack, styled } from '@mui/material';

type HeaderTableProps = {
  field: string;
  headerName?: string;
  width?: number;
  sortable?: boolean;
};

type DataTableProps = {
  header: HeaderTableProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  handleRowClick?: (params: GridRowParams) => void;
  paginationModel: GridPaginationModel;
  handlePaginationModelChange: (newPaginationModel: GridPaginationModel) => void;
  totalRows: number;
  loading?: boolean;
  pageSizeOptions?: number[];
};
export const DataTable = ({
  data,
  header,
  handleRowClick,
  totalRows,
  handlePaginationModelChange,
  paginationModel,
  loading,
  pageSizeOptions
}: DataTableProps) => {
  return (
    <Stack style={{ height: 780, width: '100%' }}>
      <StyledGrid
        autoHeight
        getRowId={(row) => row?.leadId || row?.id}
        pageSizeOptions={pageSizeOptions || [10]}
        rows={data}
        columns={header}
        onRowClick={handleRowClick}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        rowCount={totalRows}
        paginationMode="server"
        loading={loading}
        checkboxSelection
        disableAutosize
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnSorting
        disableDensitySelector
      />
    </Stack>
  );
};

const StyledGrid = styled(DataGrid)(() => ({
  '.MuiDataGrid-columnHeaderCheckbox': {
    display: 'none'
  },
  '.MuiDataGrid-cellCheckbox': {
    display: 'none'
  },
  '.MuiDataGrid-row': {
    ' &:nth-of-type(odd)': {
      backgroundColor: '#F1F1E8',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: ' #F1F1E8',
        opacity: 0.8
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ' #fff',
      opacity: 0.8
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    },
    '.MuiDataGrid-cell': {
      outline: 'none'
    }
  },
  '.MuiDataGrid-selectedRowCount': {
    visibility: 'hidden'
  },
  '.MuiDataGrid-columnHeader:focus': {
    outline: 'none'
  },
  '.Mui-selected': {
    ' &:nth-of-type(odd)': {
      backgroundColor: 'rgba(34, 55, 74, 0.08)'
    }
  }
}));
