import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useNavigate } from 'react-router-dom';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { isNil, omitBy } from 'lodash';
import { getFiles } from 'services/files';
import { useDebouncedCallback } from 'use-debounce';
import { StatusFileFilter } from 'const/common';
import { Files } from 'services/leads';
import { useUpdateSearchParamUrl } from 'utils/index';

export interface FilterFormValues {
  earlyMorning?: boolean;
  doorKnock?: boolean;
  status: string[];
  types: string[];
  page?: number;
  size?: number;
  searchText?: string;
}

export const useHomePage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useUpdateSearchParamUrl();

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<Files[]>([]);
  const [search, setSearch] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>(searchParams.get('searchText') ?? '');
  const [selectedStatus, setSelectedStatus] = useState<string>(
    searchParams.get('fileStatus') ?? StatusFileFilter.OPEN_FILE
  );

  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const formValue = useMemo(() => {
    return {
      searchText: searchParams.get('searchText') || searchInput,
      earlyMorning: searchParams.get('earlyMorning')
        ? searchParams.get('earlyMorning') === 'true'
        : undefined,
      doorKnock: searchParams.get('doorKnock')
        ? searchParams.get('doorKnock') === 'true'
        : undefined,
      status: searchParams.getAll('status'),
      fileStatus: searchParams.get('fileStatus') ? searchParams.get('fileStatus') : undefined,
      types: searchParams.getAll('types'),
      sortBy: searchParams.get('sortBy') ? searchParams.get('sortBy') : undefined,
      order: searchParams.get('order') ? searchParams.get('order') : undefined,
      page: 1,
      size: SIZE_PAGE_CONTROL_PANEL
    };
  }, [searchParams, searchInput]);

  const fetchDataBailiff = useCallback(async () => {
    const data = await getFiles(formValue);
    setRows(data.files);
    setPage(2);
    if (data.total > SIZE_PAGE_CONTROL_PANEL) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [formValue]);

  const { asyncCallback: asyncFetchUser } = useAsyncCallback(fetchDataBailiff, []);

  useEffect(() => {
    asyncFetchUser();
  }, [asyncFetchUser]);

  const fetchMoreData = useCallback(async () => {
    const res = await getFiles({ ...formValue, page: page });
    const newData = res.files;
    setRows((prevState) => [...prevState, ...newData]);
    setPage((prevState) => prevState + 1);
    if (res.total > SIZE_PAGE_CONTROL_PANEL * page) {
      setHasMore(true);
      return;
    }
    setHasMore(false);
  }, [page, formValue]);

  const handleInputSearch = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    const params = {
      page: searchParams.get('page') || 1,
      searchText: inputValue || null
    };
    setSearchParams(omitBy(params, isNil) as { [key: string]: string });
  }, 500);

  const handleStatusChange = useCallback((status: string) => {
    setSelectedStatus(status);
    const params = {
      fileStatus: status || null
    };
    setSearchParams(omitBy(params, isNil) as { [key: string]: string });
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancel = () => {
    setSearch(false);
    if (!searchInput) return;
    setSearchInput('');
    setSearchParams({
      searchText: ''
    });
  };

  const onFilter = useCallback((valueForm: FilterFormValues) => {
    const params = {
      page: 1,
      status: valueForm.status || null,
      types: valueForm.types || null,
      earlyMorning: valueForm.earlyMorning,
      doorKnock: valueForm.doorKnock
    };
    setSearchParams(omitBy(params, isNil) as { [key: string]: string | string[] });
  }, []);

  const handleResetFilter = useCallback(() => {
    setSearchParams({});
  }, []);

  const handleClickRow = useCallback(
    (indexRow: number) => {
      const id = rows.find((_, index) => index === indexRow)?.id;
      if (id) {
        navigate(`/file/${id}`);
      }
    },
    [rows]
  );

  const onSortHeader = useCallback(
    (sortField: string) => {
      let order = 'asc';
      const sortFieldOld = searchParams.get('sortBy');
      const orderOld = searchParams.get('order');
      if (sortFieldOld === sortField) {
        if (orderOld === 'asc') {
          order = 'desc';
        } else {
          order = 'asc';
        }
      }
      setSearchParams({ ...formValue, sortBy: sortField, order: order });
    },
    [formValue, searchParams, setSearchParams]
  );

  return {
    open,
    search,
    searchInput,
    selectedStatus,
    rows,
    setSearch,
    handleOpen,
    handleClose,
    handleCancel,
    handleInputSearch,
    handleStatusChange,
    handleClickRow,
    onFilter,
    handleResetFilter,
    formValue,
    fetchMoreData,
    hasMore,
    onSortHeader
  };
};
