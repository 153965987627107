import axios from 'axios';
import { compact, omit } from 'lodash';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Lead, TFile, TypeLead } from 'types/user';
import { NewLeadForm } from 'app/components/modules/ModalNewLead';
import { bailiffUpdateFile, getFilesById, updateFilesBailiff } from 'services/files';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { createLead, CreateLeadPayload, updateLead } from 'services/leads';
import { useFlash } from 'contexts/flash';
import { AddNote } from './components/LeadBox';

export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const { id } = useParams();

  const [modalNewLead, setModalNewLead] = useState<boolean>(false);
  const [leads, setLeads] = useState<Partial<Lead>[]>([]);
  const [file, setFile] = useState<TFile>();
  const [searchParams] = useSearchParams();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [idsLeadOpen, setIdsLeadOpen] = useState<number[]>([]);
  const [showQuickNote, setShowQuickNote] = useState<boolean>(false);
  const [textQuickNote, setTextQuickNote] = useState<string>(file?.quickNote || '');

  useEffect(() => {
    if (!searchParams.get('leadId')) return;
    setIdsLeadOpen([Number(searchParams.get('leadId'))]);
  }, [searchParams.get('leadId')]);

  useEffect(() => {
    const leadIds = searchParams.getAll('leadId');
    if (!leadIds.length || isScrolled) return;
    const leadItem = document.querySelector(`#lead-detail-${leadIds[0]}`) as HTMLDivElement;
    if (leadItem) {
      setIsScrolled(true);
      setLeads((prevState) =>
        prevState.map((item) => ({
          ...item,
          isExpanded: leadIds.includes(`${item.id}`)
        }))
      );
      leadItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [leads, searchParams, isScrolled]);

  const getFiles = useCallback(async () => {
    const data = await getFilesById(Number(id));
    const fileRes = data.file as TFile;

    if (fileRes.onHold) {
      navigate(-1);
    }
    setFile(fileRes);
    setTextQuickNote(fileRes?.quickNote || '');
    setLeads(fileRes.leads);
  }, [id, navigate]);

  useEffect(() => {
    asyncGetFiles();
  }, [id]);

  const handleAddQuickNote = useCallback(async () => {
    if (!textQuickNote || !id) return;
    const data = await bailiffUpdateFile(Number(id), { quickNote: textQuickNote });
    setFlash({ type: 'success', message: 'Add quick note successful' });
    const fileRes = data.file as TFile;
    setFile(fileRes);
    setTextQuickNote(fileRes?.quickNote || '');
    setShowQuickNote(false);
  }, [id, textQuickNote]);

  const { asyncCallback: asyncAddQuickNote } = useAsyncCallback(handleAddQuickNote, []);

  const handleCreateLead = useCallback(
    async (value: NewLeadForm) => {
      let earlyMorning = false;
      let doorKnock = false;
      if (value.type === TypeLead.POR || TypeLead.MVS) {
        earlyMorning = true;
        doorKnock = true;
      }
      const payload = {
        ...omit(value, ['towYards']),
        earlyMorning,
        doorKnock,
        leadTowYards: value.towYards?.map((item) => ({ content: item.value, checked: false })),
        fileId: Number(id)
      };
      const data = await createLead(payload);
      setLeads([...leads, data.lead]);
      setModalNewLead(false);
      setFlash({ type: 'success', message: 'Create lead successful' });
    },
    [leads, id]
  );

  const handleUpdateLead = useCallback(
    async ({
      idLead,
      payload,
      message
    }: {
      idLead: number;
      payload: Partial<CreateLeadPayload>;
      message: string;
    }) => {
      const data = await updateLead(idLead, { ...payload, fileId: Number(id) });

      setLeads((prevState) =>
        prevState.map((item) => {
          if (item.id === idLead) {
            return data.lead;
          }
          return item;
        })
      );
      setFlash({ type: 'success', message });
      if (data?.onHold) {
        navigate(-1);
      }
    },
    [id, setFlash, navigate]
  );
  const handleAttendance = useCallback(
    async ({
      id,
      files,
      textNote,
      isEarly
    }: {
      id?: number;
      files?: File[];
      textNote: string;
      isEarly?: boolean;
    }) => {
      const formData = new FormData();
      let attendanceIds: number[] = [];
      if (files?.length) {
        for (let x = 0; x < files.length; x++) {
          formData.append('files', files[x], files[x].name);
        }
        const { data: resListIdFile } = await axios.post('/storages/note-photo', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        attendanceIds = resListIdFile.files;
      }

      const payload = {
        doorKnock: isEarly ? undefined : false,
        earlyMorning: isEarly ? false : undefined,
        notes: [
          {
            content: textNote,
            ...(attendanceIds.length > 0 ? { notePhotosIds: attendanceIds } : {})
          }
        ]
      };
      await asyncUpdateLead({
        idLead: id,
        payload,
        message: 'Update leads successful'
      });
    },
    []
  );

  const handleOnRedemption = useCallback(async () => {
    await updateFilesBailiff(Number(id), 'PRE_SEIZURE');
    setFlash({ type: 'success', message: 'Update files successful' });
    setTimeout(() => {
      navigate('/');
    }, 500);
  }, [id]);

  const { asyncCallback: asyncCreateLead } = useAsyncCallback(handleCreateLead, []);
  const { asyncCallback: asyncUpdateLead } = useAsyncCallback(handleUpdateLead, [id]);
  const { asyncCallback: asyncGetFiles } = useAsyncCallback(getFiles, []);
  const { asyncCallback: handleAddNotePhotos } = useAsyncCallback(handleAttendance, []);
  const { asyncCallback: handleUpdateBailiff } = useAsyncCallback(handleOnRedemption, []);

  return {
    leads,
    modalNewLead,
    setModalNewLead,
    handleCreateLead: asyncCreateLead,
    handleAddNotePhotos: handleAddNotePhotos,
    handleUpdateBailiff: handleUpdateBailiff,
    handleDeleteNoteLead: async (idNote: number, idLead: number) =>
      await asyncUpdateLead({
        idLead,
        payload: {
          notes: [{ id: idNote, isDelete: true }]
        },
        message: 'Delete note leads successful'
      }),
    handleAddNewNote: async ({ idLead, textNote }: AddNote) =>
      await asyncUpdateLead({
        idLead,
        payload: {
          notes: [{ content: textNote }]
        },
        message: 'Add note leads successful'
      }),
    handleEditNote: async (idLead: number, idNote: number, content: string) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          notes: [{ id: idNote, content: content }]
        },
        message: 'Edit note leads successful'
      });
    },
    handleChooseEarlyMorning: async (idLead: number, earlyMorning: boolean) =>
      await asyncUpdateLead({
        idLead,
        payload: { earlyMorning },
        message: 'Update early morning successful'
      }),
    handleChooseDoorKnock: async (idLead: number, doorKnock: boolean) =>
      await asyncUpdateLead({
        idLead,
        payload: { doorKnock },
        message: 'Update door knock successful'
      }),
    onClickSeized: useCallback(() => {
      navigate(`/seizure/${id}`);
    }, []),
    handleChooseRuleOut: async (idLead: number, ruleOut: boolean) => {
      await asyncUpdateLead({
        idLead,
        payload: { ruleOut },
        message: 'Rule out leads successful'
      });
    },
    handleAddTowYards: async ({
      idLead,
      towYards
    }: {
      idLead: number;
      towYards: { id?: number; value: string; checked?: boolean; user?: { role: string } }[];
    }) => {
      const leadTowYards =
        compact(
          towYards?.map((item) => {
            if (!item.id) {
              return { content: item.value, checked: item.checked };
            }
            return { id: item.id, content: item.value, checked: item.checked };
          })
        ) || [];
      if (!leadTowYards.length) return;
      await asyncUpdateLead({
        idLead,
        payload: {
          leadTowYards
        },
        message: 'Update tow yards successful'
      });
    },
    handleDeleteTowYards: async ({
      idTowYards,
      idLead
    }: {
      idLead: number;
      idTowYards: number;
    }) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          leadTowYards: [
            {
              id: idTowYards,
              isDelete: true
            }
          ]
        },
        message: 'Delete tow yards successful'
      });
    },
    handleChangeVinStatus: async ({ vinStatus, idLead }: { idLead: number; vinStatus: string }) => {
      await asyncUpdateLead({
        idLead,
        payload: {
          vinStatus
        },
        message: 'update VIN check successful'
      });
    },
    handleUploadFileCarfax: async ({
      files,
      idLead,
      oldDocIds
    }: {
      idLead: number;
      files: File[];
      oldDocIds: number[];
    }) => {
      const formData = new FormData();
      let leadDocumentIds: number[] = [];
      if (files?.length) {
        for (let x = 0; x < files.length; x++) {
          formData.append('files', files[x], files[x].name);
        }
        const { data: resListIdFile } = await axios.post('/storages/lead-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        leadDocumentIds = [...resListIdFile.files, ...oldDocIds];
        await asyncUpdateLead({
          idLead: idLead,
          payload: {
            leadDocumentIds
          },
          message: 'Update leads successful'
        });
      }
    },
    handleDeleteDocumentLead: async ({
      idLead,
      oldDocIds
    }: {
      idLead: number;
      oldDocIds: number[];
    }) => {
      await asyncUpdateLead({
        idLead: idLead,
        payload: {
          leadDocumentIds: [...oldDocIds]
        },
        message: 'Update leads successful'
      });
    },
    file,
    idsLeadOpen,
    setIdsLeadOpen,
    showQuickNote,
    setShowQuickNote,
    textQuickNote,
    setTextQuickNote,
    handleAddQuickNote: asyncAddQuickNote
  };
};
