import { Stack } from '@mui/material';
import { FormLogin, FormLoginType } from 'app/pages/LoginPage/components/FormLogin';
import { useFlash } from 'contexts/flash';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { ROLE } from 'const/common';
import { loginAuth } from 'services/auth';
import { Helmet } from 'react-helmet-async';
const storage = new StorageServices();

export const LoginPage = () => {
  const location = useLocation();
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const postLogin = useCallback(
    async (data: FormLoginType) => {
      const isLoginAdmin = location.pathname.includes('/admin');
      const res = await loginAuth(data);
      if (
        (isLoginAdmin && res.role === ROLE.USER) ||
        (!isLoginAdmin && (res.role === ROLE.ADMIN || res.role === ROLE.ROOT_ADMIN))
      ) {
        return setFlash({ type: 'error', message: 'Forbidden' });
      }
      setFlash({ type: 'success', message: 'You are successfully logged in' });
      storage.setAccessToken(res.accessToken);
      storage.setRefreshToken(res.refreshToken);
      storage.setRole(res.role);
      navigate(isLoginAdmin ? '/admin' : '/');
      // eslint-disable-next-line
  }, [location]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(postLogin, []);

  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Surrey Bailiffs - Login</title>
      </Helmet>
      <LayoutContent title={'Welcome Back!'} children={<FormLogin onSubmit={handleSubmitForm} />} />
    </Stack>
  );
};
