import React from 'react';
import { Stack } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { User } from 'types/user';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { DataTable } from 'app/components/elements/TableCustom';

type ActiveTabProps = {
  rows: User[];
  totalRows: number;
  page: number;
  loading?: boolean;
  handlePaginationModelChange: (newPaginationModel: GridPaginationModel) => void;
  handleRowClick: (params: GridRowParams) => void;
  header: GridColDef[];
};
export const ActiveTab = ({
  rows,
  handleRowClick,
  totalRows,
  handlePaginationModelChange,
  page,
  loading,
  header
}: ActiveTabProps) => {
  return (
    <Stack>
      {rows?.length !== 0 && (
        <DataTable
          data={rows}
          totalRows={totalRows}
          paginationModel={{
            page: page > 1 ? page - 1 : 0,
            pageSize: SIZE_PAGE_CONTROL_PANEL
          }}
          loading={loading}
          handlePaginationModelChange={handlePaginationModelChange}
          handleRowClick={handleRowClick}
          header={header}
        />
      )}
    </Stack>
  );
};
