import { FormPostSeizure } from '../app/pages/Admin/RedemptionPage/PostSeizurePage/components/modules/FormPostSeizure/FormPostSeizure';

export type UserDocuments = {
  fileName: string;
  url: string;
  id: number;
};
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  isActive: boolean;
  userDocuments: UserDocuments[];
  doorKnock: boolean;
  earlyMorning: boolean;
  type: string;
};

export type UserHome = {
  id?: number;
  clientName: string;
  fileNumber: string;
  firstName: string;
  lastName: string;
  auctionHouse: string;
  vin: string;
  model: string;
  make: string;
  year: string;
  filePhotosIds: [];
  fileDocumentsIds: [];
  status: string;
  leads: User;
  colour: string;
  plate: string;
};

export type GetFilesParams = {
  size: number;
  page: number;
  earlyMorning: boolean;
  doorKnock: boolean;
  status: string[];
  types: string[];
  fileStatus: string | null;
};

export const TypeLead = {
  POR: 'POR',
  POE: 'POE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  TOW_YARDS: 'TOW_YARDS',
  REFERENCE: 'REFERENCE',
  OTHER_ADDRESS: 'OTHER_ADDRESS',
  OTHER_PHONE_NUMBER: 'OTHER_PHONE_NUMBER',
  OTHER_OPEN_NOTE: 'OTHER_OPEN_NOTE',
  MVS: 'MVS',
  CARFAX: 'CARFAX',
  VIN: 'VIN'
};
export type TypeLeadKeys = keyof typeof TypeLead;
export const LeadInformation = {
  ADDRESS: 'ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  OPEN_NOTE: 'OPEN_NOTE'
};
export type TNote = {
  id?: number;
  leadId?: string;
  typeLead?: string;
  isDelete?: boolean;
  isAdmin?: boolean;
  updatedAt: string;
  deletedAt: string;
  photos?: TFilePhoto[];
  content: string;
  user: {
    role: string;
    firstName: string;
    lastName: string;
  };
};
export type Lead = {
  id?: number;
  type?: string;
  fileId: string;
  address: string;
  city: string;
  province: string;
  phoneNumber: string;
  otherPhoneNumber: string;
  information: string;
  informationType: string;
  ruleOut: boolean;
  doorKnock: boolean;
  earlyMorning: boolean;
  firstName: string;
  lastName: string;
  colour: string;
  plate: string;
  vinStatus: string;
  namePOE: string;
  nameReference: string;
  leadTowYards: {
    id: string;
    content: string;
    userId: string;
    checked: boolean;
    user?: {
      role: string;
    };
  }[];
  leadDocuments: {
    id: string;
    storageId: string;
    url: string;
    name: string;
  }[];
  notes: TNote[];
  note: string;
  isAdmin?: boolean;
};
export type TFilePhoto = {
  id: number;
  fileId: number;
  storageId: number;
  url: string;
  name: string;
  file?: File;
  documentType?: string;
};
export type DispatchFile = {
  id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
};
export type PreSeizure = {
  id?: number;
  arrears: string;
  bailiffFees: string;
  redemptionFees: string;
  lenderFees: string;
  status: string;
  statementSent: Date;
  followUpSent: Date;
};
export type TFile = {
  fileNumber: string;
  make: string;
  model: string;
  vin: string;
  year: string;
  firstName: string;
  id: string;
  lastName: string;
  fileDocuments: TFilePhoto[];
  photos: TFilePhoto[];
  leads: Lead[];
  clientOption: {
    id: number;
    value: string;
    parentId: number;
    children: {
      id: number;
      value: string;
      parentId: number;
    }[];
  };
  auctionDeliveryLocation: {
    id: number;
    value: string;
  };
  colour: string;
  plate: string;
  dispatchFiles: DispatchFile[];
  arrears: number;
  quickNote?: string;
  contractBalance: number;
  ppsaBaseRegistrationNumber: string;
  ppsaRegistrationDate: Date;
  contactDate: Date;
  auctionDeliveryLocationId: number;
  clientOptionsId: number;
  clientOptionsChildId?: number;
  status: string;
  filePostSeizures: FormPostSeizure;
  filePreSeizures: PreSeizure;
  coBorrowerFirstName?: string;
  coBorrowerLastName?: string;
  onHold?: boolean;
};
export type Dashboard = {
  openFile: number;
  onHold: number;
  outOfLeads: number;
  redemption: number;
};
