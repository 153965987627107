import { Stack } from '@mui/material';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import {
  FormForgotPassword,
  FormForgotPasswordType
} from 'app/pages/ForgotPasswordPage/components/FormForgotPassword';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const handleEmailSubmit = async (value: FormForgotPasswordType) => {
    await axios.post('/auth/forgot-password', value);
    navigate(`/forgot-password/code?email=${value.email}`);
  };

  const { asyncCallback } = useAsyncCallback(handleEmailSubmit, []);
  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Surrey Bailiffs - Forgot Password</title>
      </Helmet>
      <LayoutContent
        title={'Forgot Password'}
        textFooter={''}
        textNavigation={'/login'}
        textLink={'Back to Login'}
        textUnderlineNone
        children={<FormForgotPassword onSubmit={asyncCallback} />}
      />
    </Stack>
  );
};
