import { Box, Button, Dialog, Grid, Stack, styled, Typography } from '@mui/material';
import { widthScreen } from 'const/common';
import { StyledParams } from 'types/common';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import theme from 'styles/theme';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { CameraCustom } from 'app/components/elements/Camera';
import { TextError } from 'app/components/elements/TextError';
type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (files: File[], textNote: string) => void;
  textTitle: string;
  defaultValue?: string;
};
export const ModalNewPhotos = ({
  isOpen,
  onClose,
  onSubmit,
  textTitle,
  defaultValue
}: DialogConfirmType) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [note, setNote] = useState<string>(defaultValue ?? '');
  const fileInputPhotoRef = useRef<HTMLInputElement | null>(null);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    setPhotos((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const error = useMemo(() => {
    if (!note) {
      return 'You must have additional notes';
    }
    return '';
  }, [note]);
  const handleClose = useCallback(() => {
    onClose();
    setShowError(false);
    setPhotos([]);
    setNote(defaultValue ?? '');
  }, [onClose, defaultValue]);

  return (
    <DialogStyled open={isOpen} onClose={handleClose}>
      <WrapperDialog width={widthScreen < 500 ? '100%' : '420px'}>
        <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
          <Stack width={'33px'} />
          <TitleStyled>{textTitle}</TitleStyled>
          <img
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
            src={'/icons/icon_cancel.svg'}
            alt={'icon-cancel'}
          />
        </Stack>
        <WrapperContent>
          <HeaderContent>
            <TextStyled>Photos</TextStyled>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => setShowCamera(true)}
              src={'/icons/icon_camera.svg'}
              alt={'icon_camera'}
            />
          </HeaderContent>
          <Stack>
            <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
              {photos.map((file, index) => (
                <Grid item xs={4} sm={4} md={4} lg={4} key={index}>
                  <Box
                    component="img"
                    src={URL.createObjectURL(file)}
                    alt={`Image ${index + 1}`}
                    sx={{
                      width: '100%',
                      height: '120px',
                      objectFit: 'cover',
                      display: 'block',
                      borderRadius: '5px'
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Button
                  onClick={() => {
                    if (fileInputPhotoRef.current) {
                      fileInputPhotoRef.current.click();
                    }
                  }}
                  sx={{
                    width: '100%',
                    height: '105px',
                    borderRadius: '5px',
                    border: `1px solid ${theme.colors.dark1}`,
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}>
                  <img src={'/icons/icon_upload_lage.svg'} alt={'icon'} />
                  <input
                    accept="image/*"
                    ref={fileInputPhotoRef}
                    hidden
                    onChange={handleFileChange}
                    type="file"
                    multiple
                  />
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <TextStyled>Notes</TextStyled>
          <InputStyled
            placeholder={'note'}
            value={note}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value as string)}
          />
          {showError && <TextError errorText={error} />}
          <ButtonCustom
            onClick={() => {
              if (!note) {
                setShowError(true);
                return;
              }
              onSubmit(photos, note);
              setPhotos([]);
              setNote(defaultValue ?? '');
              setShowError(false);
            }}
            label={'Submit'}
          />
        </WrapperContent>
        <CameraCustom isOpen={showCamera} onClose={() => setShowCamera(false)} />
      </WrapperDialog>
    </DialogStyled>
  );
};
const InputStyled = styled('input')(({ theme }: StyledParams) => ({
  width: '100%',
  height: '36px',
  borderRadius: '5px',
  padding: '0 10px',
  border: `1px solid ${theme.colors.light1}`,
  '&:focus': {
    border: `1px solid ${theme.colors.dark1}`
  },
  marginBottom: '14px'
}));
const HeaderContent = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row'
}));
const WrapperDialog = styled(Stack)(() => ({
  borderRadius: '20px',
  flex: 1,
  padding: '15px 32px 32px 32px'
}));
const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '20px',
  fontWeight: 700,
  color: theme.colors.dark1
}));
const WrapperContent = styled(Stack)(() => ({
  flexDirection: 'column',
  gap: '10px',
  marginTop: '34px'
}));
const DialogStyled = styled(Dialog)(({ theme }: StyledParams) => ({
  [theme.breakpoints.up('sm')]: {
    '.MuiDialog-paper': {
      maxWidth: '420px'
    }
  },
  borderRadius: '20px',
  '.MuiDialog-paper': {
    margin: '20px',
    width: '100%',
    minHeight: '450px'
  }
}));
const TextStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '18px',
  fontWeight: 500,
  color: theme.colors.light1
}));
