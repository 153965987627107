import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { leadTypes, statusTypes } from 'const/common';
import { FilterLeadsType } from 'types/common';

type FilterModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: FilterLeadsType) => void;
  onReset?: () => void;
  value?: FilterLeadsType;
  isShowStatus?: boolean;
  isShowChecked?: boolean;
};

const defaultValue: FilterLeadsType = {
  types: [],
  status: []
};

const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  onReset,
  onSubmit,
  isShowStatus,
  isShowChecked,
  value = defaultValue
}) => {
  const [formValues, setFormValues] = useState(value);

  useEffect(() => {
    setFormValues(value);
  }, [value, open]);

  const handleCheckboxChange =
    (field: keyof FilterLeadsType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: event.target.checked
      }));
    };

  const handleFileStatusSelect = (status: string) => {
    setFormValues((prevValues) => {
      const updatedStatusArray = prevValues.status.includes(status)
        ? prevValues?.status.filter((item) => item !== status)
        : [...prevValues.status, status];

      return {
        ...prevValues,
        status: updatedStatusArray
      };
    });
  };

  const handleLeadTypeSelect = (type: string) => {
    setFormValues((prevValues) => {
      const updatedTypesArray = prevValues.types.includes(type)
        ? prevValues.types.filter((item) => item !== type)
        : [...prevValues.types, type];

      return {
        ...prevValues,
        types: updatedTypesArray
      };
    });
  };

  const handleApply = () => {
    onSubmit(formValues);
    onClose();
  };

  const handleReset = () => {
    setFormValues(defaultValue);
    onReset?.();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ margin: 'auto', maxWidth: '464px' }}>
      <Box textAlign={'end'} paddingRight={1} paddingTop={1.25}>
        <IconButton onClick={onClose}>
          <img src={'/icons/icon_cancel_bold.svg'} alt={'icon-cancel'} />
        </IconButton>
      </Box>
      <DialogTitle sx={{ paddingBottom: '10px' }}>
        <Box display={'flex'} justifyContent={'space-between'}>
          Filters
          <Button sx={{ padding: 0 }} onClick={handleReset}>
            Reset all
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '10px' }}>
        {isShowChecked && (
          <>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.earlyMorning}
                    onChange={handleCheckboxChange('earlyMorning')}
                  />
                }
                label="Early Morning"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.doorKnock}
                    onChange={handleCheckboxChange('doorKnock')}
                  />
                }
                label="Door Knock"
              />
            </Box>
          </>
        )}
        {isShowStatus && (
          <>
            <Typography fontSize={14} fontWeight={600} variant="subtitle1" gutterBottom>
              File Status
            </Typography>
            <Box mb={2}>
              {statusTypes.map(({ value, name }) => (
                <Chip
                  key={name}
                  label={value}
                  onClick={() => handleFileStatusSelect(name)}
                  color={formValues.status.includes(name) ? 'primary' : 'default'}
                  style={{ margin: '5px', fontSize: 12, fontWeight: 600 }}
                />
              ))}
            </Box>
          </>
        )}
        <Typography fontSize={14} fontWeight={600} variant="subtitle1" gutterBottom>
          Lead Type
        </Typography>
        <Box maxWidth={'380px'}>
          {leadTypes.map(({ value, name }) => (
            <Chip
              sx={{ padding: '10px 4px', fontSize: 12, fontWeight: 600 }}
              key={name}
              label={value}
              onClick={() => handleLeadTypeSelect(name)}
              color={formValues.types.includes(name) ? 'primary' : 'default'}
              style={{ margin: '10px' }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: ' 0 26px 26px' }}>
        <ButtonCustom onClick={() => handleApply()} type={'submit'} label={'Apply'} />
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
