import { Stack, Box } from '@mui/material';

import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordCodeSchema } from 'utils/validate';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback } from 'react';
type ForgotPasswordCodeForm = {
  code: string;
};
export const ForgotPasswordCode = () => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<ForgotPasswordCodeForm>({ resolver: yupResolver(forgotPasswordCodeSchema) });
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const handleVerifyCode = async (payload: ForgotPasswordCodeForm) => {
    const email = searchParam.get('email');
    if (!email) return;
    await axios.post(`/auth/verify-code`, { code: payload.code, email });
    navigate(`/reset-password?code=${payload.code}&email=${email}`);
  };

  const { asyncCallback: asyncHandleVerifyCode } = useAsyncCallback(handleVerifyCode, []);

  const handleResend = useCallback(async () => {
    const email = searchParam.get('email');
    if (!email) return;
    await axios.post('/auth/forgot-password', { email });
  }, [searchParam]);

  const { asyncCallback: asyncResendCode, loadingCb } = useAsyncCallback(handleResend, []);

  return (
    <form onSubmit={handleSubmit(asyncHandleVerifyCode)}>
      <Stack gap={2.5}>
        <Stack width={'100%'} alignItems={'center'}>
          <Box sx={{ width: '370px', textAlign: ' center', marginTop: '17px' }}>
            We've sent you an email with verification code to reset your password. Please check your
            inbox and enter the code here. If you don't see the email, be sure to check your spam or
            junk folder.
          </Box>
        </Stack>
        <Stack gap={1}>
          <InputText
            inputError={errors.code?.message}
            placeholder={'Enter Code'}
            inputProps={{
              ...register('code')
            }}
          />
        </Stack>
        <ButtonCustom type={'submit'} label={'Continue'} />
        <ButtonCustom
          loading={loadingCb}
          onClick={asyncResendCode}
          type={'button'}
          isCancel
          label={'Resend'}
        />
      </Stack>
    </form>
  );
};
