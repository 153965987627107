import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import React from 'react';
import { FileForm } from '../FilesPage/components/FileForm';
import { useInjection } from './hook';
import { Helmet } from 'react-helmet-async';

export const CreateFilesPage = () => {
  const {
    asyncCreateFile,
    optionClient,
    optionClientChild,
    optionAuctionDeliveryLocation,
    changeOptionClient
  } = useInjection();
  return (
    <LayoutAdminTable title={'New Files'}>
      <Helmet>
        <title>Surrey Bailiffs - New Files</title>
      </Helmet>
      <FileForm
        optionClient={optionClient}
        optionClientChild={optionClientChild}
        optionAuctionDeliveryLocation={optionAuctionDeliveryLocation}
        changeOptionClient={changeOptionClient}
        createForm={asyncCreateFile}
      />
    </LayoutAdminTable>
  );
};
