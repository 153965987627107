import { Link, Stack, styled } from '@mui/material';
import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useInjection } from './hook';
import Typography from '@mui/material/Typography';
import theme from 'styles/theme';
import { StyledParams } from 'types/common';
import { Autocomplete } from '@react-google-maps/api';
import React from 'react';

export const EditAdmin = () => {
  const {
    handleSubmit,
    register,
    errors,
    asyncUpdateUser,
    fileInputDocumentRef,
    handleFileChange,
    fileDocument,
    handleDeleteDocument,
    handleDeleteDocumentOld,
    filesOld,
    inputRef,
    isLoaded
  } = useInjection();
  return (
    <LayoutAdminTable
      title={'Edit Admin'}
      textButton={'Add Document'}
      onClickButton={() => {
        if (fileInputDocumentRef.current) {
          fileInputDocumentRef.current?.click();
        }
      }}>
      <input
        accept="image/pdf"
        ref={fileInputDocumentRef}
        hidden
        onChange={handleFileChange}
        type="file"
        multiple
      />
      <Wrapper gap={2.5} maxWidth={'900px'}>
        <Stack gap={4.5} width={'100%'} flexDirection={'row'}>
          <Stack gap={2.5} flex={1}>
            <InputText
              title={'First Name'}
              inputError={errors.firstName?.message}
              placeholder={'First Name'}
              inputProps={{
                ...register('firstName')
              }}
            />
            <InputText
              title={'Last Name'}
              inputError={errors.lastName?.message}
              placeholder={'Last Name'}
              inputProps={{
                ...register('lastName')
              }}
            />
            <Stack gap={1.25}>
              <Typography fontWeight={500} mb={1} fontSize={'13px'} color={theme.colors.dark1}>
                Documents
              </Typography>
              {filesOld?.map((doc, index) => (
                <Stack
                  key={index}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}>
                  <Stack flexDirection={'row'} gap={1.5} alignItems={'center'}>
                    <img src={'/icons/icon_document.svg'} alt={'icon'} />
                    <NameDocument href={doc.url} target={'_blank'}>
                      {doc.fileName}
                    </NameDocument>
                  </Stack>
                  <img
                    onClick={() => handleDeleteDocumentOld(doc.id)}
                    style={{ cursor: 'pointer' }}
                    src={'/icons/icon_delete_file.svg'}
                    alt={'icon'}
                  />
                </Stack>
              ))}
              {fileDocument.map((doc, index) => (
                <Stack
                  key={index}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}>
                  <Stack flexDirection={'row'} gap={1.5} alignItems={'center'}>
                    <img src={'/icons/icon_document.svg'} alt={'icon'} />
                    <NameDocument href={doc.url} target={'_blank'}>
                      {doc.file.name}
                    </NameDocument>
                  </Stack>
                  <img
                    onClick={() => handleDeleteDocument(index)}
                    style={{ cursor: 'pointer' }}
                    src={'/icons/icon_delete_file.svg'}
                    alt={'icon'}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack gap={2.5} flex={1}>
            {isLoaded && (
              <Autocomplete>
                <InputText
                  inputError={errors.address?.message}
                  placeholder={'Address'}
                  title={'Address'}
                  inputRef={inputRef}
                  inputProps={{
                    ...register('address')
                  }}
                />
              </Autocomplete>
            )}
          </Stack>
        </Stack>
        <ButtonCustom
          style={{ width: '250px', marginTop: '20px' }}
          onClick={handleSubmit(asyncUpdateUser)}
          label={'Save'}
        />
      </Wrapper>
    </LayoutAdminTable>
  );
};

const Wrapper = styled(Stack)(() => ({
  gap: '20px',
  padding: '40px 30px',
  border: '1px solid #ccc',
  borderRadius: '20px',
  alignItems: 'center'
}));
const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 24px)'
}));
