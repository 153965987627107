/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import { PinIcon } from 'assets/icon';
import styled from 'styled-components';
import ButtonHold from 'app/components/elements/ButtonHold';

interface SpotMarkerProps {
  item: {
    latitude: number;
    longitude: number;
    status: string;
  };
  isActive?: boolean;
  activeIndex: number;
  onHold: () => void;
  onPointerUp: (e: React.PointerEvent<any>) => void;
}

function Marker({ item, isActive, activeIndex, onHold, onPointerUp }: SpotMarkerProps) {
  return (
    <OverlayView
      position={{ lat: item.latitude, lng: item.longitude }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Button onHold={onHold} onPointerUp={onPointerUp}>
        {activeIndex !== -1 && <Index>{activeIndex}</Index>}
        <PinComponent $isActive={isActive} status={item.status} />
      </Button>
    </OverlayView>
  );
}

export default React.memo(Marker) as typeof Marker;

const getColorByStatus = (status: string) => {
  switch (status) {
    case 'New':
      return '#9747FF';
    case 'Attention':
      return '#FFAA47';
    case 'Aged':
      return '#FF4773';
    default:
      return '#9747FF';
  }
};

const PinComponent = styled(PinIcon)<{ $isActive?: boolean; status: string }>`
  color: ${({ status }) => getColorByStatus(status)};
  width: 20px;
  height: 32px;
  path {
    stroke: ${({ $isActive }) => ($isActive ? '#22374A' : 'transparent')};
  }
`;

const Button = styled(ButtonHold)`
  transform: translate(-22px, -22px);
  position: relative;
`;

const Index = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  color: white;
  background: #ea4335;
  position: absolute;
  top: -3px;
  right: 0;
`;
