import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminCreateAdminSchema } from 'utils/validate';
import { useNavigate } from 'react-router-dom';
import { adminCreateUser } from 'services/user';
import { useCallback, useRef, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useFlash } from 'contexts/flash';
import axios from 'axios';
import { useJsApiLoader } from '@react-google-maps/api';
export type CreateAdminForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  address: string;
};
export const useInjection = () => {
  const navigate = useNavigate();
  const { setFlash } = useFlash();
  const fileInputDocumentRef = useRef<HTMLInputElement | null>(null);

  const inputRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fileDocument, setFileDocument] = useState<{ file: File; url: string }[]>([]);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateAdminForm>({ resolver: yupResolver(adminCreateAdminSchema) });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const newFiles = Array.from(fileList);
    const fileConvert = newFiles.map((item) => ({
      file: item,
      url: URL.createObjectURL(new Blob([item], { type: item?.type }))
    }));
    setFileDocument((prevFiles) => [...prevFiles, ...fileConvert]);
  };

  const handleDeleteDocument = (indexToDelete: number) => {
    setFileDocument((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  const createUser = useCallback(
    async (value: CreateAdminForm) => {
      const formData = new FormData();
      let arrayIdFile: number[] = [];
      if (fileDocument.length) {
        for (let x = 0; x < fileDocument.length; x++) {
          formData.append('files', fileDocument[x].file, fileDocument[x].file.name);
        }
        const { data: resListIdFile } = await axios.post('/storages/bailiffs-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        arrayIdFile = resListIdFile.files;
      }
      const payload = {
        ...value,
        isActive: false,
        role: 'ADMIN',
        documentIds: arrayIdFile
      };
      await adminCreateUser(payload);
      setFlash({ type: 'success', message: 'Create new admin successful' });
      navigate('/admin/control-panel?tab=admin');
    },
    [fileDocument, navigate, setFlash]
  );

  const { asyncCallback: asyncCreateUser } = useAsyncCallback(createUser, []);

  return {
    control,
    handleSubmit,
    register,
    errors,
    asyncCreateUser,
    setValue,
    fileInputDocumentRef,
    handleFileChange,
    fileDocument,
    handleDeleteDocument,
    showPassword,
    setShowPassword,
    inputRef,
    isLoaded
  };
};
