import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateUserSchema } from 'utils/validate';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { getUserInfo, updateUser } from 'services/auth';
import { UserInfo } from 'services/user';
import { useJsApiLoader } from '@react-google-maps/api';

export type ProfileForm = {
  email: string;
  firstName: string;
  lastName: string;
  address: string;
};
export const useInjection = () => {
  const inputRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm<ProfileForm>({ resolver: yupResolver(updateUserSchema) });

  const [user, setUser] = useState<UserInfo>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { user }: { user: UserInfo } = await getUserInfo();
        reset({ ...user });
        setUser(user);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = useCallback(
    async (data: ProfileForm) => {
      if (!user) return;
      const payload: Partial<ProfileForm> = {};
      if (user.address !== data.address) payload.address = data.address;
      if (user.email !== data.email) payload.email = data.email;
      if (user.firstName !== data.firstName) payload.firstName = data.firstName;
      if (user.lastName !== data.lastName) payload.lastName = data.lastName;
      await updateUser(payload as ProfileForm);
      setModalConfirm(false);
    },
    [user]
  );

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(onSubmit, []);

  return {
    errors,
    user,
    register,
    handleSubmit,
    showPassword,
    setShowPassword,
    handleSubmitForm,
    modalConfirm,
    setModalConfirm,
    inputRef,
    isLoaded
  };
};
