import { Box, Grid, IconButton, InputAdornment, Link, Stack, styled } from '@mui/material';
import { InputText } from 'app/components/elements/InputText';
import Typography from '@mui/material/Typography';
import theme from 'styles/theme';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import React from 'react';
import { StyledParams } from 'types/common';
import { ClientOption, CreateFiles, useInjection } from './hook';
import { AddNote, LeadBoxMemo } from 'app/pages/FileDetail/components/LeadBox';
import { Lead, TFile } from 'types/user';
import { ModalNewLead, NewLeadForm } from 'app/components/modules/ModalNewLead';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLoading } from 'contexts/loading';
import CustomDatePicker from 'app/components/elements/InputDate';
import { Controller } from 'react-hook-form';
import { InputSelect } from 'app/components/elements/InputSelect';
import dayjs from 'dayjs';
import { TypeOption } from 'app/pages/Admin/NewFileSetUpPage/hook';
import PaidIcon from '@mui/icons-material/AttachMoney';
import { InputNumber } from 'app/components/elements/InputNumber';
import { includes } from 'lodash';
import { RadioGroupCustom } from 'app/components/elements/RadioGroupCustom';
import { optionArrearsOther } from 'const/option';
import MultiSelectAutocomplete, {
  OptionAutocomplete
} from 'app/components/elements/MultiSelectAutocomplete';
import { IconCollapsible } from 'assets/icons/IconCollapsible';
export type FileFormProps = {
  createForm: ({
    value,
    fileDocument,
    filePhotos
  }: {
    value: CreateFiles;
    filePhotos?: { file: File; url: string }[];
    fileDocument?: { file: File; url: string }[];
  }) => void;
  isEdit?: boolean;
  id?: number;
  leads?: Lead[];
  handleAddNewLead?: (value: NewLeadForm) => void;
  handleDeleteNoteLead?: (idNote: number) => void;
  handleAddTowYards?: ({
    idLead,
    towYards
  }: {
    idLead: number;
    towYards: { value: string; checked: boolean; user?: { role: string } }[];
  }) => void;
  handleAddNewNote?: ({ isNewLead, idLead, textNote, isAdmin }: AddNote) => void;
  handleEditNote?: (idLead: number, idNote: number, content: string) => void;
  loadingModalNewLead?: boolean;
  initFile?: TFile;
  handleDeleteLead?: (id: number) => void;
  handleDeleteFile?: () => void;
  handleDeleteTowYards?: ({ idTowYards, idLead }: { idLead: number; idTowYards: number }) => void;
  handleChangeVinStatus?: ({ vinStatus, idLead }: { idLead: number; vinStatus: string }) => void;
  handleUploadFileCarfax?: ({
    files,
    idLead,
    oldDocIds
  }: {
    idLead: number;
    files: File[];
    oldDocIds: number[];
  }) => void;
  handleDeleteDocumentLead?: ({
    idLead,
    oldDocIds
  }: {
    idLead: number;
    oldDocIds: number[];
  }) => void;
  optionClient?: ClientOption[];
  optionClientChild?: ClientOption[];
  optionAuctionDeliveryLocation?: TypeOption[];
  changeOptionClient?: (value: number) => void;
  handleCloseNotFound?: () => void;
  handleCloseClient?: () => void;
};
export const FileForm = ({
  createForm,
  isEdit,
  id,
  leads,
  handleAddNewLead,
  handleEditNote,
  handleAddNewNote,
  handleDeleteNoteLead,
  handleDeleteLead,
  initFile,
  handleDeleteFile,
  handleAddTowYards,
  handleDeleteTowYards,
  handleChangeVinStatus,
  handleUploadFileCarfax,
  handleDeleteDocumentLead,
  optionClient,
  optionClientChild,
  optionAuctionDeliveryLocation,
  changeOptionClient,
  handleCloseNotFound,
  handleCloseClient
}: FileFormProps) => {
  const { loading } = useLoading();
  const {
    handleSubmit,
    handleDeleteDocument,
    register,
    errors,
    fileDocument,
    fileInputDocumentRef,
    handleFileChange,
    handleChangePhotos,
    handleDeletePhotos,
    fileInputPhotosRef,
    filePhotos,
    onSubmitForm,
    fileDocumentsInit,
    photosInit,
    setValue,
    isAdmin,
    control,
    isCompleted,
    idsLeadOpen,
    watchArrears,
    optionsUser,
    setIdsLeadOpen,
    modalNewLead,
    setModalNewLead,
    openForm,
    setOpenForm
  } = useInjection({ createForm, id, isEdit, initFile, leads });

  return (
    <Stack gap={2}>
      <WrapperBox gap={2.5} width={'100%'} flexDirection={'row'}>
        {isEdit && (
          <Stack zIndex={99} width={'max-content'}>
            <Typography style={{ fontSize: '16px', fontWeight: 700, paddingLeft: '10px' }}>
              {openForm ? 'Close' : 'Open'}
            </Typography>
            <IconButton onClick={() => setOpenForm(!openForm)}>
              <IconCollapsible open={openForm} />
            </IconButton>
          </Stack>
        )}
        <Wrapper
          className={`animated-container ${openForm || !isEdit ? 'open' : ''}`}
          maxWidth={isEdit ? 'unset' : '900px'}>
          <Stack gap={4.5} width={'100%'} flexDirection={'row'}>
            <Stack gap={2.5} width={'calc(50% - 18px)'}>
              <InputText
                title={'File number'}
                inputError={errors.fileNumber?.message}
                placeholder={'File number'}
                inputProps={{
                  ...register('fileNumber'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Year'}
                inputError={errors.year?.message}
                placeholder={'Year'}
                inputProps={{
                  ...register('year'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Make'}
                inputError={errors.make?.message}
                placeholder={'Make'}
                inputProps={{
                  ...register('make'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Model'}
                inputError={errors.model?.message}
                placeholder={'Model'}
                inputProps={{
                  ...register('model'),
                  disabled: isCompleted
                }}
              />
              <Controller
                name={'arrears'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    title={'Arrears'}
                    inputError={errors.arrears?.message}
                    placeholder={'Arrears'}
                    endAdornment={
                      <InputAdornment position="end">
                        <PaidIcon />
                      </InputAdornment>
                    }
                    inputProps={{
                      disabled: isCompleted
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {watchArrears === 0 && (
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioGroupCustom
                      onChange={onChange}
                      value={value || ''}
                      label={'Options'}
                      options={optionArrearsOther}
                    />
                  )}
                  name={'arrearsOther'}
                />
              )}
              <Controller
                name={'clientOptionsId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    inputError={errors.clientOptionsId?.message}
                    style={{ height: 50 }}
                    title={'Client'}
                    placeholder={'Client'}
                    options={
                      optionClient?.map((item: ClientOption) => ({
                        value: item.id,
                        label: item.value
                      })) || []
                    }
                    disabled={isCompleted}
                    value={value}
                    onChange={(event) => {
                      changeOptionClient && changeOptionClient(event.target.value as number);
                      setValue('clientOptionsChildId', null);
                      onChange(event);
                    }}
                  />
                )}
              />
              {optionClientChild?.length !== 0 && (
                <Controller
                  name={'clientOptionsChildId'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputSelect
                      inputError={errors.clientOptionsChildId?.message}
                      style={{ height: 50 }}
                      title={'Client Children'}
                      placeholder={'Client Children'}
                      options={
                        optionClientChild?.map((item: ClientOption) => ({
                          value: item.id,
                          label: item.value
                        })) || []
                      }
                      disabled={isCompleted}
                      value={value}
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  )}
                />
              )}
              <Controller
                name={'auctionDeliveryLocationId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    inputError={errors.auctionDeliveryLocationId?.message}
                    style={{ height: 50 }}
                    title={'Auction Delivery Location'}
                    placeholder={'Auction Delivery Location'}
                    options={optionAuctionDeliveryLocation}
                    value={value}
                    disabled={isCompleted}
                    onChange={(event) => {
                      onChange(event);
                    }}
                  />
                )}
              />
              <InputText
                title={'PPSA Base Registration Number'}
                inputError={errors.ppsaBaseRegistrationNumber?.message}
                placeholder={'PPSA Base Registration Number'}
                inputProps={{
                  ...register('ppsaBaseRegistrationNumber'),
                  disabled: isCompleted
                }}
              />
              <Controller
                name={'ppsaRegistrationDate'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    inputError={errors.ppsaRegistrationDate?.message}
                    title={'PPSA Registration Date'}
                    value={value ? dayjs(value) : null}
                    onChange={onChange}
                    datePickerProps={{
                      disabled: isCompleted,
                      maxDate: dayjs()
                    }}
                  />
                )}
              />
              <Controller
                name={'userAssign'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MultiSelectAutocomplete
                      value={value}
                      title={'Assign to Bailiff'}
                      label={'Assign to Bailiff'}
                      onChange={(_, value: OptionAutocomplete[]) => {
                        onChange(value);
                      }}
                      options={optionsUser}
                    />
                  );
                }}
              />
              <Stack gap={1.25}>
                <Stack gap={1.25}>
                  <Typography fontWeight={500} mb={1} fontSize={'13px'} color={theme.colors.dark1}>
                    Photos
                  </Typography>
                  <Grid container spacing={1.25} justifyContent="flex-start" alignItems="center">
                    {photosInit?.map((photo, index) => (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        key={index}
                        style={{ position: 'relative' }}>
                        <Box
                          component="img"
                          src={photo.url}
                          alt={`Image ${index + 1}`}
                          sx={{
                            width: '100%',
                            height: '120px',
                            objectFit: 'cover',
                            display: 'block',
                            borderRadius: '10px'
                          }}
                        />
                        <Stack
                          onClick={() => {
                            const newFileDoc = photosInit.filter((item) => item.id !== photo.id);
                            setValue('photos', newFileDoc);
                          }}
                          position={'absolute'}
                          style={{
                            top: 2,
                            right: 0,
                            width: '20px',
                            height: '20px',
                            borderRadius: '10px',
                            border: '2px solid red',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#fff',
                            cursor: 'pointer'
                          }}>
                          <HighlightOffIcon
                            color={'error'}
                            onClick={() => handleDeleteDocument(index)}
                          />
                        </Stack>
                      </Grid>
                    ))}
                    {filePhotos.map((photo, index) => (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        key={index}
                        style={{ position: 'relative' }}>
                        <Box
                          component="img"
                          src={photo.url}
                          alt={`Image ${index + 1}`}
                          sx={{
                            width: '100%',
                            height: '120px',
                            objectFit: 'cover',
                            display: 'block',
                            borderRadius: '10px'
                          }}
                        />
                        <Stack
                          onClick={() => handleDeletePhotos(index)}
                          position={'absolute'}
                          style={{
                            top: 2,
                            right: 0,
                            width: '20px',
                            height: '20px',
                            borderRadius: '10px',
                            border: '2px solid red',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#fff',
                            cursor: 'pointer'
                          }}>
                          <Typography
                            color={'red'}
                            lineHeight={'9px'}
                            fontWeight={700}
                            marginTop={'-3px'}>
                            x
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
                {!isCompleted && (
                  <ButtonCustom
                    onClick={() => {
                      if (fileInputPhotosRef.current) {
                        fileInputPhotosRef.current?.click();
                      }
                    }}
                    style={{ width: '140px', height: '50px' }}
                    label={'Add Photos'}
                  />
                )}
                <input
                  accept="image/*"
                  ref={fileInputPhotosRef}
                  hidden
                  onChange={handleChangePhotos}
                  type="file"
                  multiple
                />
              </Stack>
            </Stack>
            <Stack gap={2.5} width={'calc(50% - 18px)'}>
              <InputText
                title={'VIN'}
                inputError={errors.vin?.message}
                placeholder={'VIN'}
                inputProps={{
                  ...register('vin'),
                  disabled: isCompleted
                }}
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
              />
              <InputText
                title={'First Name'}
                inputError={errors.firstName?.message}
                placeholder={'First Name'}
                inputProps={{
                  ...register('firstName'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Last Name'}
                inputError={errors.lastName?.message}
                placeholder={'Last Name'}
                inputProps={{
                  ...register('lastName'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Co-Borrower First Name'}
                inputError={errors.coBorrowerFirstName?.message}
                placeholder={'Co-Borrower First Name'}
                inputProps={{
                  ...register('coBorrowerFirstName'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Co-Borrower Last Name'}
                inputError={errors.coBorrowerLastName?.message}
                placeholder={'Co-Borrower Last Name'}
                inputProps={{
                  ...register('coBorrowerLastName'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Plate'}
                inputError={errors.plate?.message}
                placeholder={'Plate'}
                inputProps={{
                  ...register('plate'),
                  disabled: isCompleted
                }}
              />
              <InputText
                title={'Colour'}
                inputError={errors.colour?.message}
                placeholder={'Colour'}
                inputProps={{
                  ...register('colour'),
                  disabled: isCompleted
                }}
              />
              <Controller
                name={'contractBalance'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    title={'Contract Balance'}
                    inputError={errors.contractBalance?.message}
                    placeholder={'Contract Balance'}
                    endAdornment={
                      <InputAdornment position="end">
                        <PaidIcon />
                      </InputAdornment>
                    }
                    inputProps={{
                      disabled: isCompleted
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name={'contactDate'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    inputError={errors.contactDate?.message}
                    title={'Contact Date'}
                    value={value ? dayjs(value) : null}
                    onChange={onChange}
                    datePickerProps={{
                      disabled: isCompleted,
                      maxDate: dayjs()
                    }}
                  />
                )}
              />
              <InputText
                title={'Quick Note'}
                inputError={errors.quickNote?.message}
                placeholder={'Quick Note'}
                inputProps={{
                  ...register('quickNote'),
                  disabled: isCompleted
                }}
              />
              <Stack gap={1.25}>
                <Stack gap={1.25}>
                  <Typography fontWeight={500} mb={1} fontSize={'13px'} color={theme.colors.dark1}>
                    Documents
                  </Typography>
                  {fileDocumentsInit?.map((doc, index) => (
                    <Stack
                      key={index}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Stack
                        width={'calc(100% - 62px)'}
                        flexDirection={'row'}
                        gap={1.5}
                        alignItems={'center'}>
                        <img src={'/icons/icon_document.svg'} alt={'icon'} />
                        <NameDocument href={doc.url} target={'_blank'}>
                          {doc.name}
                        </NameDocument>
                      </Stack>
                      {!doc.documentType && (
                        <img
                          onClick={() => {
                            const newFileDoc = fileDocumentsInit.filter(
                              (item) => item.id !== doc.id
                            );
                            setValue('fileDocuments', newFileDoc);
                          }}
                          style={{ cursor: 'pointer' }}
                          src={'/icons/icon_delete_file.svg'}
                          alt={'icon'}
                        />
                      )}
                    </Stack>
                  ))}
                  {fileDocument.map((doc, index) => (
                    <Stack
                      key={index}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Stack
                        width={'calc(100% - 62px)'}
                        flexDirection={'row'}
                        gap={1.5}
                        alignItems={'center'}>
                        <img src={'/icons/icon_document.svg'} alt={'icon'} />
                        <NameDocument href={doc.url} target={'_blank'}>
                          {doc.file.name}
                        </NameDocument>
                      </Stack>
                      <img
                        onClick={() => handleDeleteDocument(index)}
                        style={{ cursor: 'pointer' }}
                        src={'/icons/icon_delete_file.svg'}
                        alt={'icon'}
                      />
                    </Stack>
                  ))}
                </Stack>
                {!isCompleted && (
                  <ButtonCustom
                    onClick={() => {
                      if (fileInputDocumentRef.current) {
                        fileInputDocumentRef.current?.click();
                      }
                    }}
                    style={{ width: '140px', height: '50px' }}
                    label={'Add Document'}
                  />
                )}
                <input
                  accept=".pdf"
                  ref={fileInputDocumentRef}
                  hidden
                  onChange={handleFileChange}
                  type="file"
                  multiple
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'center'} flexWrap={'wrap'} gap={2.5}>
            {!isCompleted && (
              <ButtonCustom
                style={{ width: '200px', marginTop: '20px' }}
                onClick={handleSubmit(onSubmitForm)}
                loading={loading}
                label={isEdit ? 'Save' : 'Create File'}
              />
            )}
            {isEdit && (
              <ButtonCustom
                style={{ width: '200px', marginTop: '20px' }}
                onClick={handleCloseNotFound}
                loading={loading}
                label={'Close Not Found'}
              />
            )}
            {isEdit && (
              <ButtonCustom
                style={{ width: '200px', marginTop: '20px' }}
                onClick={handleCloseClient}
                loading={loading}
                label={'Close Client Request'}
              />
            )}
            {isEdit && (
              <ButtonCustom
                isDelete
                style={{ width: '200px', marginTop: '20px' }}
                onClick={handleDeleteFile}
                loading={loading}
                label={'Delete File'}
              />
            )}
          </Stack>
        </Wrapper>
        {isEdit && (
          <Wrapper width={'37.5%'} alignItems={'center'}>
            <ListLead>
              {leads?.map((item, index) => (
                <LeadBoxMemo
                  onOpenLead={() => {
                    const hasId = includes(idsLeadOpen, Number(item.id));
                    if (hasId) {
                      setIdsLeadOpen((prevState) =>
                        prevState.filter((pre) => pre !== Number(item.id))
                      );
                      return;
                    }
                    setIdsLeadOpen((prevState) => [...prevState, Number(item.id)]);
                  }}
                  isOpen={includes(idsLeadOpen, Number(item.id))}
                  isCompleted={isCompleted}
                  isAdmin={isAdmin}
                  key={index}
                  item={item}
                  handleDeleteNoteLead={handleDeleteNoteLead}
                  handleAddNewNote={handleAddNewNote}
                  handleEditNote={handleEditNote}
                  handleDeleteLead={() => {
                    if (item.id) {
                      handleDeleteLead && handleDeleteLead(item.id);
                    }
                  }}
                  handleAddTowYards={handleAddTowYards}
                  handleDeleteTowYards={handleDeleteTowYards}
                  handleChangeVinStatus={handleChangeVinStatus}
                  handleUploadFileCarfax={handleUploadFileCarfax}
                  handleDeleteDocumentLead={handleDeleteDocumentLead}
                />
              ))}
              {!isCompleted && (
                <WrapperButton>
                  <ButtonCustom
                    onClick={() => {
                      setModalNewLead(true);
                    }}
                    label={'New Lead'}
                  />
                </WrapperButton>
              )}
            </ListLead>
            {!isCompleted && handleAddNewLead && (
              <ModalNewLead
                isAdmin={isAdmin}
                isOpen={modalNewLead}
                onClose={() => setModalNewLead(false)}
                onSubmit={(value: NewLeadForm) => {
                  handleAddNewLead(value);
                  setModalNewLead(false);
                }}
                textTitle={'Add New Lead'}
              />
            )}
          </Wrapper>
        )}
      </WrapperBox>
    </Stack>
  );
};
const WrapperBox = styled(Stack)(() => ({
  '.animated-container': {
    transition: 'transform 0.3s ease, opacity 0.3s ease, width 0.3s ease',
    transform: 'translateX(-100% - 30px)',
    opacity: 0,
    width: '0',
    overflow: 'hidden'
  },
  '.animated-container.open': {
    transform: 'translateX(0)',
    opacity: 1,
    width: '62.5%'
  }
}));
const Wrapper = styled(Stack)(() => ({
  padding: '40px 30px',
  border: '1px solid #ccc',
  borderRadius: '20px',
  alignItems: 'center',
  gap: 20
}));
const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 60px)'
}));
const ListLead = styled(Stack)(() => ({
  width: '100%',
  gap: 10,
  paddingTop: '20px'
}));
const WrapperButton = styled(Stack)(() => ({
  paddingBottom: '25px',
  margin: '0 10px'
}));
