import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { createPreSeizure } from 'services/files';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormPreSeizure } from '../components/modules/FormPreSeizure/FormPreSeizure';
import axios from 'axios';

export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onCreatePreSeizure = useCallback(async (formData: FormPreSeizure) => {
    const formData2 = new FormData();
    let filePhotosIds: number[] = [];
    const filePhotos = formData.preSeizuresDocuments?.filter((item) => item.file);
    if (filePhotos?.length) {
      for (let x = 0; x < filePhotos.length; x++) {
        formData2.append('files', filePhotos[x]!.file!, filePhotos[x]!.file!.name);
      }
      const { data: resListIdFile } = await axios.post(
        '/storages/pre_seizures_document',
        formData2,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      filePhotosIds = resListIdFile.files;
      formData2.delete('files');
    }
    await createPreSeizure({ ...formData, ...{ documentIds: filePhotosIds } });
    setFlash({ type: 'success', message: 'Create pre seizure successful' });
    navigate(`/admin/redemptions`);
  }, []);

  return {
    searchParams,
    onCreatePreSeizure,
    handleOnBack: () => navigate(`/admin/redemptions`)
  };
};
