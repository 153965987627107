import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconCollapsibleProps {
  open: boolean;
}

export const IconCollapsible: React.FC<IconCollapsibleProps> = ({ open }) => (
  <SvgIcon>
    <rect fill={'#CCC'} height="20" rx="3" width="20" x="2" y="2.125" />
    <rect fill={'#000'} height="16" rx="2" width="8" x={open ? 12 : 4} y="4.125" />
  </SvgIcon>
);
