import React, { ReactElement } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-x-${index}`
  };
}
type TabsCustomProps = {
  tab: number;
  onChangeTab: (tab: number) => void;
  headerTab: { label: string; value: number }[];
  contentTab: ReactElement;
};
export const TabsCustom = ({ tab, contentTab, headerTab, onChangeTab }: TabsCustomProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          sx={{ paddingBottom: 0 }}
          value={tab}
          onChange={(e, value) => {
            onChangeTab(value);
          }}
          aria-label="basic tabs example">
          {headerTab?.map((hd) => <Tab key={hd.value} label={hd.label} {...a11yProps(hd.value)} />)}
        </Tabs>
        <CustomTabPanel index={tab} value={tab}>
          {contentTab}
        </CustomTabPanel>
      </Box>
    </Box>
  );
};
