/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterLeadFormValues } from 'app/pages/MapPage/type';
import axios from 'axios';
import * as qs from 'qs';
import { formatPayload } from 'utils/index';

export type CreateLeadPayload = {
  fileId: number;
  type: string;
  address: string;
  city: string;
  province: string;
  phoneNumber: string;
  information: string;
  ruleOut: boolean;
  earlyMorning: boolean;
  doorKnock: boolean;
  notes?: {
    id?: number;
    content?: string;
    isDelete?: boolean;
    notePhotosIds?: number[];
    updatedAt?: string;
  }[];
};

export interface Lead {
  id: number;
  type: string;
  address: string;
  city: string;
  province: string;
  longitude: number;
  latitude: number;
  phoneNumber: string;
  information: any;
  ruleOut: boolean;
  note: any;
  fileId: string;
  earlyMorning: any;
  doorKnock: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  file: Files;
}

export interface Files {
  id: string;
  clientName: string;
  fileNumber: string;
  firstName: string;
  lastName: string;
  auctionHouse: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  colour: string;
  plate: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  leadId?: number;
  phoneNumber?: string;
  address?: string;
  leadTitle?: string;
}

export const getLead = async (payload: FilterLeadFormValues) => {
  const { data: res } = await axios.get('/leads', { params: formatPayload(payload) });
  return res;
};

type GetLeadsParams = {
  size: number;
  page: number;
  earlyMorning: boolean;
  doorKnock: boolean;
  status: string[];
  types: string[];
  category: string;
};

export type payloadLoadCreateReport = {
  fileId: number;
  kilometers: number;
  key: boolean;
  addressLeadId?: number;
  otherAddress?: string;
  customersLeadId?: number;
  otherCustomerPOR?: string;
  run: boolean;
  signedVsa: boolean;
  possibleRedemption: boolean;
  transportRequired: boolean;
  billsIncurred: boolean;
  conditionReport: string;
  towingCompany: string;
  vsaDocumentIds: number[];
  seizureReportPhotoIds: number[];
  removedToId?: number;
  removedToOther?: string;
  otherBills?: string;
  otherTransport?: string;
};

export const createLead = async (payload: Partial<CreateLeadPayload>) => {
  const { data: res } = await axios.post('/leads', payload);
  return res;
};
export const updateLead = async (id: number, payload: Partial<CreateLeadPayload>) => {
  const { data: res } = await axios.put(`/leads/${id}`, payload);
  return res;
};
export const deleteLead = async (id: number) => {
  await axios.delete(`/admin/leads/${id}`);
};

export const getLeads = async (params: Partial<GetLeadsParams>) => {
  const payload = formatPayload({
    ...params,
    types: params.types,
    status: params.status,
    category: params.category
  });
  const query = qs.stringify(payload);
  const { data } = await axios.get(`/leads?${query}`);
  return data;
};

export const updateSeizure = async (payload: Partial<payloadLoadCreateReport>) => {
  const { data: res } = await axios.post(`/seizure-reports`, payload);
  return res;
};
