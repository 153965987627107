import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack, styled, Typography } from '@mui/material';
import { StyledParams } from 'types/common';
import { HeaderPage } from 'app/components/elements/HeaderPage';
import { HeaderFile } from 'app/pages/FileDetail/components/HeaderFileDetail';
import { LeadBoxMemo } from 'app/pages/FileDetail/components/LeadBox';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { useInjection } from 'app/pages/FileDetail/hook';
import { ModalNewLead } from 'app/components/modules/ModalNewLead';
import { BoxPhotoMemo } from 'app/pages/FileDetail/components/BoxPhoto';
import { BoxFileDocumentMemo } from 'app/pages/FileDetail/components/BoxFileDocument';
import { SummaryLog } from './components/SummaryLog';
import { includes } from 'lodash';

function FileDetail() {
  const {
    leads,
    modalNewLead,
    setModalNewLead,
    handleCreateLead,
    handleDeleteNoteLead,
    file,
    handleAddNotePhotos,
    handleAddNewNote,
    handleEditNote,
    handleChooseRuleOut,
    handleChooseEarlyMorning,
    handleChooseDoorKnock,
    onClickSeized,
    handleAddTowYards,
    handleDeleteTowYards,
    handleChangeVinStatus,
    handleUploadFileCarfax,
    handleDeleteDocumentLead,
    handleUpdateBailiff,
    idsLeadOpen,
    setIdsLeadOpen,
    showQuickNote,
    setShowQuickNote,
    textQuickNote,
    setTextQuickNote,
    handleAddQuickNote
  } = useInjection();
  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - File Details</title>
      </Helmet>
      <HeaderPage title={'File Details'} isBack />
      <Stack style={{ padding: '20px 16px 0 16px' }}>
        <HeaderFile
          textQuickNote={textQuickNote}
          setTextQuickNote={setTextQuickNote}
          file={file}
          showQuickNote={showQuickNote}
          setShowQuickNote={setShowQuickNote}
          handleAddQuickNote={handleAddQuickNote}
        />
      </Stack>
      <WrapperLead>
        <TitleLead style={{ paddingLeft: '11px' }}>Lead Details</TitleLead>
        <ListLead>
          {leads?.map((item, index) => (
            <LeadBoxMemo
              onOpenLead={() => {
                const hasId = includes(idsLeadOpen, Number(item.id));
                if (hasId) {
                  setIdsLeadOpen((prevState) => prevState.filter((pre) => pre !== Number(item.id)));
                  return;
                }
                setIdsLeadOpen((prevState) => [...prevState, Number(item.id)]);
              }}
              isOpen={includes(idsLeadOpen, Number(item.id))}
              handleChooseEarlyMorning={handleChooseEarlyMorning}
              handleChooseDoorKnock={handleChooseDoorKnock}
              handleChooseRuleOut={handleChooseRuleOut}
              handleAddNewNote={handleAddNewNote}
              handleEditNote={handleEditNote}
              handleAddNewPhotos={(files, textNote, isEarly) =>
                handleAddNotePhotos({ id: item?.id, files, textNote, isEarly })
              }
              key={index}
              item={item}
              handleDeleteNoteLead={handleDeleteNoteLead}
              handleAddTowYards={handleAddTowYards}
              handleDeleteTowYards={handleDeleteTowYards}
              handleChangeVinStatus={handleChangeVinStatus}
              handleUploadFileCarfax={handleUploadFileCarfax}
              handleDeleteDocumentLead={handleDeleteDocumentLead}
            />
          ))}
          <WrapperButton>
            <ButtonCustom
              onClick={() => {
                setModalNewLead(true);
              }}
              label={'New Lead'}
            />
          </WrapperButton>
        </ListLead>
      </WrapperLead>
      <PhotoStack>
        <BoxPhotoMemo photos={file?.photos} />
      </PhotoStack>
      <BoxFileDocumentMemo documents={file?.fileDocuments} />
      <Stack px={2.5} mt={2}>
        <SummaryLog leads={leads} />
      </Stack>
      <Stack px={2} gap={2}>
        <ButtonCustom sx={{ width: '100%' }} label={'SEIZED'} onClick={onClickSeized} fullWidth />
        <ButtonCustom
          sx={{ width: '100%' }}
          label={'Redemption'}
          isCancel
          onClick={handleUpdateBailiff}
          fullWidth
        />
      </Stack>
      <ModalNewLead
        isOpen={modalNewLead}
        onClose={() => setModalNewLead(false)}
        onSubmit={handleCreateLead}
        textTitle={'Add New Lead'}
      />
    </Stack>
  );
}
export default FileDetail;

const TitleLead = styled(Typography)(({ theme }: StyledParams) => ({
  color: theme.colors.light1,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '100%'
}));
const PhotoStack = styled(Typography)(({ theme }: StyledParams) => ({
  padding: '20px 0',
  margin: '0 16px',
  borderBottom: `1px solid ${theme.colors.light3}`
}));
const WrapperLead = styled(Stack)(() => ({
  padding: '20px 5px 0 5px'
}));
const WrapperButton = styled(Stack)(({ theme }: StyledParams) => ({
  paddingBottom: '25px',
  margin: '0 10px',
  borderBottom: `1px solid ${theme.colors.light3}`
}));
const ListLead = styled(Stack)(() => ({
  width: '100%',
  gap: 10,
  paddingTop: '20px'
}));
