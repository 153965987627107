import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { User } from 'types/user';
import { adminGetAdmins, adminGetArchive, adminGetUser } from 'services/user';
import { SIZE_PAGE_CONTROL_PANEL } from 'const/paging';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { ROLE } from 'const/common';
import { StorageServices } from 'services/storage';
import { ClientOption, TClient } from './ClientOptionTab/hooks';
import { getClientOption, updateClientOption, UpdateClientOption } from 'services/client';
import { compact, omit } from 'lodash';
import {
  AuctionDelivery,
  getAuctionDeliveryLocations,
  updateAuctionDeliveryLocations
} from 'services/auction';
import { TAuctionForm } from './AuctionDeliveryLocationTab/hooks';
import { useFlash } from 'contexts/flash';
const storage = new StorageServices();

export const TAB = {
  BAILIFFS: 'bailiffs',
  ARCHIVE: 'archive',
  AUCTION: 'auction',
  CLIENT: 'client',
  AUCTION3: 'auction3',
  ADMIN: 'admin'
};

export const TAB_HEADER = [
  { label: 'Bailiffs', param: TAB.BAILIFFS, value: 0 },
  { label: 'Archive', param: TAB.ARCHIVE, value: 1 },
  { label: 'Auction Delivery Locations', param: TAB.AUCTION, value: 2 },
  { label: 'Clients', param: TAB.CLIENT, value: 3 },
  { label: 'Admin', param: TAB.ADMIN, value: 4, role: ROLE.ROOT_ADMIN }
];

type RowState = {
  rows: User[];
  total: number;
};

type PageState = {
  [key: string]: number;
};

export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const { loading } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rowStates, setRowStates] = useState<{ [key: string]: RowState }>({
    [TAB.BAILIFFS]: { rows: [], total: 0 },
    [TAB.ARCHIVE]: { rows: [], total: 0 },
    [TAB.AUCTION]: { rows: [], total: 0 },
    [TAB.CLIENT]: { rows: [], total: 0 },
    [TAB.ADMIN]: { rows: [], total: 0 }
  });
  const [optionAuctionDelivery, setOptionAuctionDelivery] = useState<AuctionDelivery[]>([]);
  const [optionClient, setOptionClient] = useState<TClient[]>([]);
  const [pages, setPages] = useState<PageState>({
    [TAB.BAILIFFS]: 1,
    [TAB.ARCHIVE]: 1,
    [TAB.ADMIN]: 1,
    [TAB.AUCTION]: 1,
    [TAB.CLIENT]: 1
  });

  const tabHeader = useMemo(() => {
    const role = storage.getRole();
    if (role === ROLE.ADMIN) {
      return TAB_HEADER.filter((item) => item.role !== ROLE.ROOT_ADMIN);
    }
    return TAB_HEADER;
  }, [storage.getRole()]);

  const getInitData = useCallback(async () => {
    const role = storage.getRole();
    const [bailiff, dataOptionClients, dataAuctionHouses, archive] = await Promise.all([
      adminGetUser({ page: 1, size: SIZE_PAGE_CONTROL_PANEL }),
      getClientOption(),
      getAuctionDeliveryLocations(),
      adminGetArchive({ page: 1, size: SIZE_PAGE_CONTROL_PANEL })
    ]);
    setOptionClient(dataOptionClients.clientOptions);
    setOptionAuctionDelivery(
      dataAuctionHouses.auctionHouses.map((item: { value: string; id: number }) => ({
        value: item.value,
        idOption: item.id
      }))
    );
    setRowStates((prevState) => ({
      ...prevState,
      [TAB.BAILIFFS]: { rows: bailiff.users, total: bailiff.total },
      [TAB.ARCHIVE]: { rows: archive.archives, total: archive.total }
    }));
    if (role === ROLE.ROOT_ADMIN) {
      const data = await adminGetAdmins({ page: 1, size: SIZE_PAGE_CONTROL_PANEL });
      setRowStates((prevState) => ({
        ...prevState,
        [TAB.ADMIN]: { rows: data.users, total: data.total }
      }));
    }
  }, []);
  const { asyncCallback: asyncGetInitData } = useAsyncCallback(getInitData, []);

  useEffect(() => {
    asyncGetInitData();
  }, []);

  const fetchDataForTab = useCallback(async (page: number, tab: string) => {
    const rows: User[] = [];
    let total = 0;
    if (tab === TAB.BAILIFFS) {
      const data = await adminGetUser({ page: page, size: SIZE_PAGE_CONTROL_PANEL });
      rows.push(...data.users);
      total = data.total as number;
    }

    if (tab === TAB.ADMIN) {
      const data = await adminGetAdmins({ page: page, size: SIZE_PAGE_CONTROL_PANEL });
      rows.push(...data.users);
      total = data.total as number;
    }
    if (tab === TAB.ARCHIVE) {
      const data = await adminGetArchive({ page: page, size: SIZE_PAGE_CONTROL_PANEL });
      rows.push(...data.archives);
      total = data.total as number;
    }
    setRowStates((prev) => ({
      ...prev,
      [tab]: { rows: rows, total: total }
    }));
  }, []);

  const { asyncCallback: asyncFetchUser } = useAsyncCallback(fetchDataForTab, []);

  const handlePaginationModelChange = useCallback(
    async (newPaginationModel: GridPaginationModel, tab: string) => {
      const newPage = newPaginationModel.page + 1;
      setPages((prev) => ({ ...prev, [tab]: newPage }));
      await asyncFetchUser(newPage, tab);
    },
    [asyncFetchUser]
  );
  const handleRowClick = (params: GridRowParams) => {
    const tabQuery = searchParams.get('tab') || 'bailiffs';
    navigate(`/admin/control-panel/${tabQuery}/${params.id}`);
  };

  const handleRowArchiveClick = (params: GridRowParams) => {
    params.row.file.seizureReports?.[0]?.id
      ? navigate(`/admin/control-panel/archive/${params.row.file.seizureReports?.[0]?.id}`)
      : navigate(`/admin/control-panel/archive/file/${params.row.file.id}`);
  };

  // handle tabs
  const handleChangeTab = (newValue: number) => {
    const nameTab = tabHeader.find((item) => item.value === newValue)?.param.toLocaleLowerCase();
    setSearchParams({ tab: nameTab || 'bailiffs' });
  };

  const onSubmitClientForm = useCallback(async (data: ClientOption) => {
    const dataConvert: UpdateClientOption = {
      options:
        compact(
          data.options?.map((itemMap) => {
            if (!itemMap.value) return;
            if (!itemMap.id && itemMap.isDelete) {
              return;
            }
            return {
              ...itemMap,
              id: itemMap?.id ? Number(itemMap.id) : undefined,
              parentId: undefined,
              children: compact(
                itemMap.children.map((item) => {
                  if (!item.id && item.isDelete) {
                    return;
                  }
                  return {
                    ...item,
                    id: item?.id ? Number(item.id) : undefined,
                    parentId: itemMap?.id ? Number(itemMap.id) : undefined
                  };
                })
              )
            };
          })
        ) || []
    };
    const dataRes = await updateClientOption(dataConvert);
    setOptionClient(dataRes);
    setFlash({ type: 'success', message: 'Update Option Client successful' });
  }, []);

  const onSubmitActionDeliveryForm = useCallback(
    async (data: TAuctionForm) => {
      const payload =
        compact(
          data?.options?.map((item) => {
            if (!item.value) return;
            if (!item.idOption && item.isDelete) return;
            return {
              ...omit(item, ['id', 'idOption']),
              id: item?.idOption ? Number(item.idOption) : undefined
            };
          })
        ) || [];
      const dataRes = await updateAuctionDeliveryLocations({ options: payload });
      setOptionAuctionDelivery(dataRes.auctionHouses);
      setFlash({ type: 'success', message: 'Update Option Auction Delivery Locations successful' });
    },
    [setFlash]
  );
  const { asyncCallback: asyncOnSubmitClient } = useAsyncCallback(onSubmitClientForm, []);
  const { asyncCallback: asyncOnSubmitAuctionDelivery } = useAsyncCallback(
    onSubmitActionDeliveryForm,
    []
  );

  const renderButtonHeader = useCallback(() => {
    const tab = (searchParams.get('tab') as keyof typeof TAB) || TAB.BAILIFFS;
    if (tab === TAB.BAILIFFS) return 'New Bailiff';
    if (tab === TAB.ADMIN) return 'New Admin';
    return '';
  }, [searchParams]);

  const onClickButtonHeader = useCallback(() => {
    const tab = (searchParams.get('tab') as keyof typeof TAB) || TAB.BAILIFFS;
    if (tab === TAB.BAILIFFS) return navigate(`/admin/control-panel/bailiffs`);
    if (tab === TAB.ADMIN) return navigate(`/admin/control-panel/admin`);
    return;
  }, [searchParams]);

  const currentTab = useMemo(() => {
    const params = searchParams.get('tab');
    return TAB_HEADER.find((item) => item.param === params)?.value || 0;
  }, [searchParams]);

  return {
    tab: currentTab,
    asyncOnSubmitAuctionDelivery,
    asyncOnSubmitClient,
    handlePaginationModelChange,
    handleRowClick,
    loading,
    optionAuctionDelivery,
    optionClient,
    pages,
    rowStates,
    searchParams,
    handleRowArchiveClick,
    handleChangeTab,
    renderButtonHeader,
    onClickButtonHeader,
    tabHeader
  };
};
