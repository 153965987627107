import { Box, InputAdornment, Link, Stack, styled, Typography } from '@mui/material';
import { InputNumber } from 'app/components/elements/InputNumber';
import { Controller } from 'react-hook-form';
import CustomDatePicker from 'app/components/elements/InputDate';
import dayjs from 'dayjs';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import React, { useRef } from 'react';
import { StyledParams } from 'types/common';
import { useInjection } from './hook';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { InputSelect } from 'app/components/elements/InputSelect';
import { optionArrearsOther, optionsRedemptionsSeizure } from 'const/option';
import PaidIcon from '@mui/icons-material/AttachMoney';
import { RadioGroupCustom } from 'app/components/elements/RadioGroupCustom';
import { TFilePhoto } from 'types/user';
import theme from 'styles/theme';

export type FormPostSeizure = {
  fileId: number;
  arrears?: number;
  arrearsOther?: string;
  bailiffFees?: number;
  redemptionFees?: number;
  lenderFees?: number;
  otherFees?: number;
  storageFees?: number;
  towBill?: number;
  interimStatementSent?: Date;
  followUpSent?: Date;
  appointmentBooked?: Date;
  finalStatementSent?: Date;
  status: string;
  postSeizuresDocuments?: TFilePhoto[];
  escFees?: number;
  transportFees?: number;
  mvsSearch?: number;
  nextPayment?: number;
  fctFees?: number;
  auctionHouseFees?: number;
  nextPaymentDate?: Date;
};
export type FormPostSeizureProps = {
  onSubmitForm: (value: FormPostSeizure) => void;
  isEdit?: boolean;
  initDataForm?: Partial<FormPostSeizure>;
  handleMoveToArchive?: () => void;
};
export const PostSeizureForm = ({
  onSubmitForm,
  initDataForm,
  handleMoveToArchive
}: FormPostSeizureProps) => {
  const {
    watchArrears,
    errors,
    handleSubmit,
    control,
    setIsShowModal,
    isShowModal,
    contentModal,
    dirtyFields,
    fileDocument,
    handleDeleteDocument,
    handleFileChange
  } = useInjection({ initDataForm });
  const fileInputDocumentRef = useRef<HTMLInputElement | null>(null);
  return (
    <div>
      <Wrapper>
        <Stack direction="row" width={'254px'}>
          <Controller
            name={'status'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSelect
                inputError={errors.status?.message}
                style={{ height: 50 }}
                title={'Status'}
                placeholder={'Choose an selection'}
                options={optionsRedemptionsSeizure}
                value={value}
                onChange={(event) => {
                  onChange(event);
                }}
              />
            )}
          />
        </Stack>
        <TextTitleStyled>Generate Statement</TextTitleStyled>
        <Stack direction={'row'} gap={2.5} sx={{ flexWrap: 'wrap' }}>
          <Stack gap={1.25}>
            <Controller
              name={'arrears'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Arrears'}
                  placeholder={'Arrears'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {watchArrears === 0 && (
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioGroupCustom
                    onChange={onChange}
                    value={value || ''}
                    label={'Options'}
                    options={optionArrearsOther}
                  />
                )}
                name={'arrearsOther'}
              />
            )}
          </Stack>
          <Box>
            <Controller
              name={'bailiffFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Bailiff Fees'}
                  inputError={errors.bailiffFees?.message}
                  placeholder={'Bailiff Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'redemptionFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Redemption Fees'}
                  inputError={errors.redemptionFees?.message}
                  placeholder={'Redemption Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'lenderFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Lender Fees'}
                  inputError={errors.lenderFees?.message}
                  placeholder={'Lender Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'towBill'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Tow Bill'}
                  inputError={errors.towBill?.message}
                  placeholder={'Tow Bill'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'storageFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Storage Fees'}
                  inputError={errors.storageFees?.message}
                  placeholder={'Storage Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'auctionHouseFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Auction House Fees'}
                  inputError={errors.auctionHouseFees?.message}
                  placeholder={'Auction House Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'escFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Client Fees'}
                  inputError={errors.escFees?.message}
                  placeholder={'Client Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'transportFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Transport Fees'}
                  inputError={errors.transportFees?.message}
                  placeholder={'Transport Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'mvsSearch'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'MVS Search'}
                  inputError={errors.mvsSearch?.message}
                  placeholder={'MVS Search'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'nextPayment'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Next Payment'}
                  inputError={errors.nextPayment?.message}
                  placeholder={'Next Payment'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={'otherFees'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  title={'Other Fees'}
                  inputError={errors.otherFees?.message}
                  placeholder={'Other Fees'}
                  endAdornment={
                    <InputAdornment position="end">
                      <PaidIcon />
                    </InputAdornment>
                  }
                  inputProps={{}}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>
        </Stack>
        <Stack marginTop={5} direction="row" gap={2.5} sx={{ flexWrap: 'wrap' }}>
          <Controller
            name={'nextPaymentDate'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                inputError={errors.nextPaymentDate?.message}
                title={'Next Payment Date'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                datePickerProps={{}}
              />
            )}
          />
          <Controller
            name={'interimStatementSent'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                inputError={errors.interimStatementSent?.message}
                title={'Interim Statement Sent'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                datePickerProps={{}}
              />
            )}
          />
          <Controller
            name={'followUpSent'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                inputError={errors.followUpSent?.message}
                title={'Follow Up Sent'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                datePickerProps={{}}
              />
            )}
          />
          <Controller
            name={'appointmentBooked'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                inputError={errors.appointmentBooked?.message}
                title={'Appointment Booked'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                datePickerProps={{}}
              />
            )}
          />
          <Controller
            name={'finalStatementSent'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                inputError={errors.finalStatementSent?.message}
                title={'Final Statement Sent'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                datePickerProps={{}}
              />
            )}
          />
        </Stack>
        <Stack gap={1.25} marginTop="40px">
          <Stack gap={1.25}>
            <Typography fontWeight={500} mb={1} fontSize={'13px'} color={theme.colors.dark1}>
              Documents
            </Typography>

            {fileDocument.map((doc, index) => (
              <Stack
                key={index}
                flexDirection={'row'}
                alignItems={'center'}
                width="fit-content"
                justifyContent={'space-between'}>
                <Stack flexDirection={'row'} gap={1.5} marginRight="24px" alignItems={'center'}>
                  <img src={'/icons/icon_document.svg'} alt={'icon'} />
                  <NameDocument href={doc.url} target={'_blank'}>
                    {doc?.file?.name || doc.name}
                  </NameDocument>
                </Stack>
                <img
                  onClick={() => handleDeleteDocument(doc)}
                  style={{ cursor: 'pointer' }}
                  src={'/icons/icon_delete_file.svg'}
                  alt={'icon'}
                />
              </Stack>
            ))}
          </Stack>

          <ButtonCustom
            onClick={() => {
              if (fileInputDocumentRef.current) {
                fileInputDocumentRef.current?.click();
              }
            }}
            style={{ width: '140px', height: '50px' }}
            label={'Add Document'}
          />

          <input
            accept=".pdf"
            ref={fileInputDocumentRef}
            hidden
            onChange={handleFileChange}
            type="file"
            multiple
          />
        </Stack>
        <Stack gap={2} direction={'row'} justifyContent={'end'}>
          <ButtonCustom
            style={{ width: '160px', marginTop: '40px' }}
            label={'Save'}
            onClick={handleSubmit(() => {
              if (Object.keys(dirtyFields).length === 0) return;
              setIsShowModal(true);
            })}
          />
          {initDataForm?.status === 'COMPLETED' && (
            <ButtonCustom
              style={{ width: '160px', marginTop: '40px' }}
              label={'Move to archive'}
              onClick={handleMoveToArchive}
            />
          )}
        </Stack>
        <DialogConfirm
          isOpen={isShowModal}
          onClose={() => setIsShowModal(false)}
          onSubmit={handleSubmit((value) => {
            setIsShowModal(false);
            onSubmitForm(value);
          })}
          textTitle={'Are you sure?'}
          textButton={'Confirm'}
          textContent={`This action will change the redemption status to ${contentModal}`}
          contentStyle={{ textAlign: 'center' }}
        />
      </Wrapper>
    </div>
  );
};
const TextTitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '14px',
  fontWeight: '700',
  color: '#000000',
  marginTop: theme.spacing(3.75),
  marginBottom: theme.spacing(2.5)
}));

export const Wrapper = styled(Stack)(() => ({
  padding: '40px 30px',
  border: '1px solid #ccc',
  borderRadius: '20px'
}));

const NameDocument = styled(Link)(({ theme }: StyledParams) => ({
  color: theme.colors.dark1,
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 24px)'
}));
