import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';
import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StorageServices } from 'services/storage';
import { ROLE } from 'const/common';
const storage = new StorageServices();
const Loading = () => {
  const isUser = storage.getRole() === ROLE.USER;
  const div = useRef<HTMLDivElement | null>(null);
  const portElement = useMemo(() => {
    if (!div.current) {
      div.current = document.createElement('div');
      document.body.append(div.current);
    }
    return div.current;
  }, []);
  useEffect(() => () => portElement.remove(), []);
  if (!portElement) return null;
  return createPortal(
    <Center style={{ maxWidth: isUser ? '450px' : '100%' }}>
      <BeatLoader />
    </Center>,
    portElement
  );
};

export default Loading;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100dvh;
  top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  background: white;
`;
