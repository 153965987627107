import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';

type CheckBoxCustomProps = { label?: string } & CheckboxProps;
export const CheckBoxCustom = ({ label, ...props }: CheckBoxCustomProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          style={{ width: 44 }}
          checkedIcon={<img src={'/icons/icon_checked_box.svg'} alt={'icon'} />}
          icon={<img src={'/icons/icon_check_box.svg'} alt={'icon'} />}
          {...props}
        />
      }
      label={label}
    />
  );
};
