import { InputAdornment, Stack, Typography, styled } from '@mui/material';

import { useInjection } from 'app/pages/SettingProfileInformation/hook';
import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { HeaderPage } from 'app/components/elements/HeaderPage';
import { IconUser } from 'assets/icon';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import BusinessIcon from '@mui/icons-material/Business';
import { Helmet } from 'react-helmet-async';
import { Autocomplete } from '@react-google-maps/api';
import React from 'react';

export const SettingProfileInformation = () => {
  const {
    handleSubmit,
    register,
    handleSubmitForm,
    errors,
    modalConfirm,
    setModalConfirm,
    inputRef,
    isLoaded
  } = useInjection();
  return (
    <Stack>
      <Helmet>
        <title>Surrey Bailiffs - Profile Information</title>
      </Helmet>
      <HeaderPage title={'Profile Information'} isBack />
      <WrapperContent>
        <Stack gap={2.5}>
          <Stack gap={1}>
            <InputText
              inputError={errors.email?.message}
              placeholder={'Email'}
              inputProps={{
                ...register('email')
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '8px', width: 40 }}>
                  <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                    <IconUser />
                  </Stack>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack gap={1}>
            <InputText
              inputError={errors.firstName?.message}
              placeholder={'First Name'}
              inputProps={{
                ...register('firstName')
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '8px', width: 40 }}>
                  <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                    <IconName>FN</IconName>
                  </Stack>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack gap={1}>
            <InputText
              inputError={errors.lastName?.message}
              placeholder={'Last Name'}
              inputProps={{
                ...register('lastName')
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '8px', width: 40 }}>
                  <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                    <IconName>LN</IconName>
                  </Stack>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack gap={1}>
            {isLoaded && (
              <Autocomplete>
                <InputText
                  inputError={errors.address?.message}
                  placeholder={'Address'}
                  inputProps={{
                    ...register('address')
                  }}
                  inputRef={inputRef}
                  endAdornment={
                    <InputAdornment position="end" sx={{ paddingRight: '8px', width: 40 }}>
                      <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                        <BusinessIcon style={{ color: '#22374A' }} />
                      </Stack>
                    </InputAdornment>
                  }
                />
              </Autocomplete>
            )}
          </Stack>
          <ButtonCustom onClick={handleSubmit(() => setModalConfirm(true))} label={'Update'} />
        </Stack>
      </WrapperContent>
      <DialogConfirm
        onSubmit={handleSubmit(handleSubmitForm)}
        onClose={() => setModalConfirm(false)}
        isOpen={modalConfirm}
        textTitle={'Update Profile'}
        textContent={'Please make sure all fields are correct before saving the changes.'}
        textButton={'Confirm and save'}
        contentStyle={{ textAlign: 'center' }}
      />
    </Stack>
  );
};
const WrapperContent = styled(Stack)(() => ({
  padding: '30px 16px 16px 16px'
}));

const IconName = styled(Typography)`
  color: #22374a;
  padding: 4px;
  border: 2px solid #22374a;
  font-size: 10px;
  font-weight: 500;
`;
