import { Box, Button, Grid, IconButton, Stack, styled } from '@mui/material';
import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { TAuctionForm, useAuctionDeliveryLocation } from './hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller } from 'react-hook-form';
import React from 'react';
import { AuctionDelivery } from 'services/auction';
export type AuctionDeliveryLocationTabProps = {
  optionInit: AuctionDelivery[];
  onSubmitForm: (data: TAuctionForm) => void;
};
export const AuctionDeliveryLocationTab = ({
  optionInit,
  onSubmitForm
}: AuctionDeliveryLocationTabProps) => {
  const { errors, append, update, control, handleSubmit, fieldOptionAuction, handleResetForm } =
    useAuctionDeliveryLocation({ optionInit });

  return (
    <Wrapper minWidth={'900px'} width={'50%'} mt={2}>
      <Stack width={'100%'} mb={2} alignItems={'flex-start'}>
        {fieldOptionAuction && (
          <Stack width={'100%'}>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
              {fieldOptionAuction.map((field, index) => (
                <Grid
                  display={field.isDelete ? 'none' : 'grid'}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  key={field.id}
                  style={{ position: 'relative' }}>
                  <Stack flexDirection={'row'} gap={1} width={'100%'}>
                    <Controller
                      name={`options.${index}.value`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <InputText
                            {...field}
                            inputError={errors.options?.[index]?.value?.message || ''}
                            placeholder={'Auction Delivery Location'}
                            title={`Auction Delivery Location`}
                            inputProps={{
                              ...field
                            }}
                          />
                        );
                      }}
                    />
                    <IconButton
                      sx={{ marginTop: '24px' }}
                      onClick={() => {
                        update(index, {
                          ...fieldOptionAuction[index],
                          isDelete: true
                        });
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => append({ value: '' })}
          sx={{ marginTop: 2 }}>
          Add Auction Delivery Location
        </Button>
      </Stack>

      <Stack
        width={'100%'}
        justifyContent={'flex-end'}
        flexDirection={'row'}
        gap={2}
        alignItems={'flex-end'}>
        <Box width={'156px'} sx={{ float: 'inline-end' }}>
          <ButtonCustom label="Save" onClick={handleSubmit(onSubmitForm)} />
        </Box>
        <ButtonCustom isCancel sx={{ width: '156px' }} label={'Reset'} onClick={handleResetForm} />
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  padding: '24px',
  border: '1px solid #ccc',
  borderRadius: '20px'
}));
