import { LayoutAdminTable } from 'app/components/templates/LayoutAdminTable';
import React from 'react';
import { FileForm } from 'app/pages/Admin/FilesPage/components/FileForm';
import { useInjection } from './hook';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';

export const EditFilesPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    asyncUpdateFile,
    leads,
    handleAddNewLead,
    handleAddNewNote,
    handleEditNote,
    file,
    handleDeleteLead,
    handleDeleteFile,
    showModalDeleteFile,
    setShowModalDeleteFile,
    showModalDeleteLead,
    setShowModalDeleteLead,
    setIdLeadDelete,
    handleAddTowYards,
    handleDeleteTowYards,
    handleChangeVinStatus,
    handleUploadFileCarfax,
    handleDeleteDocumentLead,
    optionClient,
    optionClientChild,
    optionAuctionDeliveryLocation,
    changeOptionClient,
    handleCloseClient,
    handleCloseNotFound
  } = useInjection();
  return (
    <LayoutAdminTable
      title={'Edit Files'}
      textButton={'Back To List'}
      onClickButton={() => navigate('/admin/files')}>
      <FileForm
        optionClient={optionClient}
        optionClientChild={optionClientChild}
        optionAuctionDeliveryLocation={optionAuctionDeliveryLocation}
        changeOptionClient={changeOptionClient}
        initFile={file}
        createForm={asyncUpdateFile}
        isEdit
        leads={leads}
        id={Number(id)}
        handleAddNewLead={handleAddNewLead}
        handleDeleteFile={() => setShowModalDeleteFile(true)}
        handleDeleteLead={(id: number) => {
          setIdLeadDelete(id);
          setShowModalDeleteLead(true);
        }}
        handleAddNewNote={handleAddNewNote}
        handleAddTowYards={handleAddTowYards}
        handleEditNote={handleEditNote}
        handleDeleteTowYards={handleDeleteTowYards}
        handleChangeVinStatus={handleChangeVinStatus}
        handleUploadFileCarfax={handleUploadFileCarfax}
        handleDeleteDocumentLead={handleDeleteDocumentLead}
        handleCloseClient={handleCloseClient}
        handleCloseNotFound={handleCloseNotFound}
      />
      <DialogConfirm
        width={'650px'}
        isOpen={showModalDeleteFile}
        textButton={'Delete'}
        isDelete
        onClose={() => setShowModalDeleteFile(false)}
        textTitle={'Delete File'}
        textContent={
          'Are you sure? Deleting a file will also delete all the photos, documents, and leads associated with the file.'
        }
        onSubmit={handleDeleteFile}
      />
      <DialogConfirm
        width={'650px'}
        isOpen={showModalDeleteLead}
        textButton={'Delete'}
        isDelete
        onClose={() => setShowModalDeleteLead(false)}
        textTitle={'Delete Lead'}
        textContent={'Are you sure? Deleting a lead is permanent.'}
        onSubmit={handleDeleteLead}
      />
    </LayoutAdminTable>
  );
};
