import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationAuctionSchema } from 'utils/validate';
import { AuctionDelivery } from 'services/auction';
import { AuctionDeliveryLocationTabProps } from './index';
import { useCallback, useEffect } from 'react';

export type TAuctionForm = {
  options?: AuctionDelivery[];
};

export const useAuctionDeliveryLocation = ({
  optionInit
}: Partial<AuctionDeliveryLocationTabProps>) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<TAuctionForm>({
    resolver: yupResolver(validationAuctionSchema),
    defaultValues: {
      options: optionInit
    }
  });
  const {
    fields: fieldOptionAuction,
    append,
    update
  } = useFieldArray({
    control,
    name: 'options'
  });
  useEffect(() => {
    reset({
      options: optionInit
    });
  }, [optionInit]);

  const handleResetForm = useCallback(() => {
    reset({
      options: optionInit
    });
  }, [optionInit]);

  return {
    append,
    update,
    fieldOptionAuction,
    reset,
    control,
    handleSubmit,
    errors,
    handleResetForm
  };
};
