export const { innerWidth: widthScreen, innerHeight: heightScreen } = window;

export const ROLE = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  ROOT_ADMIN: 'ROOT_ADMIN'
};

export const statusTypes = [
  { value: 'New - created past 48 hours', name: 'NEW' },
  { value: 'Attention - no update past 48 hours', name: 'ATTENTION' },
  { value: 'Aged - opened for 2 weeks', name: 'AGED' }
];

export const leadTypes = [
  { value: 'POR', name: 'POR' },
  { value: 'POE', name: 'POE' },
  { value: 'MVS', name: 'MVS' },
  { value: 'CB Phone Number', name: 'PHONE_NUMBER' },
  // { value: 'ADDRESS', name: 'CB Address' },
  // { value: 'Debtor Phone Number', name: 'Debtor Phone Number' },
  { value: 'References', name: 'REFERENCE' },
  { value: 'Carfax', name: 'CARFAX' },
  { value: 'Vin', name: 'VIN Check' },
  { value: 'Tow Yards', name: 'TOW_YARDS' },
  { value: 'Other Address', name: 'OTHER_ADDRESS' },
  { value: 'Other Phone Number', name: 'OTHER_PHONE_NUMBER' },
  { value: 'Other Open Note', name: 'OTHER_OPEN_NOTE' }
];

export const CATEGORY = {
  PHONE: 'phone_number',
  ADDRESS: 'address',
  NONE: ''
};

export const StatusFileFilter = {
  OPEN_FILE: 'open-files',
  ON_HOLD: 'on-hold',
  OUT_OF_LEADS: 'out-of-leads'
};

export const capitalizeFirstLetter = (text: string): string =>
  text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
