import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangePasswordSchema } from 'utils/validate';
import { useCallback, useState } from 'react';

import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { changePassword } from 'services/auth';
import { useFlash } from 'contexts/flash';
import { useNavigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();

type FormChangePassword = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword?: string;
};
export const useInjection = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormChangePassword>({ resolver: yupResolver(ChangePasswordSchema) });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowPNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: FormChangePassword) => {
      setModalConfirm(false);
      await changePassword({ ...data });
      setFlash({ type: 'success', message: 'Change password successful' });

      storage.removeAccessToken();
      storage.removeRefreshToken();
      storage.removeRole();
      navigate('/login');
    },
    [navigate, setFlash]
  );

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(onSubmit, []);
  return {
    errors,
    register,
    handleSubmit,
    showPassword,
    setShowPassword,
    handleSubmitForm,
    showNewPassword,
    setShowPNewPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    modalConfirm,
    setModalConfirm
  };
};
